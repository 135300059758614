import React from "react";
import { Container } from "@urbaninfrastructure/react-ui-kit";
import Loading from "./Loading";
import GraphQLNetworkError from "./GraphQLNetworkError";
import { ApolloError, NetworkStatus } from "apollo-client";

interface Props {
  entity?: string;
  queriesData: Array<{
    error?: ApolloError;
    loading: boolean;
    networkStatus: NetworkStatus;
  }>;
  loadingProps?: {
    large?: boolean;
  };
}

// Helper component, shows loading state and error (if any) for supplied GraphQL queries
const GraphQLQuery = ({
  children,
  entity,
  queriesData,
  loadingProps
}: React.PropsWithChildren<Props>) => {
  const { loadingComponent, errorComponent } = useGraphQLQuery({
    entity,
    queriesData,
    loadingProps
  });
  if (loadingComponent) {
    return loadingComponent;
  }
  return (
    <>
      {errorComponent}
      {typeof children === "function" ? children() : children}
    </>
  );
};

export function useGraphQLQuery({
  entity = "data",
  queriesData,
  loadingProps = {
    large: true
  }
}: Props): {
  loadingComponent: JSX.Element | null;
  errorComponent: JSX.Element | null;
} {
  let loadingComponent: JSX.Element | null = null;
  let errorComponent: JSX.Element | null = null;
  // only show loading if not polling
  const loading = queriesData.some(prop => {
    return (
      prop &&
      prop.loading &&
      prop.networkStatus !== NetworkStatus.poll &&
      prop.networkStatus !== NetworkStatus.setVariables &&
      prop.networkStatus !== NetworkStatus.fetchMore
    );
  });

  const errors = queriesData.reduce<ApolloError[]>((accum, data) => {
    if (data && data.error) {
      accum.push(data.error);
    }
    return accum;
  }, []);

  if (loading) {
    const loadingProp = <Loading {...loadingProps}>Loading {entity}</Loading>;
    loadingComponent = loadingProps.large ? (
      <Container>{loadingProp}</Container>
    ) : (
      <>{loadingProp}</>
    );
  }
  if (errors.length > 0) {
    errorComponent = (
      <GraphQLNetworkError
        message={`Could not load '${entity}' properly`}
        errors={errors}
      />
    );
  }

  return { loadingComponent, errorComponent };
}

export default GraphQLQuery;
