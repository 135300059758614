import React from "react";
import styled, { keyframes, css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { Button } from "@urbaninfrastructure/react-ui-kit";
import {
  Left as LeftIcon,
  Right as RightIcon
} from "@urbaninfrastructure/react-icons";
import { transparentize } from "polished";

type Props = SpaceProps &
  React.PropsWithChildren<{
    collapsed?: boolean;
    fadeColor?: string;
  }>;

type State = {
  overflowRight: boolean;
  overflowLeft: boolean;
  animateScrollRight: boolean;
  animateScrollLeft: boolean;
  touchDetected: boolean;
};

type BaseProps = {
  overflowRight: boolean;
  overflowLeft: boolean;
  collapsed: boolean;
  touchDetected: boolean;
  fadeColor: string;
};

const fadeInAnimation = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const scrollRightAnimation = keyframes`
  from {
    transform: translate(200px,0)
  }
  to {
    transform: translate(0,0)
  }
`;

const scrollLeftAnimation = keyframes`
  from {
    transform: translate(-200px,0)
  }
  to {
    transform: translate(0,0)
  }
`;

const baseCss = ({
  theme,
  overflowRight,
  overflowLeft,
  collapsed,
  touchDetected,
  fadeColor
}) => css<BaseProps>`
  /* The parent container with fading styling */
  position: relative;
  width: 100%;
  ${space};
  ${overflowRight &&
    css`
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 3;
        top: 0px;
        bottom: 0px;
        width: 3.5rem;
        animation: 1000ms ease-out ${fadeInAnimation};
        right: 0;
        background: linear-gradient(
          to left,
          ${transparentize(
            0,
            fadeColor ? theme.colors[fadeColor] : theme.colors.white
          )},
          ${transparentize(
            1,
            fadeColor ? theme.colors[fadeColor] : theme.colors.white
          )}
        );
        ${touchDetected &&
          css`
            width: 1.75rem;
          `};
      }
    `};
  ${overflowLeft &&
    css`
      &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 3;
        top: 0px;
        bottom: 0px;
        width: 3.5rem;
        animation: 1000ms ease-out ${fadeInAnimation};
        left: 0;
        background: linear-gradient(
          to right,
          ${transparentize(
            0,
            fadeColor ? theme.colors[fadeColor] : theme.colors.white
          )},
          ${transparentize(
            1,
            fadeColor ? theme.colors[fadeColor] : theme.colors.white
          )}
        );
        ${touchDetected &&
          css`
            width: 1.75rem;
          `};
      }
    `};
  ${(overflowRight || overflowLeft) &&
    !collapsed &&
    css`
      width: calc(100% + ${theme.space[4]}px + ${theme.space[4]}px);
      margin-right: -${theme.space[4]}px;
      margin-left: -${theme.space[4]}px;
      ${theme.mediaQueries[0]} {
        width: calc(100% + ${theme.space[6]}px + ${theme.space[6]}px);
        margin-right: -${theme.space[6]}px;
        margin-left: -${theme.space[6]}px;
      }
    `};
`;

const contentCss = ({ needOverflow }) => css`
  /* The container with the overflow property */
  width: 100%;
  ${needOverflow &&
    css`
      display: flex; /* Need flex here to fix weird padding right behaviour */
      z-index: 1;
      overflow: auto;
      overflow-y: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      /* For WebKit implementations, provide inertia scrolling */
      -webkit-overflow-scrolling: touch;
      /* Hide scrollbar for IE and Webkit */
      -ms-overflow-style: -ms-autohiding-scrollbar;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        display: none;
      }
    `};
`;

const paddingCss = ({
  theme,
  needOverflow,
  collapsed,
  animateScrollRight,
  animateScrollLeft
}) => css`
  /* Needed to provide padding inside the overflow element */
  width: 100%;
  ${needOverflow &&
    css`
      display: flex; /* Need flex here to fix weird padding right behaviour */
      width: auto;
      ${!collapsed &&
        css`
          padding-right: ${theme.space[4]}px;
          padding-left: ${theme.space[4]}px;
          ${theme.mediaQueries[0]} {
            padding-right: ${theme.space[6]}px;
            padding-left: ${theme.space[6]}px;
          }
        `};
    `};
  ${animateScrollRight &&
    css`
      animation: 200ms ease-out ${scrollRightAnimation};
    `} ${animateScrollLeft &&
    css`
      animation: 200ms ease-out ${scrollLeftAnimation};
    `};
`;

const buttonCss = ({ theme, direction, fadeColor }) => css`
  /* Overrides Button styling */
  position: absolute;
  z-index: 4;
  top: 50%;
  transform: translate(0, -50%);
  padding: ${theme.space[1]}px;
  border-width: 1px;
  border-color: ${theme.colors.neutral[2]};
  animation: 200ms ease-out ${fadeInAnimation};
  &:focus {
    outline: none;
  }
  ${direction === "left" &&
    css`
      left: 2px;
    `};
  ${direction === "right" &&
    css`
      right: 2px;
    `};
  ${fadeColor &&
    css`
      background: ${theme.colors[fadeColor]};
      border-color: ${theme.colors.white};
    `};
`;

const Base = styled.div<BaseProps>`
  ${baseCss};
`;

const ContentWrapper = styled.div<{ needOverflow: boolean }>`
  ${contentCss};
`;

const PaddingWrapper = styled.div`
  ${paddingCss};
`;

const ScrollButton = styled(Button)`
  ${buttonCss};
`;

export class HorizontalOverflow extends React.Component<Props, State> {
  state = {
    overflowRight: false,
    overflowLeft: false,
    animateScrollRight: false,
    animateScrollLeft: false,
    touchDetected: false
  };

  touchEventTimer: number | null = null;
  ContentWrapper = React.createRef<HTMLDivElement>();

  updateOverflow = () => {
    // Check scroll width and position to detect overflow
    const e = this.ContentWrapper.current;
    if (e) {
      this.setState({
        overflowRight:
          e.offsetWidth < e.scrollWidth &&
          e.scrollLeft - e.clientLeft + e.offsetWidth < e.scrollWidth
      });
      this.setState({
        overflowLeft: e.offsetWidth < e.scrollWidth && e.scrollLeft > 0
      });
    }
  };

  touchDetected = () => {
    // Remove left and right scroll buttons and fading during touch scroll
    this.setState({ touchDetected: true });
    if (this.touchEventTimer) {
      window.clearTimeout(this.touchEventTimer);
    }
    this.touchEventTimer = window.setTimeout(() => {
      this.setState({ touchDetected: false });
    }, 2000);
  };

  componentDidMount() {
    this.updateOverflow();
    window.addEventListener("resize", this.updateOverflow);

    const { current } = this.ContentWrapper;
    if (current) {
      current.addEventListener("scroll", this.updateOverflow);
      current.addEventListener("touchmove", this.touchDetected);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateOverflow);
    const { current } = this.ContentWrapper;
    if (current) {
      current.removeEventListener("scroll", this.updateOverflow);
      current.removeEventListener("touchmove", this.touchDetected);
    }
    if (this.touchEventTimer) {
      window.clearTimeout(this.touchEventTimer);
    }
  }

  scrollRight() {
    const { current } = this.ContentWrapper;
    if (current) {
      // Scroll right and animate
      current.scrollLeft = current.scrollLeft + 200;
      this.setState({ animateScrollRight: true });
      // Remove scroll animation css after animation has passed
      window.setTimeout(() => {
        this.setState({ animateScrollRight: false });
        this.updateOverflow();
      }, 200);
    }
  }

  scrollLeft() {
    const { current } = this.ContentWrapper;
    if (current) {
      // Scroll left and animate
      current.scrollLeft = current.scrollLeft - 200;
      this.setState({ animateScrollLeft: true });
      // Remove scroll animation css after animation has passed
      window.setTimeout(() => {
        this.setState({ animateScrollLeft: false });
        this.updateOverflow();
      }, 200);
    }
  }

  render() {
    const { children, collapsed, fadeColor, ...props } = this.props;
    const {
      overflowRight,
      overflowLeft,
      animateScrollRight,
      animateScrollLeft,
      touchDetected
    } = this.state;

    return (
      <Base
        overflowRight={!!overflowRight}
        overflowLeft={!!overflowLeft}
        collapsed={!!collapsed}
        touchDetected={!!touchDetected}
        fadeColor={fadeColor || ""}
        {...props}
      >
        {!touchDetected && overflowRight && (
          <ScrollButton
            direction="right"
            type="button"
            onClick={() => this.scrollRight()}
            variant="secondary"
            shape="cool"
            title="Scroll right"
            aria-label="Scroll right"
            fadeColor={fadeColor}
          >
            <RightIcon
              size="12px"
              color={fadeColor ? "white" : "primary"}
              style={{ verticalAlign: "top", display: "block" }}
            />
          </ScrollButton>
        )}
        {!touchDetected && overflowLeft && (
          <ScrollButton
            direction="left"
            type="button"
            onClick={() => this.scrollLeft()}
            variant="secondary"
            shape="cool"
            title="Scroll left"
            aria-label="Scroll left"
            fadeColor={fadeColor}
          >
            <LeftIcon
              size="12px"
              color={fadeColor ? "white" : "primary"}
              style={{ verticalAlign: "top", display: "block" }}
            />
          </ScrollButton>
        )}
        <ContentWrapper
          ref={this.ContentWrapper}
          needOverflow={overflowRight || overflowLeft}
        >
          <PaddingWrapper
            needOverflow={overflowRight || overflowLeft}
            collapsed={collapsed}
            animateScrollRight={animateScrollRight}
            animateScrollLeft={animateScrollLeft}
          >
            {children}
          </PaddingWrapper>
        </ContentWrapper>
      </Base>
    );
  }
}
