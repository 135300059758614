import React from "react";
import NextLink from "next/link";
import { useSystemRouter, SystemLinkProps } from "../src/hooks";

/**
 * Link to Next.js routes with /[systemId] automatically replaced with current systemId
 */
export function SystemLink({
  href,
  passHref = true,
  ...props
}: React.PropsWithChildren<SystemLinkProps>) {
  const { getSystemHref } = useSystemRouter();
  const systemHref = getSystemHref(href);

  return <NextLink href={systemHref} passHref={passHref} {...props} />;
}
