import styled, { keyframes, css } from "styled-components";
import { space } from "styled-system";
import {
  Flex,
  FlexProps,
  Box,
  Arrow,
  Li,
  Text,
  Spinner,
  inputCss,
  InputCssProps,
  inputDefaultProps
} from "@urbaninfrastructure/react-ui-kit";

const Z_INDEXES = {
  dropdownGroup: 5,
  dropdownItem: 6,
  input: 4
};

const dropdownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px)
  }
  to {
    opacity:1;
    transform: translateY(1px)
  }
`;

const dropdownGroupsCss = ({ theme, large }) => css`
  width: 100%;
  top: calc(100% + 0.5rem);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: ${theme.radii["md"]};
  animation: 100ms ease-out ${dropdownAnimation};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  overflow: auto;
  max-height: 250px;
  z-index: ${Z_INDEXES.dropdownGroup};
  ${large &&
    css`
      max-height: 400px;
      box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.3);
    `};
`;

export const DropdownGroups = styled(Box)`
  ${dropdownGroupsCss};
`;

const dropdownGroupLabelCss = ({ theme, large }) => css`
  text-transform: uppercase;
  border-color: ${theme.colors.neutral[3]};
  border-style: solid;
  border-width: 1px 0;
  font-size: ${theme.fontSizes[0]}px;
  ${large &&
    css`
      border: none;
    `};
`;

export const DropdownGroupLabel = styled(Text)`
  ${dropdownGroupLabelCss};
`;

const dropdownItemCss = ({ theme, large }) => css`
  border: none;
  display: flex;
  justify-content: space-between;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
  z-index: ${Z_INDEXES.dropdownItem};
  ${large &&
    css`
      border-bottom: 1px solid ${theme.colors.neutral[3]};
      &:first-child {
        border-top: 1px solid ${theme.colors.neutral[3]};
      }
    `};
`;

export const DropdownItem = styled(Li)`
  ${dropdownItemCss};
`;

export const AutocompleteInput = styled(Box.withComponent("input"))`
  background: transparent;
  border: 0;
  font-size: 16px;
  flex: 1 0 10px;
  align-self: center;
  border-radius: 5px;
  ${space};
  &:focus {
    outline: none;
  }
`;

AutocompleteInput.defaultProps = {
  py: inputDefaultProps.py,
  pl: inputDefaultProps.px,
  pr: 5
};

interface AutocompleteInputWrapperProps extends FlexProps, InputCssProps {
  isFocused: boolean;
  large?: boolean;
}

export const AutocompleteInputWrapper = styled(Flex)<
  AutocompleteInputWrapperProps
>`
  ${inputCss};
  z-index: ${Z_INDEXES.input};
  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border-color: ${theme.colors.primary};
    `};
`;

export const ArrowIcon = Arrow;

ArrowIcon.defaultProps = {
  size: "18px",
  direction: "down"
};

export const SpinnerIcon = Spinner;

SpinnerIcon.defaultProps = {
  width: 10,
  height: 10
};

export const ArrowIconWrapper = styled(Box)<{ onClick: () => void }>`
  position: absolute;
  right: ${({ right }) => right};
  top: 50%;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
`;

export const AddIcon = styled(Box)<{ onClick: () => void }>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;
