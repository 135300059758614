import React from "react";
import { Formik, Form as FormikForm } from "formik";
import Table from "../Table";
import {
  Label,
  Input,
  Box,
  Button,
  ButtonGroup,
  Flex
} from "@urbaninfrastructure/react-ui-kit";
import { I18NCancel, I18NCreate } from "../buttons-i18n";
import { FormWrapper } from "./styled";
import { DataTableColumnGeneric } from "./types";

type Props = {
  onCancelClick: () => void;
  columns: DataTableColumnGeneric<any, string>[];
  onSubmit: (
    values: Record<string, any>
  ) => void | Promise<(values: Record<string, any>) => void>;
  formWrapperProps: Record<string, any>;
};

function getKey(column) {
  return column.key || column.property;
}

const DataTableFormCreateRow = ({
  columns,
  onCancelClick,
  onSubmit,
  formWrapperProps
}: Props) => {
  const initialValues = columns.reduce((accum, column) => {
    if (column.notSubmittable) {
      return accum;
    }
    const key = getKey(column);
    accum[key] = Object.prototype.hasOwnProperty.call(column, "defaultValue")
      ? column.defaultValue
      : "";
    return accum;
  }, {});
  return (
    <Table.Tr>
      <Table.Td px={0} py={0} colSpan={columns.length + 1}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <FormikForm onSubmit={handleSubmit}>
              <FormWrapper formWrapperProps={formWrapperProps}>
                {columns.map(column => {
                  if (column.notSubmittable) {
                    return null;
                  }
                  const key = getKey(column);
                  return (
                    <Box flex="1 1 auto" px={4} mt={3} key={key}>
                      {column.editableComponent ? (
                        column.editableComponent({
                          label: column.header.label,
                          name: key,
                          onChange: handleChange,
                          setFieldValue: setFieldValue,
                          onBlur: handleBlur,
                          value: values[key],
                          options: column.options
                        })
                      ) : (
                        <>
                          <Label>{column.header.label}</Label>
                          <Input disabled value={values[key]} />
                        </>
                      )}
                    </Box>
                  );
                })}
                <Flex alignItems="flex-end" ml="auto" mt={2}>
                  <ButtonGroup flexWrap="nowrap">
                    <Button type="button" onClick={onCancelClick}>
                      <I18NCancel />
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                      data-testid="DataTableFormCreateRow__CreateButton"
                    >
                      <I18NCreate />
                    </Button>
                  </ButtonGroup>
                </Flex>
              </FormWrapper>
            </FormikForm>
          )}
        </Formik>
      </Table.Td>
    </Table.Tr>
  );
};

DataTableFormCreateRow.defaultProps = {
  formWrapperProps: {},
  onCancelClick: () => {
    // noop
  },
  onSubmit: () => {
    // noop
  }
};

export default DataTableFormCreateRow;
