import React from "react";
import { parseFirmwareVersion } from "../../src/utils";

export default function FirmwareVersion({ value }: { value: string | null }) {
  if (!value) {
    return null;
  }

  const parsed = parseFirmwareVersion(value);
  if (!parsed) {
    return <>value</>;
  }

  return <span title={value}>{parsed.version}</span>;
}
