import {
  Anchor,
  AnchorProps,
  Text,
  Box
} from "@urbaninfrastructure/react-ui-kit";
import React from "react";
import { ExternalLink } from "react-feather";

export const ExternalAnchor = ({
  color,
  ref,
  ...props
}: AnchorProps &
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >) => {
  return (
    <Text as="span" whiteSpace="pre">
      <Box as="span" display="inline-flex" alignItems="center">
        <Anchor color={color as any} mr={1} {...props} />
        <ExternalLink size={14} />
      </Box>
    </Text>
  );
};
