import React from "react";
import styled, { css } from "styled-components";
import { border } from "styled-system";
import { Right as RightIcon } from "@urbaninfrastructure/react-icons";
import { Text, Box, Anchor, BoxProps } from "@urbaninfrastructure/react-ui-kit";
import useSystem from "../../src/hooks/useSystem";
import Icon, { IconName } from "../Icon";
import { SystemType } from "../../src/core-types";

type Props = React.PropsWithChildren<{
  title: React.ReactNode;
  value: React.ReactNode;
  large?: boolean;
  icon?: string;
}> &
  BoxProps;

type InfoAnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  React.PropsWithChildren<{
    as?: any;
    icon?: IconName | "vehicle" | IconName[];
    small?: boolean;
    ArrowIcon?: React.ElementType;
  }>;

const anchorCss = ({ theme }) => css`
  &:hover,
  &:focus {
    color: ${theme.colors.text};
    background: ${theme.colors.neutral[1]};
    text-decoration: none;
    border-bottom-color: ${theme.colors.borderGray} !important;
  }
`;

const anchorWrapperCss = ({ theme }) => css`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: ${theme.colors.text};
  text-decoration: none;
`;

const AnchorElement = styled(Anchor)<React.PropsWithRef<InfoAnchorProps>>`
  ${border};
  ${anchorCss};
`;

const AnchorWrapper = styled(Text)`
  ${anchorWrapperCss};
`;

const InfoAnchor = React.forwardRef<HTMLAnchorElement, InfoAnchorProps>(
  ({ icon, ArrowIcon = RightIcon, children, small, ...props }, ref) => {
    const system = useSystem();

    let arrowIconPlacement = "right";
    if (!props.href) {
      arrowIconPlacement = "left";
    }

    const arrowIcon = (
      <ArrowIcon
        color="neutral.4"
        size={10}
        mr={arrowIconPlacement === "left" ? 1 : 0}
        ml={arrowIconPlacement === "right" ? 1 : 0}
      />
    );

    if (icon && icon === "vehicle") {
      switch (system.type) {
        case SystemType.scootersharing:
          icon = "scooter";
          break;
        default: {
          icon = "bike";
          break;
        }
      }
    }

    return (
      <AnchorElement
        borderStyle="solid"
        borderWidth={1}
        borderColor="borderGray"
        borderRadius={16}
        pl={icon ? "6px" : 1}
        py="2px"
        pr={1}
        textDecoration="none"
        backgroundColor="white"
        display="inline-block"
        {...props}
        ref={ref}
      >
        <AnchorWrapper as="span">
          {Array.isArray(icon)
            ? icon.map(ii => <Icon key={ii} name={ii} size="16px" mr={1} />)
            : icon && <Icon name={icon} size="16px" mr={1} />}

          {arrowIconPlacement === "left" && arrowIcon}

          <Text
            as="span"
            small={small}
            whiteSpace="nowrap"
            typoStyle="xxs"
            lineHeight={small ? "1" : undefined}
            css={`
              text-overflow: ellipsis;
              max-width: 200px;
              overflow: hidden;
            `}
          >
            {children}
          </Text>

          {arrowIconPlacement === "right" && arrowIcon}
        </AnchorWrapper>
      </AnchorElement>
    );
  }
);

InfoAnchor.displayName = "InfoAnchor";

export const Info = ({ title, value, large, color, ...props }: Props) => (
  <Box mb={4} maxWidth="20rem" color={color as any} {...props}>
    <Text
      fontSize={large ? 1 : 0}
      m={0}
      mb={large ? 2 : 2}
      lineHeight={0}
      fontWeight="normal"
    >
      {title}
    </Text>

    <Text fontSize={large ? 3 : 2} mt={0}>
      {value}
    </Text>
  </Box>
);

Info.Anchor = InfoAnchor;
