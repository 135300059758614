import React from "react";
import { Formik } from "formik";
import Table from "../Table";
import {
  Label,
  Input,
  Button,
  ButtonGroup,
  Flex,
  Box
} from "@urbaninfrastructure/react-ui-kit";
import { I18NUpdate, I18NCancel } from "../buttons-i18n";
import { FormWrapper } from "./styled";
import { DataTableColumnGeneric } from "./types";
import { TrashButton } from "components/TrashButton";

type DataTableRow = any;

type Props = {
  onCancelClick: () => void;
  onDeleteClick?: (key: string) => void;
  row: DataTableRow;
  columns: Array<DataTableColumnGeneric<DataTableRow, string>>;
  i: number;
  getRowProps: (index: number) => DataTableRow;
  onSubmit: (
    values: Record<string, any>
  ) => void | Promise<(values: Record<string, any>) => void>;
  formWrapperProps: Record<string, any>;
};

const DataTableFormRow = ({
  columns,
  row,
  onCancelClick,
  onDeleteClick,
  i,
  getRowProps,
  onSubmit,
  formWrapperProps
}: Props) => {
  const initialValuesFunction = () => {
    const values = {};
    columns.map(column => {
      if (column.notSubmittable) {
        return null;
      }
      const rowKey = Object.keys(row).find(
        rowKey => column.property === rowKey
      );
      if (!rowKey) {
        return null;
      }

      let rowValue = row[rowKey];

      if (column.cell && column.cell.submitFormatter) {
        rowValue = column.cell.submitFormatter({ value: rowValue, row });
      }

      values[column.property] = rowValue;
    });
    return values;
  };
  const initialValues = initialValuesFunction();
  return (
    <Table.Tr key={i} {...getRowProps(i)}>
      <Table.Td px={0} py={0} colSpan={columns.length + 1}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <FormWrapper {...formWrapperProps}>
                {columns.map(column => {
                  if (column.notSubmittable) {
                    return null;
                  }
                  const id = `input-${column.key || column.property}`;
                  return (
                    <Box flex="1 1 auto" px={4} mt={3} key={column.property}>
                      {column.editableComponent ? (
                        column.editableComponent({
                          label: column.header.label,
                          name: column.property,
                          onChange: handleChange,
                          setFieldValue: setFieldValue,
                          onBlur: handleBlur,
                          value: values[column.property],
                          options: column.options
                        })
                      ) : (
                        <>
                          <Label htmlFor={id}>{column.header.label}</Label>
                          <Input
                            id={id}
                            disabled
                            value={values[column.property]}
                          />
                        </>
                      )}
                    </Box>
                  );
                })}
                <Flex alignItems="flex-end" ml="auto" mt={3}>
                  <ButtonGroup flexWrap="nowrap">
                    {onDeleteClick && (
                      <TrashButton handleClick={() => onDeleteClick(row.id)} />
                    )}
                    <Button type="button" onClick={onCancelClick}>
                      <I18NCancel />
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                      data-testid="DataTableFormCreateRow__UpdateButton"
                    >
                      <I18NUpdate />
                    </Button>
                  </ButtonGroup>
                </Flex>
              </FormWrapper>
            </form>
          )}
        </Formik>
      </Table.Td>
    </Table.Tr>
  );
};

DataTableFormRow.defaultProps = {
  formWrapperProps: {},
  onCancelClick: () => {
    // noop
  },
  onSubmit: () => {
    // noop
  }
};

export default DataTableFormRow;
