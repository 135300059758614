import * as string from "./string";

export { default as array } from "./array";
export { default as redirect } from "./redirect";
export { string };

// probably not safe, but will do for this use case. the real id is returned from the server when the entity is created, so it only needs to live for a short time.
export function createRandomId(): string {
  return Math.random()
    .toString(36)
    .slice(2);
}

export const isServer = typeof window === "undefined";

// http://redux.js.org/docs/recipes/reducers/ImmutableUpdatePatterns.html#updating-an-item-in-an-array
export function updateObjectInArray<T extends Record<string, unknown>>(
  array: T[],
  action: { idx: number; item: T }
): T[] {
  return array.map((item, idx) => {
    if (idx !== action.idx) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item
    };
  });
}

// Parse semver version, date and gitsha from firmware version string
// Example string: "v2.1.1-beta1 (02-07-2019_13_43_48):69b2be202b6dc020eaf4c9ab7c126ed0017e6a90"
type ParsedFirmware = {
  version: string;
  date?: string | undefined;
  gitsha?: string | undefined;
};
export function parseFirmwareVersion(value: string): ParsedFirmware | null {
  const [versionAndDate, gitsha] = value.split(":");
  let version;
  let date;
  const splitted = versionAndDate.split("(");
  if (splitted.length) {
    version = splitted[0];
    date = splitted[1];
  }
  if (!version) {
    return null;
  }
  version = version.trim();
  if (date && date.endsWith(")")) {
    date = date.substring(0, date.length - 1);
  }
  return { version, date, gitsha };
}
