import styled from "styled-components";
import { Text } from "@urbaninfrastructure/react-ui-kit";

type Props = {
  id: string | number;
};

const TextId = styled(Text).attrs((props: Props) => ({
  title: props.id,
  children: props.id
}))``;

TextId.defaultProps = {
  as: "span",
  display: "inline-block",
  fontFamily: "mono",
  small: true,
  px: 1,
  bg: "neutral.2"
};

export default TextId;
