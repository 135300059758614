import React from "react";
import { Flex, FlexProps } from "@urbaninfrastructure/react-ui-kit";
import styled, { css } from "styled-components";
import { HorizontalOverflow } from "../HorizontalOverflow";
import theme from "../theme";

type Props = {
  block?: boolean;
  noWrap?: boolean;
};
type InnerProps = React.PropsWithChildren<Props> & FlexProps;

const InfoWrapper = styled(Flex)<Props>`
  justify-content: flex-start;
  flex-wrap: ${({ noWrap }) => (noWrap ? "nowrap" : "wrap")};
  min-width: 100%;
  margin-left: -${({ theme }) => theme.space[4]}px;
  margin-right: -${({ theme }) => theme.space[4]}px;
  ${({ block }) =>
    block &&
    css`
      margin-bottom: ${({ theme }) => theme.space[4]}px;
    `}
  ${theme.mediaQueries[2]} {
    margin-left: -${({ theme }) => theme.space[5]}px;
    margin-right: -${({ theme }) => theme.space[5]}px;
  }

  & > * {
    margin: ${({ noWrap, theme }) => (noWrap ? 0 : theme.space[4])}px
      ${({ theme }) => theme.space[4]}px;
    ${theme.mediaQueries[2]} {
      margin: ${({ noWrap, theme }) => (noWrap ? 0 : theme.space[5])}px
        ${({ theme }) => theme.space[5]}px;
      margin-top: 0;
    }
  }
`;

InfoWrapper.defaultProps = {
  inline: true
};

const InfoGroup = ({ noWrap, block, color, ...props }: InnerProps) => {
  return noWrap ? (
    <HorizontalOverflow mb={block ? 5 : 0}>
      <InfoWrapper noWrap color={color as any} {...props} />
    </HorizontalOverflow>
  ) : (
    <InfoWrapper block={block} color={color as any} {...props} />
  );
};

InfoGroup.defaultProps = {
  theme
};

InfoGroup.displayName = "InfoGroup";

export default InfoGroup;
