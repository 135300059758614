/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/default */
import React from "react";
import Tag from "../Tag";
import TagGroup from "../TagGroup";
import { EntityTag } from "../../src/core-types";

type Props = {
  tags?: EntityTag[] | null;
};

export default function EntityTags({ tags }: Props) {
  return tags && tags.length > 0 ? (
    <TagGroup>
      {tags.map(entityTag =>
        entityTag && entityTag.tag ? (
          <Tag
            key={entityTag.id}
            bg={entityTag.tag.colour || undefined}
            type={entityTag.tag.systemTag ? "system" : undefined}
          >
            {entityTag.tag.name}
          </Tag>
        ) : null
      )}
    </TagGroup>
  ) : null;
}
