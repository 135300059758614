import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import {
  Box,
  H2,
  H3,
  LoadableButton,
  Text
} from "@urbaninfrastructure/react-ui-kit";

import ModalRenderer from "../ModalRenderer";
import { Section, SectionHeading, SectionRow, SectionLink } from "../Section";
import Time from "../Time";

import {
  mutationLog as mutationLogQuery,
  mutationLogVariables,
  Entity,
  mutationLog_mutationLog_edges_node_mutations
} from "../../src/core-types";

const LineThrough = styled.span`
  text-decoration: line-through;
`;
const Capitalize = styled.span`
  text-transform: capitalize;
`;
function stringify(strOrObj: any | null): string {
  if (typeof strOrObj === "string") {
    return strOrObj;
  }
  return JSON.stringify(strOrObj);
}
const RenderLine = ({
  path,
  kind,
  rhs,
  lhs
}: mutationLog_mutationLog_edges_node_mutations) => {
  lhs = stringify(lhs);
  rhs = stringify(rhs);
  if (kind === "A") {
    // @todo figure out what A is
    return null;
  }
  if (kind === "N") {
    return (
      <>
        <Text color="gray" small mt="xxs">
          <Capitalize>{path}</Capitalize>
        </Text>
        <Text as="span" bg="paletteLight.green01">
          {rhs}
        </Text>
      </>
    );
  }
  if (kind === "E") {
    return (
      <>
        <Text color="gray" small mt="xxs">
          <Capitalize>{path}</Capitalize>
        </Text>
        <Text>
          <LineThrough>{lhs}</LineThrough> {rhs}
        </Text>
      </>
    );
  }
  if (kind === "D") {
    return (
      <>
        <Text color="gray" small mt="xxs">
          <Capitalize>{path}</Capitalize>
        </Text>
        <Text as="span" bg="paletteLight.red01">
          <LineThrough>{lhs}</LineThrough>
        </Text>
      </>
    );
  }
  return null;
};

const RevisionHistory = ({
  entityId,
  entityKind
}: {
  entityId: string;
  entityKind: Entity;
}) => {
  const [variables, setVariables] = React.useState({
    entityId,
    entityKind,
    cursor: { first: 10 }
  });

  const queryProps = useQuery<mutationLogQuery, mutationLogVariables>(
    gql`
      query mutationLog(
        $entityId: ID!
        $entityKind: Entity!
        $cursor: Cursor
        $options: FilterAndOrder
      ) {
        mutationLog(
          entityId: $entityId
          entityKind: $entityKind
          cursor: $cursor
          options: $options
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              createdAt
              mutations {
                lhs
                rhs
                kind
                index
                path
                item {
                  lhs
                  rhs
                  kind
                  index
                  path
                }
              }
            }
          }
        }
      }
    `,
    {
      variables,
      fetchPolicy: "cache-and-network",
      ssr: false,
      notifyOnNetworkStatusChange: true
    }
  );
  const mutationLog =
    queryProps && queryProps.data && queryProps.data.mutationLog;
  const loading = queryProps && queryProps.loading;

  if (!mutationLog || !mutationLog.edges || !mutationLog.edges.length) {
    return null;
  }

  return (
    <ModalRenderer
      closeOnOutsideClick
      render={() => {
        return (
          <>
            <H2>Revision history</H2>
            {mutationLog.edges.map(edge => {
              const { node: log } = edge;
              return (
                <Box key={log.id} ml={-6} mr={-6} mt="sm" p="sm" bg="#F5F8F9">
                  <H3>
                    Changes done: <Time value={log.createdAt} />
                  </H3>
                  {log &&
                    log.mutations &&
                    log.mutations.map(
                      (mutation, i) =>
                        mutation && (
                          <React.Fragment key={`${log.id}-${i}`}>
                            <RenderLine {...mutation} />
                          </React.Fragment>
                        )
                    )}
                </Box>
              );
            })}
            {mutationLog.pageInfo.hasNextPage && (
              <LoadableButton
                loading={loading}
                onClick={() =>
                  setVariables({
                    ...variables,
                    cursor: { first: variables.cursor.first + 10 }
                  })
                }
                mt="xs"
              >
                Load more
              </LoadableButton>
            )}
          </>
        );
      }}
      renderToggler={({ openPortal }) => (
        <Box mt="xs">
          <Section mb="xs">
            <SectionHeading>Revisions</SectionHeading>
            <SectionRow
              title="Last changes"
              value={
                <Time
                  threshold={168}
                  value={
                    mutationLog.edges[0].node &&
                    mutationLog.edges[0].node.createdAt
                  }
                />
              }
            />
            <SectionLink onClick={openPortal}>See all revisions</SectionLink>
          </Section>
        </Box>
      )}
    />
  );
};

export default RevisionHistory;
