import styled, { css } from "styled-components";
import { Box } from "@urbaninfrastructure/react-ui-kit";

export const Well = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral[1]};
    border-radius: ${theme.radii.md};
    border: 1px solid ${theme.colors.borderGray};
    padding: ${theme.space[4]}px;
    max-width: 50rem;
  `}
`;
