import useGlobalState from "./useGlobalState";
import {
  NotificationMeta,
  addNotification,
  addNotificationWithTTL,
  NotificationId,
  NotificationLevel
} from "../context/globalState";

export function useNotifications(): {
  addNotificationWithTTL: (
    message: React.ReactNode,
    meta?: NotificationMeta,
    body?: React.ReactNode
  ) => void;
  addNotification: (
    id: NotificationId,
    message: React.ReactNode,
    level?: NotificationLevel,
    body?: React.ReactNode,
    removeOnRouteChange?: boolean,
    minimal?: boolean
  ) => void;
} {
  const [, dispatch] = useGlobalState();
  return {
    addNotificationWithTTL: (message, meta, body) => {
      addNotificationWithTTL(message, meta, body)(dispatch);
    },
    addNotification: (...args) => {
      dispatch(addNotification(...args));
    }
  };
}
