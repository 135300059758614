import styled from "styled-components";
import { Text } from "@urbaninfrastructure/react-ui-kit";
import { space, SpaceProps } from "styled-system";

export const Form = styled.form<SpaceProps>`
  ${space};
`;

export const Legend = Text.withComponent("legend");

Legend.defaultProps = {
  color: "neutral.5",
  mb: 2
};
Legend.displayName = "Legend";
