import React from "react";
import { InfinitySymbol } from "@urbaninfrastructure/react-icons";

const ManagedByOperations = (props: any) => (
  <span
    title="Currently managed by an operations crew"
    style={{ cursor: "help" }}
  >
    <InfinitySymbol {...props} />
  </span>
);

export default ManagedByOperations;
