import React from "react";
import TabsNav from "./TabsNav";
import { EnhancedTabProps, TabProps } from "./types";

type Props = {
  tabs?: Array<TabProps>;
  onSelect?: Function;
  large?: boolean;
};

type State = {
  selectedTab: number | null | undefined;
};

export default class Tabs extends React.PureComponent<Props, State> {
  static defaultProps = {
    onSelect: () => {
      // noop
    },
    tabs: []
  };

  constructor(props: Props) {
    super(props);

    // Set the selected tab to the first tab with defaultSelected provided
    let defaultSelectedIndex: number | null | undefined = null;
    if (props && props.tabs) {
      for (let i = 0; i < props.tabs.length; i++) {
        if (props.tabs[i].defaultSelected) {
          defaultSelectedIndex = i;
          break;
        }
      }
    }

    this.state = {
      selectedTab: defaultSelectedIndex
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.tabs && this.props.tabs !== prevProps.tabs) {
      let defaultSelectedIndex: number | null | undefined = null;
      for (let i = 0; i < this.props.tabs.length; i++) {
        if (this.props.tabs[i].defaultSelected) {
          defaultSelectedIndex = i;
          break;
        }
      }

      this.setState({
        selectedTab: defaultSelectedIndex
      });
    }
  }

  getTabs = (): Array<EnhancedTabProps> => {
    if (!this.props.tabs) {
      return [];
    }
    return this.props.tabs.map<EnhancedTabProps>((tab, index) => ({
      ...tab,
      isSelected: index === this.state.selectedTab,
      onKeyboardNav: this.tabKeyboardNavHandler,
      onSelect: () => this.tabSelectHandler(index)
    }));
  };

  tabSelectHandler = (selectedTabIndex: number | null | undefined) => {
    this.props.onSelect && this.props.onSelect(selectedTabIndex);
    this.setState({ selectedTab: selectedTabIndex });
  };

  tabKeyboardNavHandler = (key: string) => {
    // Handle left and right arrow key presses by selecting the previous or next tab
    const selectedIndex = this.state.selectedTab;
    if (
      selectedIndex !== null &&
      this.props.tabs &&
      typeof selectedIndex === "number"
    ) {
      let nextIndex = selectedIndex;

      if (key === "ArrowLeft") {
        nextIndex = selectedIndex - 1 < 0 ? 0 : selectedIndex - 1;
      } else if (key === "ArrowRight") {
        const { tabs } = this.props;
        nextIndex =
          selectedIndex + 1 > tabs.length - 1
            ? tabs.length - 1
            : selectedIndex + 1;
      }

      if (nextIndex !== selectedIndex) {
        this.tabSelectHandler(nextIndex);
      }
    }
  };

  render() {
    const { large } = this.props;
    const tabs = this.getTabs();
    const selectedTabs = tabs && tabs.filter(tab => tab.isSelected);
    const keyhandler = this.tabKeyboardNavHandler;

    const TabsNavElement = (
      <TabsNav onKeyboardNav={keyhandler} tabs={tabs} large={large} />
    );

    return (
      <div>
        {TabsNavElement}
        {selectedTabs && selectedTabs.length && (
          <div role="tabpanel">{selectedTabs[0].content}</div>
        )}
      </div>
    );
  }
}
