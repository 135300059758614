import { UrlObject } from "url";
import { ParsedUrlQueryInput } from "querystring";
import { LinkProps } from "next/link";
import { useRouter } from "next/router";
import useSystem from "./useSystem";

export type SystemLinkHref =
  | Pathname
  | { pathname: Pathname; query?: ParsedUrlQueryInput | null };

export interface SystemLinkProps extends LinkProps {
  href: SystemLinkHref;
}

interface TransitionOptions {
  shallow?: boolean;
}

export function useSystemRouter(): {
  systemId: string;
  pathname: Pathname;
  getSystemHref: (href: SystemLinkHref) => UrlObject;
  pushRoute: (href: SystemLinkHref, options?: TransitionOptions) => void;
  replaceRoute: (href: SystemLinkHref, options?: TransitionOptions) => void;
} {
  const system = useSystem();
  const router = useRouter();
  const systemId = system.id;
  const pathname = router.pathname as Pathname;

  function pushRoute(href: LinkProps["href"], options?: TransitionOptions) {
    const systemHref = getSystemHref(href);
    router.push(systemHref, undefined, options);
  }

  function replaceRoute(href: LinkProps["href"], options?: TransitionOptions) {
    const systemHref = getSystemHref(href);
    router.replace(systemHref, undefined, options);
  }

  function getSystemHref(href: LinkProps["href"]): UrlObject {
    const systemHref: UrlObject =
      typeof href === "string" ? { pathname: href, query: {} } : href;

    if (!systemHref.pathname) {
      throw new Error("SystemLink requires pathname");
    }

    if (typeof systemHref.query === "string") {
      throw new Error("SystemLink does not support string as query");
    }

    systemHref.query = systemHref.query || {};

    if (
      systemHref.pathname.includes("/[systemId]") &&
      !systemHref.query.systemId
    ) {
      systemHref.query.systemId = systemId;
    }

    return systemHref;
  }

  return { systemId, pathname, getSystemHref, pushRoute, replaceRoute };
}
