import React from "react";
import styled, { css, keyframes } from "styled-components";
import {
  Box,
  BoxProps,
  Flex,
  theme,
  UIKitTheme as Theme
} from "@urbaninfrastructure/react-ui-kit";
import Time from "../Time";
import { Anchor } from "../Anchor";
import { Warning } from "@urbaninfrastructure/react-icons";
import { UserAgent } from "components/UserAgent";

type Themed = {
  small?: boolean;
  theme: Theme;
};

type GenericProps = {
  small?: boolean;
};

type TimelineIconProps = {
  processStarted?: boolean;
  processInProgress?: boolean;
  processResuming?: boolean;
  small?: boolean;
  pulsingIcon?: boolean;
};

const pulseAnimation = keyframes`
  0%   { opacity: 1 }
  50%  { opacity: 0 }
  100% { opacity: 1 }
`;

const TimelineIcon = styled(Box).attrs<TimelineIconProps>(
  ({
    processStarted,
    processInProgress,
    processResuming,
    small,
    pulsingIcon
  }) => {
    const process = processStarted || processInProgress || processResuming;
    const borderColor = process ? "neutral.5" : "borderGray";
    let backgroundColor = "borderGray";
    if (small) {
      backgroundColor = "white";
    } else if (process) {
      backgroundColor = "neutral.5";
    }
    return { backgroundColor, borderColor, pulsingIcon };
  }
)<TimelineIconProps>`
  ${({ pulsingIcon }) =>
    pulsingIcon &&
    css`
      animation: ${pulseAnimation} 1s infinite linear;
    `}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const headingCss = ({ theme, small }: Themed) => css`
  a {
    color: ${theme.colors.text};
    &:hover,
    &:focus {
      color: ${theme.colors.primary};
      border-bottom-color: ${theme.colors.primary};
    }
  }
  p {
    margin: 0;
  }
  ${small &&
    css`
      font-size: ${theme.fontSizes[0]}px;
      color: ${theme.colors.neutral[5]};
    `};
`;

export const datetimeCss = ({ theme }: Themed) => css`
  float: right;
  color: ${theme.colors.neutral[5]};
  font-size: ${theme.fontSizes[0]}px;
  padding-left: ${theme.space[1]}px;
  > a {
    color: ${theme.colors.neutral[5]};
    text-decoration: none;
    &:hover,
    &:focus {
      color: ${theme.colors.text};
      text-decoration: underline;
    }
  }
`;

export const optionsCss = ({ theme, small }: Themed) => css`
  position: absolute;
  top: 0;
  transform: translate(0, -50%);
  right: ${theme.space[3]}px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: ${({ theme }) => theme.radii["md"]};
  opacity: 0;
  ${small &&
    css`
      top: -${theme.space[2]}px;
    `};
`;

export const descriptionCss = ({ theme }: Themed) => css`
  font-size: ${theme.fontSizes[0]}px;
  color: ${theme.colors.neutral[5]};
  margin-top: ${theme.space[1]}px;
  > a {
    color: ${theme.colors.neutral[5]};
    &:hover,
    &:focus {
      color: ${theme.colors.text};
      border-bottom-color: ${theme.colors.text};
    }
  }
`;

const Content = styled(Box)``;
const Heading = styled(Flex)<GenericProps>`
  ${headingCss};
`;
const Datetime = styled.div<GenericProps>`
  ${datetimeCss};
`;
const Options = styled.div<GenericProps>`
  ${optionsCss};
`;
const Description = styled.div`
  ${descriptionCss};
`;

export const baseCss = ({ theme, small }: Themed) => css`
  animation: ${fadeIn} 200ms ease-out;
  ${small &&
    css`
      ${Description} {
        display: none;
      }
    `};
  &:hover,
  &:focus-within {
    background: ${theme.colors.neutral[1]};
    border-bottom: 1px solid ${theme.colors.borderGray};
    box-shadow: 0 -1px 0 0 ${theme.colors.borderGray};
    ${Content} {
      border-bottom: none;
    }
    ${Options} {
      opacity: 1;
      animation: ${fadeIn} 200ms ease-out;
    }
    ${TimelineIcon} {
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};
    }
    ${Anchor} {
      text-decoration: underline;
    }
    ${small &&
      css`
        ${Description} {
          display: block;
          animation: ${fadeIn} 200ms ease-out;
        }
      `};
  }
`;

const Base = styled(Flex)`
  ${baseCss};
`;

export type Props = BoxProps & {
  id: string;
  heading?: React.ReactNode;
  datetime?: string | null | undefined;
  description?: React.ReactNode;
  /** Pass in OptionsBar */
  options?: React.ReactNode;
  children?: React.ReactNode;
  first?: boolean;
  small?: boolean;
  processStarted?: boolean;
  processInProgress?: boolean;
  processResuming?: boolean;
  pulsingIcon?: boolean;
  indent?: boolean;
  /** Display a warning icon by default. Can be overwritten by using the icon property */
  variant?: "danger";
  icon?: (props: {
    display?: "block";
    size: string | number | Array<string | number>;
  }) => JSX.Element;
  href?: string;
  userAgent?: {
    __typename: "UserAgent";
    isIOS: boolean | null;
    isAndroid: boolean | null;
    appName: string | null;
    browser: string | null;
    original: string | null;
  } | null;
};

const TimelineItem = ({
  id,
  heading,
  datetime,
  description,
  children,
  options,
  first,
  small,
  processStarted,
  processInProgress,
  processResuming,
  pulsingIcon,
  indent,
  variant,
  href,
  icon,
  color,
  userAgent,
  ref,
  ...props
}: Props &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) => {
  const iconSize = small ? 12 : 16;
  return (
    <Base
      id={id}
      data-testid="TimelineItem"
      small={small}
      alignItems="stretch"
      width={1}
      maxWidth="90em"
      color={color as any}
      {...props}
    >
      <Box position="relative" mb={small ? -2 : -4} width={24}>
        {!first && (
          <Box
            position="absolute"
            top={small ? 3 : 5}
            bottom={0}
            left="50%"
            width="1px"
            height="100%"
            backgroundColor={processInProgress ? "neutral.5" : "borderGray"}
            css={`
              transform: translate(-50%, 0);
            `}
          />
        )}
        <Box
          position="absolute"
          top={small ? 3 : 5}
          left="50%"
          css={`
            transform: translate(-50%, -20%);
          `}
        >
          {!variant && !icon && (
            <TimelineIcon
              processStarted={processStarted}
              processInProgress={processInProgress}
              processResuming={processResuming}
              pulsingIcon={pulsingIcon}
              small={small}
              borderRadius={16}
              size={10}
              borderWidth={1}
              borderStyle="solid"
            />
          )}
          {(variant === "danger" || icon) && (
            <Flex
              backgroundColor="white"
              size={iconSize + 8}
              borderRadius={iconSize + 8}
              mt={small ? -1 : -1}
              boxShadow="heavy"
              alignItems="center"
              justifyContent="center"
            >
              {variant === "danger" && !icon && (
                <Warning display="block" size={iconSize} color="state.error" />
              )}
              {icon && icon({ display: "block", size: iconSize })}
            </Flex>
          )}
        </Box>
      </Box>
      <Content
        position="relative"
        width={1}
        borderBottom="1px solid"
        borderBottomColor="borderGray"
        py={small ? 2 : 4}
        pr={3}
        pl={indent ? 4 : 3}
      >
        {heading && (
          <Heading small={small} justifyContent="center">
            <Box flex={1}>{heading}</Box>
            {userAgent && (
              <Box mr={1}>
                <UserAgent
                  userAgent={userAgent}
                  fontSize="12px"
                  color={theme.colors.neutral[5]}
                />
              </Box>
            )}
            <Datetime small={small}>
              {href && (
                <Anchor href={href}>
                  <Time value={datetime} detailed />
                </Anchor>
              )}
              {!href && <Time value={datetime} detailed />}
            </Datetime>
          </Heading>
        )}
        {options && <Options small={small}>{options}</Options>}
        {description && <Description>{description}</Description>}
        {children && <Box mt={4}>{children}</Box>}
      </Content>
    </Base>
  );
};

export default TimelineItem;
