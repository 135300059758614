import React from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps, color, ColorProps } from "styled-system";
import { Tr as UIKitTr } from "@urbaninfrastructure/react-ui-kit";

type TrProps = SpaceProps &
  ColorProps & {
    onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  };

export const Tr = styled(UIKitTr)<TrProps>`
  ${({ theme, onClick }) => {
    return (
      onClick &&
      typeof onClick === "function" &&
      css<TrProps>`
        transition: background-color 200ms ease-out;
        cursor: pointer;
        a {
          text-decoration: none;
          &:hover {
            border-bottom: 1px solid ${theme.colors.primary};
            color: ${theme.colors.primary};
          }
        }
        &:hover {
          background: ${theme.colors.neutral[1]};
        }
      `
    );
  }};
  ${color};
  ${space};
`;
