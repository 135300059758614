import { defineMessages } from "react-intl";

import { sentence } from "../utils/string";
import { useTranslate } from "../hooks";
import { VehicleCategory } from "../core-types";

const messages = defineMessages({
  bike: {
    id: "hooks.useVehicleCategoryName.bike",
    defaultMessage: "{count, plural, one {Bike} other {Bikes}}"
  },
  ebike: {
    id: "hooks.useVehicleCategoryName.ebike",
    defaultMessage: "{count, plural, one {E-bike} other {E-bikes}}"
  },
  scooter: {
    id: "hooks.useVehicleCategoryName.scooter",
    defaultMessage: "{count, plural, one {Scooter} other {Scooters}}"
  },
  ebike_with_childseat: {
    id: "hooks.useVehicleCategoryName.ebike_with_childseat",
    defaultMessage:
      "{count, plural, one {E-bike} other {E-bikes}} with child seat"
  }
});

const useVehicleCategoryName = () => {
  const translate = useTranslate();

  return (enumValue: string | VehicleCategory, count?: number): string =>
    enumValue && messages[enumValue]
      ? translate(messages[enumValue], { count: count || 1 })
      : sentence(enumValue);
};

export default useVehicleCategoryName;
