import React from "react";
import styled, { css } from "styled-components";
import {
  Flex,
  Box,
  Text,
  TextProps,
  UIKitTheme as Theme,
  FlexProps,
  BoxProps
} from "@urbaninfrastructure/react-ui-kit";
import { Right as RightIcon } from "@urbaninfrastructure/react-icons";
import RouteIcon from "../RouteIcon";
import { SystemLinkHref } from "../../src/hooks";
import { SystemLink } from "components";

type Props = {
  /** section without max-width */
  fluid?: boolean;
  title?: React.ReactNode;
  value?: React.ReactNode;
  showRouteIcon?: boolean;
  children?: React.ReactNode;
  external?: boolean;
  icon?: JSX.Element;
  onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
  noBorder?: boolean;
  width?: number;
} & (
  | {
      systemLinkHref: SystemLinkHref;
      route?: never;
      params?: never;
      url?: never;
    }
  | {
      url: string;
      route?: never;
      params?: never;
      systemLinkHref?: never;
    }
  | { url?: never; route?: never; params?: never; systemLinkHref?: never }
);

type StyledProps = Props & {
  theme: Theme;
};

const linkWrapperCss = ({
  theme,
  noBorder
}: {
  theme: Theme;
  noBorder?: boolean;
}) => {
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space[3]}px ${theme.space[4]}px;
    padding-right: ${theme.space[3]}px;
    border-top: ${() =>
      noBorder ? "none" : `1px solid ${theme.colors.borderGray}`};
    transition: background-color 200ms ease-out;
    cursor: pointer;
    text-decoration: none;
    a {
      text-decoration: none;
      &:hover {
        border-top: 1px solid ${theme.colors.primary};
        color: ${theme.colors.primary};
      }
    }
    &:hover,
    &:focus {
      background: ${theme.colors.neutral[1]};
      text-decoration: none;
    }
    &:first-child {
      border-top: none;
    }
  `;
};

export const Section = styled(Flex)``;

Section.defaultProps = {
  mx: "auto",
  mb: 5,
  flexDirection: "column",
  borderRadius: "md",
  border: "1px solid",
  borderColor: "borderGray",
  overflow: "hidden"
};

const SectionRowWrapper = styled(Flex)`
  border-top: ${({ theme, noBorder }: StyledProps) =>
    noBorder ? "none" : `1px solid ${theme.colors.borderGray}`};
  &:first-child {
    border-top: none;
  }
`;

const SectionRowSpanWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SectionLinkWrapper = styled.a`
  ${linkWrapperCss};
`;

const SectionHeadingH2 = styled(Text.withComponent("h2"))``;

export const SectionHeading = ({
  children,
  backgroundColor = "neutral.1",
  fontSize = 0,
  fontWeight = "normal",
  px = 4,
  py = 3,
  m = 0,
  noBorder,
  color,
  as,
  action,
  ...props
}: TextProps & {
  action?: React.ReactNode;
  noBorder?: boolean;
  children: React.ReactNode;
}) => {
  const heading = (
    <SectionHeadingH2
      fontSize={fontSize}
      fontWeight={fontWeight}
      m={m}
      py={py}
      color={color as any}
      as={as as any}
      {...props}
    >
      {children}
    </SectionHeadingH2>
  );
  return (
    <Flex
      alignItems="center"
      backgroundColor={backgroundColor}
      borderTop={noBorder ? "none" : "1px solid"}
      borderTopColor="borderGray"
      px={px}
      css={`
        &:first-child {
          border-top: none;
        }
      `}
    >
      <Box flex={1}>{heading}</Box>
      {action && <Box>{action}</Box>}
    </Flex>
  );
};

export const SectionContent = ({
  noBorder,
  borderTopWidth = 1,
  borderTopStyle = "solid",
  borderTopColor = "borderGray",
  p = 4,
  color,
  ...props
}: {
  children: React.ReactNode;
  noBorder?: boolean;
} & BoxProps) => {
  return (
    <Box
      p={p}
      borderTopColor={borderTopColor}
      borderTopWidth={noBorder ? 0 : borderTopWidth}
      borderTopStyle={borderTopStyle}
      color={color as any}
      css={`
        &:first-child {
          border-top: none;
        }
      `}
      {...props}
    />
  );
};

export const SectionLink = ({
  title,
  showRouteIcon,
  children,
  external,
  icon,
  onClick,
  noBorder,
  url,
  systemLinkHref
}: Props) => {
  let LinkComponent = <></>;
  if (systemLinkHref) {
    LinkComponent = (
      <SystemLink href={systemLinkHref}>
        <SectionLinkWrapper noBorder={noBorder} onClick={onClick}>
          <SectionRowSpanWrapper>
            {showRouteIcon && (
              <RouteIcon
                route={
                  typeof systemLinkHref === "string"
                    ? systemLinkHref
                    : systemLinkHref.pathname
                }
                mr={2}
                size="12px"
                style={{ verticalAlign: "middle" }}
              />
            )}
            {title}
            {children}
          </SectionRowSpanWrapper>
          <RightIcon color="gray" size="12px" />
        </SectionLinkWrapper>
      </SystemLink>
    );
  } else if (url) {
    LinkComponent = (
      <SectionLinkWrapper
        noBorder={noBorder}
        href={url}
        target={external ? "_blank" : undefined}
        onClick={onClick}
      >
        <SectionRowSpanWrapper>
          {icon}
          {title}
          {children}
        </SectionRowSpanWrapper>
        <RightIcon
          color="gray"
          size="12px"
          style={{ verticalAlign: "middle" }}
        />
      </SectionLinkWrapper>
    );
  } else if (onClick) {
    LinkComponent = (
      <SectionLinkWrapper noBorder={noBorder} onClick={onClick}>
        <SectionRowSpanWrapper>
          {icon}
          {title}
          {children}
        </SectionRowSpanWrapper>
        <RightIcon
          color="gray"
          size="12px"
          style={{ verticalAlign: "middle" }}
        />
      </SectionLinkWrapper>
    );
  }
  return LinkComponent;
};

export const SectionRow = ({
  title,
  value,
  children,
  onClick,
  noBorder,
  action,
  justifyContent = { _: "space-between", sm: "flex-start" },
  alignItems = "flex-start",
  color,
  ...props
}: Props & FlexProps & { action?: React.ReactNode }) => {
  const rowLabel = title && (
    <Box color="neutral.5" fontSize={0} pr={2} pt={1}>
      {title}
    </Box>
  );

  if (props.route) {
    return (
      <SectionLink
        noBorder={noBorder}
        route={props.route}
        params={props.params}
        onClick={onClick}
      >
        {rowLabel}
        {value && <Text>{value}</Text>}
        {children}
      </SectionLink>
    );
  }

  if (props.systemLinkHref) {
    return (
      <SectionLink
        noBorder={noBorder}
        systemLinkHref={props.systemLinkHref}
        onClick={onClick}
      >
        {rowLabel}
        {value && <Text>{value}</Text>}
        {children}
      </SectionLink>
    );
  }

  return (
    <SectionRowWrapper
      justifyContent={justifyContent}
      alignItems={alignItems}
      py={3}
      px={4}
      noBorder={noBorder}
      color={color as any}
      {...props}
    >
      {rowLabel}
      {!action && (
        <>
          {value}
          {children}
        </>
      )}
      {action && (
        <>
          <Box flex={1}>
            {value}
            {children}
          </Box>
          <Box>{action}</Box>
        </>
      )}
    </SectionRowWrapper>
  );
};
