import React from "react";
import {
  AriaSortMap,
  Props,
  RenderProps,
  SortDirection,
  SortProps
} from "./types";

export const ariaSortMap: AriaSortMap = {
  asc: "ascending",
  desc: "descending"
};

// Ported from https://github.com/jonespen/react-unsort/tree/v1.0.0-5
export class Sortable extends React.Component<Props> {
  props: Props;

  toggleKeys = ["Enter", " "]; // This matches <button> behavior

  getSortDirectionFor = (key: string): SortDirection | undefined => {
    const { sortBy } = this.props;
    if (sortBy && sortBy.key === key) {
      return sortBy.direction;
    }
  };

  getSortProps = (key: string) => {
    const { onSort } = this.props;
    const direction = this.getSortDirectionFor(key);
    const sortProps: SortProps = {
      role: "button",
      tabIndex: 0,
      onClick: () => onSort(key),
      onKeyUp: event => {
        if (this.toggleKeys.indexOf(event.key) > -1) {
          onSort(key);
        }
      }
    };
    if (direction) {
      sortProps.direction = direction;
      sortProps["aria-sort"] = ariaSortMap[direction];
    }
    return sortProps;
  };

  getStateAndHelpers(): RenderProps {
    return {
      getSortDirectionFor: this.getSortDirectionFor,
      getSortProps: this.getSortProps
    };
  }

  render() {
    return this.props.render(this.getStateAndHelpers());
  }
}
