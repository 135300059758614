import React from "react";
const UrbanSharingLogo = ({ onlyText }: { onlyText?: boolean }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={onlyText ? "170px" : "304.9px"}
      height={onlyText ? "100px" : "121.2px"}
      viewBox={onlyText ? "0 0 170 100" : "0 0 304.9 121.2"}
    >
      <title>Urban Sharing</title>
      <g transform={onlyText ? "translate(-138)" : ""}>
        <path
          d="M146,0.8v20.9c0,2.9,0.5,5.1,1.6,6.5c1.1,1.5,3,2.2,5.6,2.2c2.7,0,4.5-0.7,5.6-2.2c1.1-1.5,1.6-3.6,1.6-6.5V0.8h5.4v20
		c0,2.5-0.2,4.6-0.7,6.5c-0.5,1.8-1.2,3.3-2.2,4.5c-1,1.2-2.3,2.1-3.9,2.6c-1.6,0.6-3.6,0.9-5.9,0.9c-2.3,0-4.3-0.3-5.9-0.9
		c-1.6-0.6-2.9-1.5-3.9-2.6c-1-1.2-1.7-2.7-2.2-4.5c-0.5-1.8-0.7-4-0.7-6.5v-20H146z"
        />
        <path
          d="M173.7,34.7V9.4h5.3v4.9h0.2c0.4-1.3,1.1-2.4,2.2-3.4c1.1-1,2.7-1.5,4.7-1.5h1.4v5.1h-2.1c-2.1,0-3.7,0.3-4.8,1
		c-1.1,0.7-1.7,1.7-1.7,3v16.1H173.7z"
        />
        <path
          d="M192.3,0h5.3v13.6h0.2c0.5-1.5,1.4-2.7,2.7-3.5c1.2-0.8,2.7-1.2,4.4-1.2c3.2,0,5.7,1.2,7.4,3.5c1.7,2.3,2.6,5.6,2.6,9.7
		c0,4.2-0.9,7.4-2.6,9.7c-1.7,2.3-4.2,3.5-7.4,3.5c-1.7,0-3.1-0.4-4.4-1.3c-1.2-0.8-2.1-2-2.7-3.5h-0.2v4.2h-5.3V0z M203.3,30.7
		c1.8,0,3.3-0.6,4.4-1.8c1.1-1.2,1.6-2.7,1.6-4.7v-4.4c0-1.9-0.6-3.5-1.6-4.7c-1.1-1.2-2.6-1.8-4.4-1.8c-1.6,0-3,0.4-4.1,1.2
		c-1.1,0.8-1.6,1.8-1.6,3.1v8.6c0,1.3,0.5,2.3,1.6,3.1C200.3,30.3,201.7,30.7,203.3,30.7z"
        />
        <path
          d="M239.5,34.7c-1.4,0-2.5-0.4-3.2-1.2c-0.7-0.8-1.2-1.8-1.4-3h-0.2c-0.5,1.6-1.4,2.8-2.7,3.6c-1.3,0.8-2.9,1.2-4.7,1.2
		c-2.6,0-4.6-0.7-6-2c-1.4-1.4-2.1-3.2-2.1-5.5c0-2.5,0.9-4.4,2.7-5.7c1.8-1.3,4.5-1.9,8-1.9h4.6V18c0-1.6-0.4-2.7-1.3-3.6
		c-0.8-0.8-2.2-1.3-3.9-1.3c-1.5,0-2.7,0.3-3.6,1c-0.9,0.6-1.7,1.5-2.4,2.5l-3.2-2.9c0.8-1.4,2-2.6,3.5-3.5c1.5-0.9,3.5-1.4,6-1.4
		c3.3,0,5.8,0.8,7.5,2.3c1.7,1.5,2.6,3.7,2.6,6.5v12.7h2.7v4.3H239.5z M228.8,31.2c1.6,0,3-0.4,4.1-1.1c1.1-0.7,1.6-1.7,1.6-2.9
		v-3.6H230c-3.7,0-5.5,1.1-5.5,3.4v0.9c0,1.1,0.4,2,1.1,2.5C226.4,30.9,227.5,31.2,228.8,31.2z"
        />
        <path
          d="M247.6,34.7V9.4h5.3v4.2h0.2c0.5-1.4,1.4-2.5,2.5-3.4c1.1-0.9,2.6-1.4,4.6-1.4c2.6,0,4.6,0.8,6,2.5
		c1.4,1.7,2.2,4.1,2.2,7.3v16h-5.3V19.3c0-3.9-1.6-5.9-4.8-5.9c-0.7,0-1.4,0.1-2,0.3c-0.7,0.2-1.3,0.4-1.8,0.8
		c-0.5,0.4-0.9,0.8-1.2,1.4c-0.3,0.6-0.5,1.2-0.5,1.9v16.9H247.6z"
        />
        <path
          d="M150.9,88.4c-2.8,0-5.3-0.5-7.3-1.6c-2-1-3.7-2.4-5.1-4.2l3.8-3.5c1.2,1.5,2.5,2.6,4,3.3c1.4,0.7,3.1,1.1,4.9,1.1
		c2.2,0,3.8-0.5,4.9-1.5c1.1-1,1.6-2.3,1.6-3.9c0-1.3-0.4-2.4-1.2-3.2c-0.8-0.8-2.2-1.4-4.1-1.7l-3-0.5c-3.3-0.6-5.8-1.7-7.4-3.3
		s-2.5-3.7-2.5-6.4c0-1.5,0.3-2.8,0.8-4.1c0.6-1.2,1.4-2.2,2.4-3.1c1-0.8,2.3-1.5,3.7-1.9c1.5-0.5,3.1-0.7,4.9-0.7
		c2.6,0,4.8,0.4,6.6,1.3c1.9,0.9,3.5,2.2,4.8,3.9L159,62c-0.9-1.1-1.9-2-3.2-2.7s-2.8-1-4.7-1c-1.9,0-3.4,0.4-4.5,1.2
		c-1.1,0.8-1.6,1.9-1.6,3.4c0,1.4,0.4,2.5,1.3,3.2c0.9,0.7,2.2,1.2,4.1,1.6l3,0.6c3.4,0.6,5.9,1.7,7.4,3.3
		c1.6,1.6,2.4,3.7,2.4,6.4c0,1.6-0.3,3-0.8,4.3c-0.6,1.3-1.4,2.4-2.4,3.3c-1.1,0.9-2.3,1.6-3.9,2.1
		C154.6,88.2,152.9,88.4,150.9,88.4z"
        />
        <path
          d="M169.4,53.2h5.3v13.6h0.2c0.5-1.4,1.4-2.5,2.5-3.4c1.1-0.9,2.6-1.4,4.6-1.4c2.6,0,4.6,0.8,6,2.5c1.4,1.7,2.2,4.1,2.2,7.3
		v16H185V72.5c0-3.9-1.6-5.9-4.8-5.9c-0.7,0-1.4,0.1-2,0.3c-0.7,0.2-1.3,0.4-1.8,0.8c-0.5,0.4-0.9,0.8-1.2,1.4
		c-0.3,0.6-0.5,1.2-0.5,1.9v16.9h-5.3V53.2z"
        />
        <path
          d="M216.1,87.8c-1.4,0-2.5-0.4-3.2-1.2c-0.7-0.8-1.2-1.8-1.4-3h-0.2c-0.5,1.6-1.4,2.8-2.7,3.6c-1.3,0.8-2.9,1.2-4.7,1.2
		c-2.6,0-4.6-0.7-6-2c-1.4-1.4-2.1-3.2-2.1-5.5c0-2.5,0.9-4.4,2.7-5.7c1.8-1.3,4.5-1.9,8-1.9h4.6v-2.1c0-1.6-0.4-2.7-1.3-3.6
		c-0.8-0.8-2.2-1.3-3.9-1.3c-1.5,0-2.7,0.3-3.6,1c-0.9,0.6-1.7,1.5-2.4,2.5l-3.2-2.9c0.8-1.4,2-2.6,3.5-3.5c1.5-0.9,3.5-1.4,6-1.4
		c3.3,0,5.8,0.8,7.5,2.3c1.7,1.5,2.6,3.7,2.6,6.5v12.7h2.7v4.3H216.1z M205.4,84.4c1.6,0,3-0.4,4.1-1.1c1.1-0.7,1.6-1.7,1.6-2.9
		v-3.6h-4.5c-3.7,0-5.5,1.1-5.5,3.4V81c0,1.1,0.4,2,1.1,2.5C203,84.1,204.1,84.4,205.4,84.4z"
        />
        <path
          d="M224.2,87.8V62.6h5.3v4.9h0.2c0.4-1.3,1.1-2.4,2.2-3.4c1.1-1,2.7-1.5,4.7-1.5h1.4v5.1H236c-2.1,0-3.7,0.3-4.8,1
		c-1.1,0.7-1.7,1.7-1.7,3v16.1H224.2z"
        />
        <path
          d="M245.5,58.1c-1.1,0-1.9-0.3-2.4-0.8c-0.5-0.5-0.8-1.2-0.8-2v-0.8c0-0.8,0.3-1.5,0.8-2c0.5-0.5,1.3-0.8,2.4-0.8
		c1.1,0,1.9,0.3,2.4,0.8c0.5,0.5,0.7,1.2,0.7,2v0.8c0,0.8-0.2,1.5-0.7,2C247.3,57.8,246.6,58.1,245.5,58.1z M242.8,62.6h5.3v25.2
		h-5.3V62.6z"
        />
        <path
          d="M255.6,87.8V62.6h5.3v4.2h0.2c0.5-1.4,1.4-2.5,2.5-3.4c1.1-0.9,2.6-1.4,4.6-1.4c2.6,0,4.6,0.8,6,2.5
		c1.4,1.7,2.2,4.1,2.2,7.3v16h-5.3V72.5c0-3.9-1.6-5.9-4.8-5.9c-0.7,0-1.4,0.1-2,0.3c-0.7,0.2-1.3,0.4-1.8,0.8
		c-0.5,0.4-0.9,0.8-1.2,1.4c-0.3,0.6-0.5,1.2-0.5,1.9v16.9H255.6z"
        />
        <path
          d="M304.9,86.7c0,3.6-1,6.4-3,8.4c-2,2-4.9,3-8.7,3c-2.2,0-4.1-0.3-5.7-1c-1.5-0.7-2.8-1.5-3.8-2.6l3-3.5
		c1.6,1.7,3.8,2.6,6.4,2.6c2.1,0,3.7-0.6,4.8-1.7c1.1-1.1,1.6-2.8,1.6-4.9v-3.6h-0.2c-0.6,1.5-1.4,2.7-2.7,3.5
		c-1.2,0.8-2.7,1.3-4.4,1.3c-3.2,0-5.7-1.1-7.4-3.4c-1.7-2.3-2.6-5.5-2.6-9.7c0-4.1,0.9-7.4,2.6-9.7c1.7-2.3,4.2-3.4,7.4-3.4
		c1.7,0,3.1,0.4,4.4,1.2c1.2,0.8,2.1,2,2.7,3.5h0.2v-4.2h5.3V86.7z M293.9,83.7c1.6,0,3-0.4,4.1-1.2c1.1-0.8,1.6-1.8,1.6-3.1v-8.4
		c0-1.3-0.6-2.3-1.6-3.1c-1.1-0.8-2.5-1.2-4.1-1.2c-1.8,0-3.3,0.6-4.4,1.8c-1.1,1.2-1.6,2.7-1.6,4.7v4.2c0,1.9,0.5,3.5,1.6,4.7
		C290.6,83.1,292.1,83.7,293.9,83.7z"
        />
      </g>
      {!onlyText && (
        <path
          d="M103.6,0.8h-37v67.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,2.9-0.9,5.7-2.5,8.1c-2.8,4.1-7.4,6.6-12.3,6.6
		c-4.9,0-9.5-2.4-12.3-6.5c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c-1.6-2.4-2.5-5.3-2.5-8.2V0.8H0v67.8c0,10.2,3,20.2,8.6,28.7
		c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.4c9.7,14.1,25.7,22.5,42.8,22.5c17.3,0,33.3-8.5,43-22.9
		c5.7-8.4,8.7-18.3,8.8-28.5c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5V0.8z M6.6,82.2c-1.3-4.4-2-8.9-2-13.6V5.4h27.7v63.2
		c0,0.8,0.1,1.5,0.1,2.3c0.4,3,1.4,5.9,3.1,8.5c0,0,0,0.1,0.1,0.1c2,3.1,2.7,6.7,2,10.3c-0.7,3.6-2.8,6.8-5.9,8.8
		c-2.3,1.5-4.9,2.3-7.7,2.3c-4.5,0-8.7-2.2-11.3-5.8c-0.1-0.1-0.2-0.2-0.3-0.4C9.9,90.8,7.9,86.6,6.6,82.2z M91,95
		c-8.8,13-23.4,20.8-39.2,20.8c-10.9,0-21.2-3.7-29.5-10.4c0.6,0.1,1.2,0.1,1.7,0.1c3.6,0,7.2-1.1,10.2-3.1
		c4.1-2.7,6.9-6.9,7.9-11.7c0.3-1.7,0.4-3.4,0.3-5c2.8,1.6,6,2.4,9.4,2.4c6.5,0,12.5-3.2,16.2-8.6c0.4-0.6,0.8-1.3,1.2-2
		c1.4-2.7,2.1-5.6,2.1-8.7c0-0.1,0-0.2,0-0.2C71.5,61,77.7,55,85.2,55l0.1,0c3.7,0,7.2,1.5,9.8,4.1c2.5,2.5,3.9,5.9,4,9.4
		c0,0.1,0,0.3,0,0.4c0,4.6-0.7,9.1-2.1,13.5C95.6,86.9,93.6,91.1,91,95z M98.3,55.9c-3.5-3.5-8.1-5.5-13-5.5l-0.2,0
		c-5.5,0-10.5,2.4-13.9,6.3V5.4H99v51.3C98.8,56.4,98.6,56.2,98.3,55.9z"
        />
      )}
    </svg>
  );
};

export default UrbanSharingLogo;
