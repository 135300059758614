declare global {
  interface Window {
    gtag: any;
  }
}

//import { GA_TRACKING_ID } from "./constants";

const SYSTEM_ID_DIMENSION = "system_id";
const ROLE_IDS_DIMENSION = "role_ids";
const ADMIN_ID_DIMENSION = "admin_id";
//const ROUTE_PATH_DIMENSION = "route_path";

type EventPayload = {
  admin_id: string;
  event_category: string;
  event_label?: string;
  role_ids: string;
  system_id: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (): // pathname: string,
// asPath: string,
// adminId?: string
void => {
  // window.gtag("set", ROUTE_PATH_DIMENSION, pathname);
  // window.gtag("set", "content_group1", pathname);
  // window.gtag("config", GA_TRACKING_ID, {
  //   page_location: pathname,
  //   page_path: asPath,
  //   user_id: adminId,
  //   custom_map: {
  //     dimension1: SYSTEM_ID_DIMENSION,
  //     dimension2: ROLE_IDS_DIMENSION,
  //     dimension3: ADMIN_ID_DIMENSION,
  //     dimension4: ROUTE_PATH_DIMENSION
  //   }
  // });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackEvent = ({
  //action, // E.g. 'Paired controller and lock'
  category, // E.g. 'Assembly'
  adminId,
  roleIds,
  systemId,
  label = null,
  value = null
}: {
  action: string;
  category: string;
  adminId: string;
  roleIds: string[];
  systemId: string;
  label?: string | null;
  value?: number | null;
}): void => {
  const payload: EventPayload = {
    event_category: category,
    [SYSTEM_ID_DIMENSION]: systemId,
    [ROLE_IDS_DIMENSION]: roleIds && roleIds.length ? roleIds.join(";") : "",
    [ADMIN_ID_DIMENSION]: adminId
  };

  if (label !== null) {
    payload.event_label = label;
  }

  if (value !== null) {
    payload.value = value;
  }

  //window.gtag("event", action, payload);
};

export const timingComplete = ({
  category,
  name,
  value,
  label
}: {
  category: string;
  name: string;
  value: number;
  label?: string;
}): void => {
  window.gtag("event", "timing_complete", {
    event_category: category,
    event_label: label,
    name: name,
    value: value
  });
};
