import React from "react";
import { useSelect } from "downshift";
import { Dialog, Button } from "../";
import { Box, AnchorButton } from "@urbaninfrastructure/react-ui-kit";
import { Cog as CogIcon } from "@urbaninfrastructure/react-icons";

const menuStyles = {
  position: "absolute",
  margin: 0,
  right: "15px",
  borderTop: 0,
  background: "white",
  boxShadow:
    "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px",
  borderRadius: "3px",
  zIndex: 1
};

const ActionsDialog = ({
  items
}: {
  items: Array<{
    name: string;
    content: React.ReactNode;
  }>;
}) => {
  const [dialogHeading, setDialogHeading] = React.useState("");
  const [dialogContent, setDialogContent] = React.useState(
    <div>placeholder</div>
  );

  return (
    <Dialog
      heading={dialogHeading}
      closeOnEsc={true}
      closeOnOutsideClick={true}
      renderOpenButton={openPortal => (
        <DropdownSelect
          items={items}
          setDialogHeading={setDialogHeading}
          setDialogContent={setDialogContent}
          openPortal={openPortal}
        />
      )}
    >
      {dialogContent}
    </Dialog>
  );
};

const DropdownSelect = ({
  items,
  setDialogHeading,
  setDialogContent,
  openPortal
}: {
  items: Array<{
    name: string;
    content: React.ReactNode;
  }>;
  setDialogHeading: React.Dispatch<React.SetStateAction<string>>;
  setDialogContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  openPortal: any;
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps
  } = useSelect({
    items
  });
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Button resetted {...getToggleButtonProps()}>
        <CogIcon
          color="neutral.5"
          display="block"
          size="20px"
          aria-label="Table actions"
        />
      </Button>
      <div {...getMenuProps()} style={menuStyles}>
        {isOpen &&
          items.map((item, index) => (
            <Box
              key={`${item.name}${index}`}
              p={2}
              {...getItemProps({ item, index })}
            >
              <AnchorButton
                onClick={e => {
                  setDialogHeading(item.name);
                  item.content && setDialogContent(item.content);
                  openPortal(e);
                }}
              >
                {item.name}
              </AnchorButton>
            </Box>
          ))}
      </div>
    </div>
  );
};

export default ActionsDialog;
