import React from "react";
import Head from "next/head";
import {
  Flex,
  Box,
  ButtonGroup,
  Button,
  ButtonAnchor
} from "@urbaninfrastructure/react-ui-kit";
import { SystemLink } from "./SystemLink";

type Props = React.PropsWithChildren<{
  title?: string;
}>;

const NotFound = ({ title = "Not found", children }: Props) => {
  return (
    <Flex
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Head>
        <title>{title}</title>
      </Head>

      <Box mb="sm">{children}</Box>

      <ButtonGroup>
        <Button type="button" onClick={() => window.history.go(-1)}>
          Go back
        </Button>

        <SystemLink href="/[systemId]">
          <ButtonAnchor>Go home</ButtonAnchor>
        </SystemLink>
      </ButtonGroup>
    </Flex>
  );
};

export default NotFound;
