import React from "react";
import ManageTagsDialog from "../ManageTagsDialog";
import {
  Entity,
  EntityTag,
  createEntityTagMutation,
  createEntityTagMutationVariables,
  deleteEntityTagMutation,
  deleteEntityTagMutationVariables,
  tagsQueryVariables,
  tagsQuery
} from "../../src/core-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CREATE_ENTITY_TAG_MUTATION,
  DELETE_ENTITY_TAG_MUTATION,
  TAGS_QUERY
} from "./graphql";

type EntityType = "DockGroup" | "Trip" | "Vehicle" | "User";

export default function EntityTagsEditDialog({
  tags,
  entity,
  systemId,
  onCompleted,
  includeSystemTags = false
}: {
  includeSystemTags?: boolean;
  tags: EntityTag[] | null;
  entity: {
    id: string;
    name: string;
    type: EntityType;
  };
  systemId: string;
  onCompleted: () => void;
}) {
  const [createEntityTag, { loading: createLoading }] = useMutation<
    createEntityTagMutation,
    createEntityTagMutationVariables
  >(CREATE_ENTITY_TAG_MUTATION, {
    onCompleted
  });
  const [deleteEntityTag, { loading: deleteLoading }] = useMutation<
    deleteEntityTagMutation,
    deleteEntityTagMutationVariables
  >(DELETE_ENTITY_TAG_MUTATION, {
    onCompleted
  });

  const { id: entityId, name: entityName, type: entityKind } = entity;

  const { data } = useQuery<tagsQuery, tagsQueryVariables>(TAGS_QUERY, {
    variables: { entityKind: entityKind as Entity, systemId, includeSystemTags }
  });

  const entityTags = tags || [];
  const existingTags = data && data.tags ? data.tags : [];
  return (
    <ManageTagsDialog
      heading={`Edit tags on ${entityName}`}
      tags={existingTags}
      entityTags={entityTags}
      editSystemTags={includeSystemTags}
      loading={deleteLoading || createLoading}
      onTagRemove={tag => {
        const entityTagsToRemove = entityTags.filter(
          entityTag => entityTag.tag.id === tag.id
        );
        if (entityTagsToRemove.length > 0) {
          entityTagsToRemove.forEach(entityTag => {
            deleteEntityTag({
              variables: { id: entityTag.id }
            });
          });
        }
      }}
      onTagAdd={tag => {
        createEntityTag({
          variables: {
            input: {
              tagId: tag.id,
              entityKind: entityKind as Entity,
              entityId
            }
          }
        });
      }}
    />
  );
}
