import React from "react";
import { Alarm as AlarmIcon } from "@urbaninfrastructure/react-icons";
import { useQuery } from "@apollo/react-hooks";
import {
  activeSystemClosureAlerts as QueryType,
  activeSystemClosureAlertsVariables as QueryVariables,
  onActiveSystemClosureAlerts as SubscriptionType,
  onActiveSystemClosureAlertsVariables as SubscriptionVariables
} from "../../src/core-types";
import { activeSystemClosureAlerts as query } from "../../src/queries";
import { onActiveSystemClosureAlerts as subscription } from "../../src/subscriptions";
import { SystemLink } from "../SystemLink";
import { Anchor } from "../Anchor";
import { IconButton } from "@urbaninfrastructure/react-ui-kit";
import { SystemLinkHref } from "src/hooks/useSystemRouter";

const ButtonLink = ({
  children,
  href
}: {
  href: SystemLinkHref;
  children: React.ReactNode;
}) => (
  <SystemLink href={href}>
    <Anchor>
      <IconButton
        small
        placement="left"
        shape="cool"
        Icon={iconProps => <AlarmIcon {...iconProps} size={20} />}
        style={{ fontWeight: "normal", textTransform: "none" }}
      >
        {children}
      </IconButton>
    </Anchor>
  </SystemLink>
);

const ActiveSystemAlerts = ({
  systemId,
  variant = "button"
}: {
  systemId: string;
  variant?: "button" | "icon-only";
}) => {
  const { data, error, subscribeToMore } = useQuery<QueryType, QueryVariables>(
    query,
    {
      variables: { systemId }
    }
  );

  React.useEffect(() => {
    const unsubscribe = subscribeToMore<
      SubscriptionType,
      SubscriptionVariables
    >({
      document: subscription,
      variables: { systemId },
      updateQuery: (_, { subscriptionData }) => ({
        activeSystemAlerts: subscriptionData.data.activeSystemClosureAlerts
      })
    });

    return () => unsubscribe();
  }, [subscribeToMore, systemId]);

  if (error || !data || !Array.isArray(data.activeSystemAlerts)) {
    return null;
  }

  const count = data.activeSystemAlerts.length;
  if (!count) {
    return null;
  }

  return count === 1 ? (
    <ButtonLink
      href={{
        pathname: "/[systemId]/system-alerts/[systemAlertId]",
        query: { systemAlertId: data.activeSystemAlerts[0].id }
      }}
    >
      {variant === "button" ? "System closure alert" : null}
    </ButtonLink>
  ) : (
    <ButtonLink href="/[systemId]/system-alerts">
      {variant === "button" ? "System closure alerts" : null}
    </ButtonLink>
  );
};

export default React.memo(ActiveSystemAlerts);
