import { Text } from "@urbaninfrastructure/react-ui-kit";
import React from "react";
import { FormattedMessage } from "react-intl";

export function NotSet() {
  return (
    <Text mute>
      <FormattedMessage id="not_set" defaultMessage="Not set" />
    </Text>
  );
}
