import { Mobile } from "@urbaninfrastructure/react-icons";
import { Flex, Text } from "@urbaninfrastructure/react-ui-kit";
import React from "react";
import {
  legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_userAgent,
  trip_trip_userAgent
} from "src/core-types";

export const UserAgent = ({
  userAgent,
  fontSize,
  color
}: {
  userAgent:
    | trip_trip_userAgent
    | legacyUserTimelineQuery_userTimeline_edges_node_entity_Trip_userAgent
    | null;
  fontSize?: string;
  color?: string;
}) => {
  if (!userAgent) {
    return null;
  }

  let displayText: string | null = null;

  switch (true) {
    case userAgent.isAndroid:
      displayText = "Android app";
      break;
    case userAgent.isIOS:
      displayText = "iOS app";
      break;
    case !!userAgent.browser:
      displayText = "Browser";
      break;
    case !userAgent.original:
      displayText = "Location";
      break;
    default:
      displayText = userAgent.original
        ? userAgent.original.charAt(0).toUpperCase() +
          userAgent.original.slice(1, 12)
        : "Unknown";
  }

  return (
    <Flex alignItems="center">
      {(userAgent.isAndroid || userAgent.isIOS) && (
        <Mobile height={12} width={12} mr={1} />
      )}
      <Text fontSize={fontSize} color={color}>
        {displayText}
      </Text>
    </Flex>
  );
};
