import styled, { css } from "styled-components";
import { space, border, SpaceProps, BorderProps } from "styled-system";
import { theme } from "@urbaninfrastructure/react-ui-kit";

export type FieldsetProps = SpaceProps &
  BorderProps & {
    block?: boolean;
  };

const fieldsetCss = ({ theme, block }) => css<FieldsetProps>`
  > legend {
    margin-bottom: ${theme.space[2]}px;
  }
  ${block &&
    css`
      > legend {
        padding: 0 ${theme.space[2]}px;
        margin-left: -${theme.space[2]}px;
        font-size: ${theme.fontSizes[2]}px;
        text-transform: uppercase;
        font-weight: normal;
        color: ${theme.colors.text};
      }
    `};
`;

export const Fieldset = styled.fieldset.attrs<FieldsetProps>(props => ({
  borderRadius: props.block ? "md" : undefined,
  border: props.block ? "1px solid" : "none",
  borderColor: props.block ? "neutral.3" : undefined,
  my: props.block ? 6 : undefined,
  py: props.block ? 4 : undefined,
  px: props.block ? { _: 4, xs: 5 } : undefined
}))<FieldsetProps>`
  ${space};
  ${border};
  ${fieldsetCss};
`;

Fieldset.defaultProps = {
  theme,
  p: 0,
  m: 0,
  mb: 5
};
