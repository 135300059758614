import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "@urbaninfrastructure/react-ui-kit";
import { HorizontalOverflow } from "../HorizontalOverflow";

type Item = {
  id: string;
  name: string;
};

type Props = {
  initialSelected: string;
  inputProps?: Record<string, any>;
  items: Item[];
  itemToString?: (item: Item) => string;
  label?: string;
  onChange: (item: Item) => void;
};

const buttonListCss = () => css`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0;
`;

const buttonListItemCss = () => css`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  button {
    border-left-width: 1px;
    border-radius: 0px;
    text-transform: uppercase;
    font-weight: bold;
    padding: ${({ theme }) => theme.space[2]}px
      ${({ theme }) => theme.space[3]}px;
    white-space: nowrap;
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }
    &:focus {
      z-index: 1;
    }
  }
  &:first-child {
    button {
      border-top-left-radius: ${({ theme }) => theme.radii["md"]};
      border-bottom-left-radius: ${({ theme }) => theme.radii["md"]};
      border-left-width: 2px;
      margin-left: 0;
    }
  }
  &:last-child {
    button {
      border-top-right-radius: ${({ theme }) => theme.radii["md"]};
      border-bottom-right-radius: ${({ theme }) => theme.radii["md"]};
      border-right-color: ${({ theme }) => theme.colors.primary};
      &:hover,
      &:focus {
        border-right-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const buttonItemCss = ({ theme, selected }) => css`
  border-color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[1]};
  ${selected &&
    css`
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:focus {
        color: ${theme.colors.white};
      }
    `};
`;

export const ButtonList = styled.ul`
  ${buttonListCss};
`;

export const ButtonListItem = styled.li`
  ${buttonListItemCss};
`;

export const ButtonItem = styled(Button)`
  ${buttonItemCss};
`;

export function SegmentButton({
  itemToString = (item: Item) => item && item.name,
  onChange,
  initialSelected,
  ...props
}: Props) {
  const [selected, setSelected] = useState<string>(initialSelected);
  const { items } = props;
  return items.length ? (
    <HorizontalOverflow>
      <ButtonList {...props}>
        {items.map(item => {
          const isSelected = selected === item.id;
          return (
            <ButtonListItem key={item.id}>
              <ButtonItem
                key={item.id}
                selected={isSelected}
                aria-pressed={isSelected}
                onClick={() => {
                  onChange(item);
                  setSelected(item.id);
                }}
              >
                {itemToString ? itemToString(item) : String(item)}
              </ButtonItem>
            </ButtonListItem>
          );
        })}
      </ButtonList>
    </HorizontalOverflow>
  ) : null;
}
