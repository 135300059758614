import gql from "graphql-tag";

import * as fragments from "./fragments";

export const onControllerEventLogUpdate = gql`
  ${fragments.controllerEventLog}
  subscription onControllerEventLogUpdate($id: ID, $systemId: ID!) {
    controllerEventLog(controllerId: $id, systemId: $systemId) {
      ...ControllerEventLog
    }
  }
`;

export const onVehicleAvailabilityUpdate = gql`
  ${fragments.vehicleAvailability}
  subscription onVehicleAvailabilityUpdate($id: ID, $systemId: ID!) {
    vehicleAvailability(vehicleId: $id, systemId: $systemId) {
      ...VehicleAvailability
    }
  }
`;

export const onActiveSystemClosureAlerts = gql`
  subscription onActiveSystemClosureAlerts($systemId: ID!) {
    activeSystemClosureAlerts(systemId: $systemId) {
      id
    }
  }
`;
