import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  values?: Record<string, any>;
};

export const I18NEdit = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.edit"
    defaultMessage="Edit"
    description="Generic edit message"
    {...props}
  />
);

export const I18NDelete = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.delete"
    defaultMessage="Delete"
    description="Generic delete message"
    {...props}
  />
);

export const I18NCreate = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.create"
    defaultMessage="Create"
    description="Generic create message"
    {...props}
  />
);

export const I18NNewEntity = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.newEntity"
    defaultMessage="New {entity}"
    description="Generic new entity message"
    {...props}
  />
);

export const I18NUpdate = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.update"
    defaultMessage="Update"
    description="Generic update message"
    {...props}
  />
);

export const I18NSave = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.save"
    defaultMessage="Save"
    description="Generic save message"
    {...props}
  />
);

export const I18NCancel = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.cancel"
    defaultMessage="Cancel"
    description="Generic cancel message"
    {...props}
  />
);

export const I18NReset = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.reset"
    defaultMessage="Reset"
    description="Generic reset message"
    {...props}
  />
);

export const I18NCreatedAt = () => (
  <FormattedMessage
    id="created_at"
    defaultMessage="Created at"
    description="Generic created at message"
  />
);
export const I18NUpdatedAt = () => (
  <FormattedMessage
    id="updated_at"
    defaultMessage="Updated at"
    description="Generic updated at message"
  />
);
