import React from "react";
import { Dialog, Text } from "@urbaninfrastructure/react-ui-kit";
import {
  Cog as CogIcon,
  WarningAlt as WarningAltIcon
} from "@urbaninfrastructure/react-icons";

import MultiSelect, { ItemType } from "../MultiSelect";
import { Info } from "../Info";
import { Tag as TagFragment, EntityTag } from "../../src/core-types";

type Props = {
  editSystemTags?: boolean;
  entityTags: EntityTag[];
  heading: string;
  loading?: boolean;
  onTagAdd: (tag: TagFragment) => void;
  onTagRemove: (tag: TagFragment) => void;
  openButtonText: React.ReactNode;
  tags: TagFragment[];
};

export default function ManageTagsDialog({
  editSystemTags = false,
  entityTags,
  heading,
  loading,
  onTagAdd,
  onTagRemove,
  openButtonText,
  tags
}: Props) {
  const tagToMultiSelectItem = (tag): ItemType => {
    return {
      id: tag.id,
      name: tag.name,
      colour: tag.colour,
      locked: !editSystemTags && Boolean(tag.systemTag),
      type: tag.systemTag ? "system" : undefined
    };
  };

  const [selectedTags, setSelectedTags] = React.useState(
    entityTags.map(entityTag => tagToMultiSelectItem(entityTag.tag))
  );

  return (
    <Dialog
      heading={heading}
      closeOnEsc={false}
      closeOnOutsideClick={false}
      renderOpenButton={openPortal => (
        <Info.Anchor
          as="button"
          onClick={event => {
            // @ts-ignore
            openPortal(event);
          }}
          ArrowIcon={CogIcon}
          small
        >
          {openButtonText}
        </Info.Anchor>
      )}
    >
      <MultiSelect
        label="Tags"
        items={tags.map(tagToMultiSelectItem)}
        values={selectedTags}
        onChange={selectedTags => {
          setSelectedTags(selectedTags);
        }}
        onAdd={item => {
          const tag = tags.find(t => t.id === item.id);
          if (tag) {
            onTagAdd(tag);
          }
        }}
        onRemove={item => {
          const tag = tags.find(t => t.id === item.id);
          if (tag) {
            onTagRemove(tag);
          }
        }}
        loading={loading}
        removeValuesOnBackspace={false}
        position="static"
      />

      {entityTags.some(({ tag }) => tag.systemTag) && (
        <Text as="p" fontSize={1} mt={3} mb={0} mute>
          Tags marked with <WarningAltIcon size="12px" /> are system tags and
          are used by the system.
          <br />
          These tags can affect functionality.
        </Text>
      )}
    </Dialog>
  );
}

ManageTagsDialog.defaultProps = {
  openButtonText: "Edit tags"
};
