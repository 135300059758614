import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const Base = styled.img`
  ${space};
  border-radius: 50%;
  display: block;
`;

const Avatar = ({
  width = 32,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & SpaceProps) => (
  <Base width={width || 32} {...props} />
);

export default Avatar;
