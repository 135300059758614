export default function redirect(url: string, res?: any) {
  if (typeof window === "undefined") {
    if (!res) {
      return;
    }
    res.writeHead(302, {
      Location: url
    });
    res.end();
    res.finished = true;
  } else {
    window.location.href = url;
  }
}
