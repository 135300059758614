import React from "react";
import { SpaceProps } from "styled-system";
import styled, { css } from "styled-components";
import { ApolloError } from "apollo-client";
import {
  Text,
  Alert,
  Flex,
  Box,
  Button
} from "@urbaninfrastructure/react-ui-kit";
import {
  Up as UpIcon,
  More as MoreIcon
} from "@urbaninfrastructure/react-icons";

type Error = ApolloError & {
  uuid?: string;
};

type Props = {
  errors?: Error[];
  error?: Error;
  message: React.ReactNode;
} & SpaceProps;

type State = {
  expandErrors: boolean;
};

const toggleButtonCss = ({ theme }) => css`
  display: block;
  padding: ${theme.space[1]}px;
  border-radius: 50%;
`;

const ToggleButton = styled(Button)`
  ${toggleButtonCss};
`;

const ErrorComponent = ({ children }: { children: React.ReactNode }) => (
  <Text color="neutral.5" fontSize={1} textAlign="left">
    {children}
  </Text>
);

const Pre = styled.pre`
  overflow-y: hidden;
  overflow-x: auto;
`;

class GraphQLNetworkError extends React.Component<Props, State> {
  state = {
    expandErrors: false
  };
  render() {
    const { message, errors, error, ...props } = this.props;
    const { expandErrors } = this.state;
    const Icon = expandErrors ? UpIcon : MoreIcon;
    const label = expandErrors ? "Hide details" : "Show details";
    return (
      <Alert variant="error" mb={6} {...props}>
        <Box width={1}>
          <Flex alignItems={["flex-start", "center"]}>
            <Box
              flex={1}
              mb={[2, 0]}
              mr={[0, 2]}
              width={[1, "auto"]}
              textAlign="left"
            >
              {message}
            </Box>

            <ToggleButton
              type="button"
              onClick={() => this.setState({ expandErrors: !expandErrors })}
              aria-expanded={expandErrors}
              aria-label={label}
              title={label}
            >
              <Icon
                size="12px"
                color="primary"
                style={{ verticalAlign: "top", display: "block" }}
              />
            </ToggleButton>
          </Flex>

          {expandErrors && (
            <Pre>
              {error && (
                <ErrorComponent>
                  <Text>{error.message}</Text>

                  {error.uuid && <Text mute>{error.uuid}</Text>}
                </ErrorComponent>
              )}

              {errors &&
                errors.map((error, i) => {
                  return (
                    <ErrorComponent key={i}>
                      <Text>{error.message}</Text>

                      {error.uuid && <Text mute>{error.uuid}</Text>}
                    </ErrorComponent>
                  );
                })}
            </Pre>
          )}
        </Box>
      </Alert>
    );
  }
}

export default GraphQLNetworkError;
