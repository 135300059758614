import { defineMessages, IntlShape } from "react-intl";
import { memoize, upperFirst } from "lodash";

import { System, SystemType } from "../core-types";

const messages = defineMessages({
  "scootersharing.vehicleNoun": {
    id: "components.translations.scootersharing.vehicleNoun",
    defaultMessage: "scooter"
  },
  "scootersharing.vehicleNounPlural": {
    id: "components.translations.scootersharing.vehicleNounPlural",
    defaultMessage: "scooters"
  },

  "default.vehicleNoun": {
    id: "components.translations.default.vehicleNoun",
    defaultMessage: "bike"
  },
  "default.vehicleNounPlural": {
    id: "components.translations.default.vehicleNounPlural",
    defaultMessage: "bikes"
  }
});

const fallackNouns = {
  vehicleNoun: "vehicle",
  vehicleNounPlural: "vehicles",
  vehicleNounUpperFirst: "Vehicle",
  vehicleNounPluralUpperFirst: "Vehicles"
};

export const getVehicleNouns = memoize(
  (system: System, intl: IntlShape) => {
    if (!system) {
      return fallackNouns;
    }

    let singular = messages["default.vehicleNoun"];
    let plural = messages["default.vehicleNounPlural"];

    switch (system.type) {
      case SystemType.scootersharing: {
        singular = messages["scootersharing.vehicleNoun"];
        plural = messages["scootersharing.vehicleNounPlural"];
        break;
      }
      case SystemType.bikesharing:
      case SystemType.busshelters:
      case SystemType.carsharing: {
        break;
      }
    }

    return {
      vehicleNoun: intl.formatMessage(singular),
      vehicleNounPlural: intl.formatMessage(plural),
      vehicleNounUpperFirst: upperFirst(intl.formatMessage(singular)),
      vehicleNounPluralUpperFirst: upperFirst(intl.formatMessage(plural))
    };
  },

  (system, intl) => `${intl.locale}-${system ? system.type : "noSystem"}`
);
