import React from "react";
import styled, { css } from "styled-components";
import { Label, UIKitTheme as Theme } from "@urbaninfrastructure/react-ui-kit";

type CheckboxIconProps = {
  theme: Theme;
  disabled?: boolean;
};

const labelCss = ({ theme, inline }) => css`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.text};
  display: block;
  input {
    margin-right: ${theme.space[2]}px;
  }
  ${inline &&
    css`
      display: inline-block;
      padding-right: ${theme.space[4]}px;
    `};
`;

export const RadioButtonIcon = styled.span`
  display: inline-block;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral[3]};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: top;
  margin-right: ${({ theme }: CheckboxIconProps) => theme.space[2]}px;
  margin-top: ${({ theme }: CheckboxIconProps) => theme.space[1] / 2}px;
  opacity: ${({ disabled }: CheckboxIconProps) => (disabled ? 0.5 : 1)};
  position: relative;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  opacity: 0;

  &:focus ~ ${RadioButtonIcon} {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:checked ~ ${RadioButtonIcon}:before, &:active ~ ${RadioButtonIcon}:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid ${({ theme }) => theme.colors.primary};
  }

  &:active ~ ${RadioButtonIcon}:before {
    opacity: 0.25;
  }
`;

const BaseLabel = styled(Label)`
  ${labelCss};
`;

type Props = React.HTMLProps<HTMLInputElement> & {
  inline?: boolean;
  labelProps: Record<string, any>;
  children?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
};

const Radio = ({ children, inline, labelProps, ref, as, ...props }: Props) => {
  return (
    <BaseLabel mb={3} {...labelProps}>
      <HiddenCheckbox type="radio" {...props} />
      <RadioButtonIcon />
      {children}
    </BaseLabel>
  );
};

Radio.defaultProps = {
  labelProps: {}
};

export default Radio;
