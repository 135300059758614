// http://redux.js.org/docs/recipes/reducers/ImmutableUpdatePatterns.html#updating-an-item-in-an-array
function update<T = any>(array: T[], index: number, newItem: T): T[] {
  return array.map((item, idx) => {
    if (idx !== index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...newItem
    };
  });
}

function insert<T = any>(arr: T[], index: number, newItem: T): T[] {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ];
}

function remove<T = any>(arr: T[], index: number): T[] {
  return arr.filter((a, idx) => idx !== index);
}

export default {
  update,
  insert,
  remove
};
