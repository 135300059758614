import React from "react";
import {
  Bike,
  Station,
  LockerAlt,
  Profile,
  Plan,
  Cart,
  RfidAlt,
  Receipt,
  Tune,
  Warning,
  Wrench,
  Operate,
  City,
  Dashboard,
  Right,
  Bin,
  More,
  Up,
  Stopwatch,
  InfinitySymbol,
  Location,
  Scooter,
  Link,
  BikeElectric,
  BikeChildSeat,
  Down,
  ArrowRight,
  VirtualStation,
  Add,
  Remove,
  ClockAlt,
  Clock
} from "@urbaninfrastructure/react-icons";
import { SvgIconProps } from "@urbaninfrastructure/react-icons/lib/Svg";
import { SizeProps, SpaceProps } from "styled-system";

export type IconName =
  | "add"
  | "arrowRight"
  | "bike"
  | "electricbike"
  | "childseatbike"
  | "scooter"
  | "station"
  | "lockClose"
  | "user"
  | "plan"
  | "shoppingCart"
  | "signal"
  | "tune"
  | "reportProblem"
  | "receipt"
  | "wrench"
  | "operate"
  | "city"
  | "dashboard"
  | "right"
  | "bin"
  | "more"
  | "up"
  | "stopwatch"
  | "infinitySymbol"
  | "location"
  | "virtualStation"
  | "down"
  | "remove"
  | "clockAlt"
  | "clock"
  | "link";

export default function Icon({
  name,
  ...props
}: {
  name: IconName;
  style?: Record<string, any>;
} & SizeProps &
  SpaceProps &
  SvgIconProps) {
  switch (name) {
    case "add":
      return <Add {...props} />;
    case "remove":
      return <Remove {...props} />;
    case "arrowRight":
      return <ArrowRight {...props} />;
    case "bike":
      return <Bike {...props} />;
    case "electricbike":
      return <BikeElectric {...props} />;
    case "childseatbike":
      return <BikeChildSeat {...props} />;
    case "scooter":
      return <Scooter {...props} />;
    case "station":
      return <Station {...props} />;
    case "lockClose":
      return <LockerAlt {...props} />;
    case "user":
      return <Profile {...props} />;
    case "plan":
      return <Plan {...props} />;
    case "shoppingCart":
      return <Cart {...props} />;
    case "signal":
      return <RfidAlt {...props} />;
    case "tune":
      return <Tune {...props} />;
    case "reportProblem":
      return <Warning {...props} />;
    case "receipt":
      return <Receipt {...props} />;
    case "wrench":
      return <Wrench {...props} />;
    case "operate":
      return <Operate {...props} />;
    case "city":
      return <City {...props} />;
    case "dashboard":
      return <Dashboard {...props} />;
    case "right":
      return <Right {...props} />;
    case "bin":
      return <Bin {...props} />;
    case "more":
      return <More {...props} />;
    case "up":
      return <Up {...props} />;
    case "stopwatch":
      return <Stopwatch {...props} />;
    case "infinitySymbol":
      return <InfinitySymbol {...props} />;
    case "location":
      return <Location {...props} />;
    case "link":
      return <Link {...props} />;
    case "down":
      return <Down {...props} />;
    case "virtualStation":
      return <VirtualStation {...props} />;
    case "clockAlt":
      return <ClockAlt {...props} />;
    case "clock":
      return <Clock {...props} />;
    default:
      return <div />;
  }
}
