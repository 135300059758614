import React from "react";
import { Bin as BinIcon } from "@urbaninfrastructure/react-icons";
import styled from "styled-components";

import { Button } from "@urbaninfrastructure/react-ui-kit";

const StyledTrashButton = styled(Button)`
  padding-left: 8px;
  padding-right: 8px;
  background: transparent;
  line-height: 1;
  margin: 0 10px;
  border: none;
  &:hover svg,
  &:focus svg,
  &:active svg {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export const TrashButton = ({
  handleClick,
  title
}: {
  handleClick: () => void;
  title?: string;
}) => {
  return (
    <StyledTrashButton
      type="button"
      data-testid="DataTableFormRow__DeleteButton"
      onClick={() => {
        handleClick();
      }}
      title={title ? title : "Delete"}
      aria-label={title ? title : "Delete"}
    >
      <BinIcon color="primary" size="24px" />
    </StyledTrashButton>
  );
};
