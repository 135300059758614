import getConfig from "next/config";

import { RouteModel } from "../types";
import {
  AclEntity,
  AvailabilityState,
  ControllerState,
  DockAvailabilityState,
  DockGroupState,
  DockState,
  MainMenuItem,
  OrderStatus,
  PaymentTransactionStatus,
  PowerState,
  RefundState,
  SystemState,
  TripState,
  ValueCodeState,
  VehicleAvailabilityState,
  VehicleState
} from "../core-types";

type UIKitColor = string;

const { publicRuntimeConfig = {} } = getConfig() || {};

export const NODE_ENV: string = publicRuntimeConfig.NODE_ENV || "development";
export const GCLOUD_API_KEY: string =
  publicRuntimeConfig.GCLOUD_API_KEY ||
  "AIzaSyD--diXjAtfzpRDp2WJEwNVykAHgT3PL2I";
export const CORE_ENDPOINT: string = publicRuntimeConfig.CORE_ENDPOINT;
export const SSO_ENDPOINT: string =
  publicRuntimeConfig.SSO_ENDPOINT || CORE_ENDPOINT;
export const BASE_URL: string = publicRuntimeConfig.BASE_URL;
export const VERSION: string = publicRuntimeConfig.VERSION;
export const GITSHA: string = publicRuntimeConfig.GITSHA;
export const GA_TRACKING_ID: string = publicRuntimeConfig.GA_TRACKING_ID;
export const MAPBOX_ACCESS_TOKEN: string =
  publicRuntimeConfig.MAPBOX_ACCESS_TOKEN ||
  "pk.eyJ1IjoidXJiYW5zaGFyaW5nIiwiYSI6ImNqbHc2OXFyazEwcmUzcG9iNWdibGpvd2EifQ.ipgWqJfXb3pwedrC0tWtJg";

export const NETWORK_POLL_INTERVAL = 5000;
export const NETWORK_POLL_INTERVAL_LONG = 15000;

export const VIEW_AS_QUERY_PARAM = "__viewAs";

export const TRANSACTION_STATES: Array<string> = [
  "authorized",
  "declined",
  "failed",
  "completed",
  "pending"
];

export const TRANSACTION_STATES_COLOR_MAP: Record<
  PaymentTransactionStatus,
  UIKitColor
> = {
  failed: "state.error",
  completed: "paletteLight.green01",
  created: "palette.green01",
  pending_authentication: "palette.blue01",
  scheduled: "palette.blue01",
  partially_refunded: "palette.blue01",
  refunded: "palette.blue01",
  cancelled: "state.warning"
};

export const REFUND_STATES_COLOR_MAP: Record<RefundState, UIKitColor> = {
  COMPLETED: "paletteLight.green01",
  FAILED: "palette.red01"
};

export const VALUE_CODE_STATES: ValueCodeState[] = [
  ValueCodeState.unused,
  ValueCodeState.redeemed,
  ValueCodeState.revoked
];

export const VALUE_CODE_STATES_COLOR_MAP: Record<ValueCodeState, UIKitColor> = {
  unused: "neutral.1",
  redeemed: "paletteLight.green01",
  revoked: "state.error"
};

export const ORDER_STATES_COLOR_MAP: Record<OrderStatus, UIKitColor> = {
  pending_payment: "neutral.1",
  payment_failed: "state.error",
  completed: "paletteLight.green01",
  paid: "state.success",
  cancelled: "palette.red01",
  created: "neutral.1",
  partially_refunded: "palette.blue01",
  pending_refund: "palette.blue01",
  refunded: "palette.blue01",
  payment_postponed: "palette.blue01"
};

export const DOCK_GROUP_TYPES = ["physical", "virtual", "hybrid"];

export const DOCK_GROUP_STATES_COLOR_MAP: Record<DockGroupState, UIKitColor> = {
  planning: "neutral.2",
  active: "paletteLight.green01",
  decommissioned: "neutral.4",
  maintenance: "palette.pink01",
  testing: "paletteLight.blue01",
  not_in_service: "state.error"
};

export const DOCK_GROUP_STATES_STROKE_COLOR_MAP: Record<
  DockGroupState,
  UIKitColor
> = {
  planning: "neutral.5",
  active: "palette.green01",
  decommissioned: "neutral.5",
  maintenance: "palette.pink01",
  testing: "palette.blue01",
  not_in_service: "state.error"
};

export const DOCK_STATES_COLOR_MAP: Record<DockState, UIKitColor> = {
  active: "paletteLight.green01",
  maintenance: "palette.pink01"
};

export const DOCK_AVAILABILITY_COLOR_MAP: Record<
  DockAvailabilityState,
  UIKitColor
> = {
  available: "paletteLight.green01",
  vehicle_available: "paletteLight.blue01",
  unavailable: "state.error"
};

export const VEHICLE_STATES_STROKE_COLOR_MAP: Record<
  VehicleState,
  UIKitColor
> = {
  active: "palette.green01",
  broken: "state.error",
  decommissioned: "neutral.2"
};

export const TRIP_STATES: TripState[] = [
  TripState.in_progress,
  TripState.on_hold,
  TripState.completed,
  TripState.cancelled
];

export const TRIP_STATES_COLOR_MAP: Record<TripState, UIKitColor> = {
  starting: "paletteLight.purple01",
  in_progress: "paletteLight.blue01",
  on_hold: "paletteLight.purple01",
  completed: "paletteLight.green01",
  cancelled: "state.error"
};

export const TRIP_FILTERS_KEY = "tripsConnectionFilters";

export const vehicleAvailabilityStates = {
  docked: "docked",
  awaiting_vehicle_unlock: "awaiting_vehicle_unlock",
  removal_cancelled: "removal_cancelled",
  on_hold: "on_hold",
  in_progress: "in_progress",
  unknown: "unknown",
  awaiting_wake_event: "awaiting_wake_event",
  awaiting_vehicle_unlock_on_resume: "awaiting_vehicle_unlock_on_resume",
  awaiting_bolt_lock_armed_to_pause: "awaiting_bolt_lock_armed_to_pause",
  awaiting_bolt_lock_to_pause: "awaiting_bolt_lock_to_pause",
  awaiting_bolt_lock_armed_to_end: "awaiting_bolt_lock_armed_to_end",
  awaiting_bolt_lock_to_end: "awaiting_bolt_lock_to_end",
  cancelling: "cancelling",
  with_administrator: "with_administrator",
  awaiting_bolt_lock_to_end_cancelling: "awaiting_bolt_lock_to_end_cancelling",
  unauthorized_trip: "unauthorized_trip"
};

export const vehicleAvailabilityStatesColorMap: Record<
  VehicleAvailabilityState,
  UIKitColor
> = {
  available: "paletteLight.green01",
  unavailable: "state.error",
  on_trip: "paletteLight.blue01",
  with_administrator: "palette.purple01",
  in_operation_location: "palette.purple01",
  unknown: "state.error",
  never_seen: "neutral.2",
  lost: "palette.red02",
  decommissioned: "neutral.2"
};

export const availabilityStatesColorMap: Record<
  AvailabilityState,
  UIKitColor
> = {
  awaiting_bolt_lock_armed_to_end: "neutral.2",
  awaiting_bolt_lock_armed_to_pause: "neutral.2",
  awaiting_bolt_lock_armed_to_virtual_dock: "neutral.2",
  awaiting_bolt_lock_to_end_cancelling: "neutral.2",
  awaiting_bolt_lock_to_end_jammed: "neutral.2",
  awaiting_bolt_lock_to_end: "neutral.2",
  awaiting_bolt_lock_to_pause_cancelling: "neutral.2",
  awaiting_bolt_lock_to_pause_jammed: "neutral.2",
  awaiting_bolt_lock_to_pause: "neutral.2",
  awaiting_bolt_lock_to_virtual_dock: "neutral.2",
  awaiting_geoposition: "neutral.2",
  awaiting_geoposition_administrator: "neutral.2",
  awaiting_interaction_confirmation: "neutral.2",
  awaiting_iot_unlock_interaction: "neutral.2",
  awaiting_lock_to_end: "neutral.2",
  awaiting_lock_to_pause: "neutral.2",
  awaiting_unlock_interaction: "neutral.2",
  awaiting_vehicle_unlock_jammed: "neutral.2",
  awaiting_vehicle_unlock_on_resume_jammed: "neutral.2",
  awaiting_vehicle_unlock_on_resume: "neutral.2",
  awaiting_vehicle_unlock: "neutral.2",
  awaiting_wake_event: "neutral.2",
  awaiting_wake_event_on_resume: "neutral.2",
  cancelling: "paletteLight.orange01",
  docked: "paletteLight.green01",
  docking_jammed_administrator: "neutral.2",
  docking_jammed_operation_location: "neutral.2",
  docking_jammed_unknown: "neutral.2",
  docking_jammed: "neutral.2",
  in_operation_location: "palette.purple01",
  in_progress: "paletteLight.blue01",
  lost: "neutral.2",
  on_hold: "paletteLight.blue01",
  removed_jammed: "neutral.2",
  unknown: "state.error",
  with_administrator: "palette.purple01",
  decommissioned: "state.error"
};

export const availabilityStatesStrokeColorMap: Record<
  AvailabilityState,
  UIKitColor
> = {
  awaiting_bolt_lock_armed_to_end: "palette.purple01",
  awaiting_bolt_lock_armed_to_pause: "palette.purple01",
  awaiting_bolt_lock_armed_to_virtual_dock: "palette.purple01",
  awaiting_bolt_lock_to_end_cancelling: "palette.purple01",
  awaiting_bolt_lock_to_end_jammed: "state.error",
  awaiting_bolt_lock_to_end: "palette.purple01",
  awaiting_bolt_lock_to_pause_cancelling: "palette.purple01",
  awaiting_bolt_lock_to_pause_jammed: "state.error",
  awaiting_bolt_lock_to_pause: "palette.purple01",
  awaiting_bolt_lock_to_virtual_dock: "palette.purple01",
  awaiting_geoposition: "palette.purple01",
  awaiting_geoposition_administrator: "palette.purple01",
  awaiting_interaction_confirmation: "palette.purple01",
  awaiting_iot_unlock_interaction: "neutral.2",
  awaiting_lock_to_end: "palette.purple01",
  awaiting_lock_to_pause: "palette.purple01",
  awaiting_unlock_interaction: "palette.purple01",
  awaiting_vehicle_unlock_jammed: "state.error",
  awaiting_vehicle_unlock_on_resume_jammed: "state.error",
  awaiting_vehicle_unlock_on_resume: "palette.purple01",
  awaiting_vehicle_unlock: "palette.purple01",
  awaiting_wake_event: "palette.purple01",
  awaiting_wake_event_on_resume: "palette.purple01",
  cancelling: "palette.orange01",
  docked: "palette.green01",
  docking_jammed_administrator: "state.error",
  docking_jammed_operation_location: "state.error",
  docking_jammed_unknown: "state.error",
  docking_jammed: "state.error",
  in_operation_location: "palette.purple01",
  in_progress: "palette.blue01",
  lost: "neutral.2",
  on_hold: "palette.blue01",
  removed_jammed: "state.error",
  unknown: "state.error",
  with_administrator: "palette.purple01",
  decommissioned: "state.error"
};

export const controllerStatesColorMap: Record<ControllerState, UIKitColor> = {
  online: "paletteLight.green01",
  updating_firmware: "paletteLight.blue02",
  unresponsive: "state.error"
};

export const controllerPowerStatesColorMap: Record<PowerState, UIKitColor> = {
  off: "state.error",
  on: "paletteLight.green01",
  sleep: "paletteLight.blue02",
  low_battery_sleep: "paletteLight.blue02",
  snooze: "paletteLight.blue02"
};

// list of global pages used across systems
export const GLOBAL_ROUTES = ["releases", "settings"];

export const NAV_BAR_ROUTES: RouteModel[] = [
  {
    key: MainMenuItem.dashboard,
    route: "/[systemId]",
    isStartPage: true
  },
  {
    key: MainMenuItem.vehicles,
    route: "/[systemId]/vehicles",
    aclEntity: AclEntity.vehicle
  },
  {
    key: MainMenuItem.controllers,
    route: "/[systemId]/controllers",
    aclEntity: AclEntity.controller
  },
  {
    key: MainMenuItem.dockGroups,
    route: "/[systemId]/stations",
    aclEntity: AclEntity.dockGroup
  },
  {
    key: MainMenuItem.docks,
    route: "/[systemId]/docks",
    aclEntity: AclEntity.dock
  },
  {
    key: MainMenuItem.users,
    route: "/[systemId]/users",
    aclEntity: AclEntity.user
  },
  {
    key: MainMenuItem.trips,
    route: "/[systemId]/trips",
    aclEntity: AclEntity.trip
  },
  {
    key: MainMenuItem.sales,
    route: "/[systemId]/sales",
    aclEntity: AclEntity.product
  },
  {
    key: MainMenuItem.valueCodes,
    route: "/[systemId]/value-codes",
    aclEntity: AclEntity.valueCode
  },
  {
    key: MainMenuItem.discounts,
    route: "/[systemId]/discounts",
    aclEntity: AclEntity.discountCampaign
  },
  {
    key: MainMenuItem.rebalancing,
    route: "/[systemId]/rebalancing",
    aclEntity: AclEntity.rebalancing
  },
  {
    key: MainMenuItem.maintenance,
    route: "/[systemId]/maintenance",
    aclEntity: AclEntity.assetMaintenance
  },
  {
    key: MainMenuItem.assembly,
    route: "/[systemId]/assembly",
    aclEntity: [AclEntity.assembly, AclEntity.vehicleControllerAssembly]
  },
  {
    key: MainMenuItem.business,
    route: "/[systemId]/business",
    aclEntity: AclEntity.business
  },
  {
    key: MainMenuItem.systemAlerts,
    route: "/[systemId]/system-alerts",
    aclEntity: AclEntity.systemAlert
  },
  {
    key: MainMenuItem.system,
    route: "/[systemId]/system",
    aclEntity: AclEntity.system
  },
  {
    key: MainMenuItem.team,
    route: "/[systemId]/team",
    aclEntity: [AclEntity.administrator]
  },
  {
    key: MainMenuItem.support,
    route: "/[systemId]/support",
    aclEntity: AclEntity.support
  }
];

export const COUNTRIES = [
  {
    name: "Denmark",
    code: "DK"
  },
  {
    name: "Finland",
    code: "FI",
    timezone: "Europe/Helsinki"
  },
  {
    name: "France",
    code: "FR",
    timezone: "Europe/Paris"
  },
  {
    name: "Germany",
    code: "DE",
    timezone: "Europe/Berlin"
  },
  {
    name: "Italy",
    code: "IT",
    timezone: "Europe/Rome"
  },
  {
    name: "Mexico",
    code: "MX",
    timezone: "America/Mexico_City"
  },
  {
    name: "Netherlands",
    code: "NL",
    timezone: "Europe/Amsterdam"
  },
  {
    name: "Norway",
    code: "NO",
    timezone: "Europe/Oslo"
  },
  {
    name: "Poland",
    code: "PL",
    timezone: "Europe/Warsaw"
  },
  {
    name: "Spain",
    code: "ES",
    timezone: "Europe/Madrid"
  },
  {
    name: "Sweden",
    code: "SE",
    timezone: "Europe/Stockholm"
  },
  {
    name: "United Kingdom",
    code: "GB",
    timezone: "Europe/London"
  },
  {
    name: "United States of America",
    code: "US"
  }
];

export const SYSTEM_COLOR_MAP: Record<SystemState, UIKitColor> = {
  open: "palette.green01",
  closed: "state.error",
  planning: "palette.orange01"
};

export const VEHICLE_INVENTORY_METRICS = {
  vehiclesInService: "vehiclesInService",
  vehiclesOnStreet: "vehiclesOnStreet",
  vehiclesInStation: "vehiclesInStation",
  vehiclesInOperationLocation: "vehiclesInOperationLocation",
  vehiclesInFreeFloatingLocation: "vehiclesInFreeFloatingLocation",
  vehiclesAvailableInFreeFloatingLocation:
    "vehiclesAvailableInFreeFloatingLocation",
  vehiclesUnavailableInFreeFloatingLocation:
    "vehiclesUnavailableInFreeFloatingLocation",
  vehiclesUnavailableInStation: "vehiclesUnavailableInStation",
  vehiclesAvailableInStation: "vehiclesAvailableInStation",
  vehiclesOnTrip: "vehiclesOnTrip",
  vehiclesWithOperations: "vehiclesWithOperations",
  vehiclesAvailabilityUnknown: "vehiclesAvailabilityUnknown",
  vehiclesLost: "vehiclesLost",
  vehiclesDecommissioned: "vehiclesDecommissioned",
  vehiclesNeverSeen: "vehiclesNeverSeen",
  vehiclesOnStreetBatteryOk: "vehiclesOnStreetBatteryOk",
  vehiclesOnStreetControllerBatteryOk: "vehiclesOnStreetControllerBatteryOk",
  vehiclesOnStreetBatteryLow: "vehiclesOnStreetBatteryLow",
  vehiclesOnStreetControllerBatteryLow: "vehiclesOnStreetControllerBatteryLow",
  vehiclesOnStreetBatteryDead: "vehiclesOnStreetBatteryDead",
  vehiclesOnStreetControllerBatteryDead: "vehiclesOnStreetControllerBatteryDead"
};

export const MAX_VOLTAGE = 4.2;
export const MIN_VOLTAGE = 3;

// copied from core: https://github.com/urbaninfrastructure/core/blob/4fc46b20ea97f86a129d553a64ebc87000851405/app/constants.js#L170-L174
export const SYSTEM_TAGS = {
  vehicleNotTrustworthyLocation: "2000000",
  vehicleExceeding: "2000001",
  tripExceeding: "2000002"
};

export const JWT_COOKIE_NAME = "sso_token";

export const PRIVACY_POLICY_URL =
  "https://urbansharing.com/admin-privacy-policy";
