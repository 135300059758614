import styled from "styled-components";

import { Flex, UIKitTheme as Theme } from "@urbaninfrastructure/react-ui-kit";
import theme from "../theme";

type Props = {
  theme: Theme;
};

const TagGroup = styled(Flex)`
  & > * {
    margin: 0 ${({ theme }: Props) => theme.space[1]}px
      ${({ theme }: Props) => theme.space[1]}px 0;
  }
`;

TagGroup.defaultProps = {
  theme,
  justifyContent: "flex-start",
  flexWrap: "wrap",
  inline: true
};

TagGroup.displayName = "TagGroup";

export default TagGroup;
