import styled from "styled-components";
import { Anchor as UIKitAnchor } from "@urbaninfrastructure/react-ui-kit";

export const Anchor = styled(UIKitAnchor)``;

Anchor.defaultProps = {
  color: "text"
};

Anchor.displayName = "Anchor";
