import {
  flowRight,
  startCase,
  toLower,
  capitalize,
  truncate as lodashTrunc
} from "lodash";

// convert to sentence case, eg: "some_string" => "Some string"
export const sentence = (str: string | null | undefined): string =>
  flowRight([capitalize, toLower, startCase])(str);

// Truncate string to specified length (defaults to 25 char)
export const truncate = (string: string, length = 25) =>
  lodashTrunc(string, { length });
