import React from "react";
export type State = {
  total: number;
  seconds: string;
  minutes: string;
  hours: string;
  completed: boolean;
};

type Props = {
  date: Date | string | number;
  intervalDelay: number;
  precision: number;
  render: (state: State) => React.ReactNode;
  now?: () => number;
  onComplete?: () => void;
  inverted?: boolean;
};

const pad = n => (n < 10 ? `0${n}` : `${n}`);

export const getTimeDifference = (
  date: Date | string | number,
  now: () => number = Date.now,
  precision = 0,
  inverted = false
) => {
  const startTimestamp = Number(
    typeof date === "string" ? new Date(date) : date
  );
  const diffDate = inverted ? now() - startTimestamp : startTimestamp - now();
  const total =
    parseInt(
      (Math.max(0, diffDate) / 1000).toFixed(
        Math.max(0, Math.min(20, precision))
      ),
      10
    ) * 1000;

  const seconds = total / 1000;

  return {
    total,
    hours: pad(Math.floor((seconds / 60 / 60) % 60)),
    minutes: pad(Math.floor((seconds / 60) % 60)),
    seconds: pad(Math.floor(seconds % 60)),
    completed: total <= 0
  };
};

export default class Countdown extends React.Component<Props, State> {
  static defaultProps = {
    intervalDelay: 1000,
    precision: 0
  };

  state = getTimeDifference(
    this.props.date,
    this.props.now,
    this.props.precision,
    this.props.inverted
  );

  interval: number | undefined;

  componentDidMount() {
    this.interval = window.setInterval(this.tick, this.props.intervalDelay);
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    window.clearInterval(this.interval);
    delete this.interval;
  }

  tick = () => {
    const { date, now, precision, onComplete, inverted } = this.props;
    const delta = getTimeDifference(date, now, precision, inverted);
    if (delta.completed && onComplete) {
      onComplete();
      this.clearInterval();
    }
    this.setState(delta);
  };

  render() {
    return this.props.render(this.state);
  }
}
