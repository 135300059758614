import styled from "styled-components";
import { Text } from "@urbaninfrastructure/react-ui-kit";

export const FormDescription = styled(Text)``;

FormDescription.defaultProps = {
  small: true,
  mt: 2,
  mb: 1
};
