import React from "react";
import { groupBy } from "lodash";
import { Select, SelectProps } from "@urbaninfrastructure/react-ui-kit";
import { AssetType } from "../../src/core-types";

const AssetModelSelect = ({
  assetModels,
  ...props
}: {
  value: string | undefined;
  assetModels:
    | {
        id: string;
        type: AssetType;
        name: string | null;
      }[]
    | null;
} & SelectProps) => {
  // group assetModels by type
  const grouped = assetModels
    ? groupBy(assetModels, assetModel => assetModel && assetModel.type)
    : {};

  return (
    <Select {...props}>
      {!props.value && <option value="" />}

      {Object.keys(grouped).map(key => {
        const models = grouped[key];
        return (
          <optgroup key={key} label={key}>
            {models.map(model => {
              if (!model) {
                return null;
              }

              return (
                <option key={model.id} value={model.id}>
                  {model.name || model.id}
                </option>
              );
            })}
          </optgroup>
        );
      })}
    </Select>
  );
};

export default AssetModelSelect;
