import React from "react";
import { Rifm } from "rifm";
import { Input } from "@urbaninfrastructure/react-ui-kit";

export const currencyFormat = (str: string): string => {
  const clean = str.replace(/[^\d.]+/gi, "");

  const beautify =
    clean.length > 2
      ? clean.indexOf(".") === -1
        ? clean
        : `${clean.split(".")[0]}.${clean.split(".")[1].substr(0, 2)}`
      : clean;

  const r = parseFloat(beautify);

  return !isNaN(r)
    ? r.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : "";
};

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> & {
  value: number | undefined | null;
  onChange: (string) => void;
};

type State = {
  internalValue: string;
};

function valueToInternalValue(value: number | null | undefined): string {
  if (value !== null && value !== undefined) {
    return `${value}`;
  }
  return "";
}

class CurrencyInput extends React.Component<Props, State> {
  static defaultProps = {
    value: null
  };
  state = {
    internalValue: currencyFormat(valueToInternalValue(this.props.value))
  };
  render() {
    const { onChange, ...props } = this.props;
    const { internalValue } = this.state;
    return (
      <Rifm
        // refuse={/[^d.]/gi} // refuse doesn't seem to work? even tho it's documented here https://istarkov.github.io/rifm/docs-readme#input-number-format-with-fractional-part
        value={internalValue}
        onChange={value => {
          this.setState({ internalValue: value });
          onChange(value);
        }}
        format={currencyFormat}
      >
        {rifmProps => {
          return <Input type="tel" {...props} {...rifmProps} />;
        }}
      </Rifm>
    );
  }
}

export default CurrencyInput;
