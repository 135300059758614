import React from "react";
import { Info } from "../Info";
import { SystemLink } from "../SystemLink";
import { IconName } from "../Icon";
import useSystem from "../../src/hooks/useSystem";
import {
  vehicle_vehicle_displayName,
  VehicleCategory,
  SystemType
} from "../../src/core-types";

export const VehicleDisplayTitle = ({
  displayName
}: {
  displayName: vehicle_vehicle_displayName;
}): string => {
  switch (displayName.__typename) {
    case "VehicleDisplayNamed":
      return `${displayName.number} ${displayName.name}`;

    case "VehicleDisplayNumbered":
      return `${displayName.number}`;

    case "VehicleDisplayAnonymous":
      return `${displayName.id}`;
  }
};

const VehicleDisplay = ({
  vehicleId,
  displayName,
  category,
  linkParams
}: {
  vehicleId: string;
  displayName: vehicle_vehicle_displayName;
  category?: VehicleCategory | null | undefined;
  linkParams?: Record<string, any>;
}) => {
  const system = useSystem();

  let icon: IconName = "bike";
  if (category) {
    switch (category) {
      case "bike":
        icon = "bike";
        break;

      case "ebike":
        icon = "electricbike";
        break;

      case "ebike_with_childseat":
        icon = "childseatbike";
        break;

      case "scooter":
        icon = "scooter";
        break;

      default:
        switch (system.type) {
          case SystemType.bikesharing:
          case SystemType.busshelters:
          case SystemType.carsharing: {
            break;
          }
          case SystemType.scootersharing: {
            icon = "scooter";
            break;
          }
        }
        break;
    }
  }

  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/vehicles/[vehicleId]",
        query: { vehicleId, ...linkParams }
      }}
    >
      <Info.Anchor
        icon={icon}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {VehicleDisplayTitle({ displayName })}
      </Info.Anchor>
    </SystemLink>
  );
};

export default VehicleDisplay;
