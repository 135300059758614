import React from "react";
import uniqueId from "lodash/uniqueId";
import { CoordInput } from "../core-types";

type GlobalState = {
  notifications: Notification[];
  user: {
    id: string;
  };
  dockGroup: {
    mapZoom?: number;
    mapCenter?: CoordInput;
  };
};

type Action =
  | { type: "NOTIFICATION/ADD"; payload: Notification }
  | { type: "NOTIFICATION/REMOVE"; payload: string }
  | { type: "NOTIFICATION/CLEAR" }
  | { type: "MODULES/DOCKGROUPS/SET_MAP_ZOOM"; payload: number }
  | { type: "MODULES/DOCKGROUPS/SET_MAP_CENTER"; payload: CoordInput };

export type Dispatch = (action: Action) => void;

export const initialState: GlobalState = {
  notifications: [],
  user: {
    id: ""
  },
  dockGroup: {
    mapZoom: undefined,
    mapCenter: undefined
  }
};

export const GlobalStateContext = React.createContext<[GlobalState, Dispatch]>([
  initialState,
  () => undefined
]);

function reducer(state: GlobalState, action: Action) {
  // NOTIFICATIONS
  switch (action.type) {
    case "NOTIFICATION/ADD": {
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    }
    case "NOTIFICATION/REMOVE": {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload
        )
      };
    }
    case "NOTIFICATION/CLEAR":
      return { ...state, notifications: [] };

    // DOCK GROUP MODULE
    case "MODULES/DOCKGROUPS/SET_MAP_ZOOM":
      return {
        ...state,
        dockGroup: { ...state.dockGroup, mapZoom: action.payload }
      };
    case "MODULES/DOCKGROUPS/SET_MAP_CENTER":
      return {
        ...state,
        dockGroup: { ...state.dockGroup, mapCenter: action.payload }
      };
  }

  return state;
}

export function useGlobalState() {
  return React.useContext(GlobalStateContext);
}

export function GlobalStateProvider({
  children,
  initialState: initState
}: {
  children: React.ReactNode;
  initialState?: GlobalState;
}) {
  const [state, dispatch] = React.useReducer(
    reducer,
    initState || initialState
  );
  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
}

// TYPES
export type NotificationLevel = "success" | "error" | "warning" | "info";

export type NotificationId = string;

export type Notification = {
  id: NotificationId;
  message: React.ReactNode;
  level: NotificationLevel;
  removeOnRouteChange?: boolean;
  body?: React.ReactNode;
  minimal?: boolean;
};

export type NotificationMeta = {
  level?: NotificationLevel;
  ttl?: number;
  removeOnRouteChange?: boolean;
};

// ACTIONS
const ID_PREFIX = "notification_";
const DEFAULT_LEVEL: NotificationLevel = "info";
const DEFAULT_TTL = 5000;

export function addNotification(
  id: NotificationId,
  message: React.ReactNode,
  level: NotificationLevel = DEFAULT_LEVEL,
  body?: React.ReactNode,
  removeOnRouteChange?: boolean,
  minimal?: boolean
): Action {
  const payload: Notification = {
    id,
    message,
    level,
    body,
    removeOnRouteChange,
    minimal
  };
  return {
    type: "NOTIFICATION/ADD",
    payload
  };
}

export function removeNotification(id: NotificationId): Action {
  return {
    type: "NOTIFICATION/REMOVE",
    payload: id
  };
}

export function clearNotifications(): Action {
  return {
    type: "NOTIFICATION/CLEAR"
  };
}

export function addNotificationWithTTL(
  message: React.ReactNode,
  meta: NotificationMeta = {},
  body?: React.ReactNode
) {
  return (dispatch: Dispatch) => {
    const id = uniqueId(ID_PREFIX);

    setTimeout(() => {
      dispatch(removeNotification(id));
    }, meta.ttl || DEFAULT_TTL);

    dispatch(
      addNotification(id, message, meta.level, body, meta.removeOnRouteChange)
    );
  };
}
