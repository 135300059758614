import React from "react";
import styled, { css } from "styled-components";
import { Box, Flex, BoxProps } from "@urbaninfrastructure/react-ui-kit";
import { motion } from "framer-motion";

type Props = {
  id?: string;
  disabled?: boolean;
  isOn?: boolean;
  labelOff?: string;
  labelOn?: string;
  onChange: (checked: boolean) => void;
} & BoxProps;

const WrapLabel = styled(motion.label)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: block;
`;

const Checkbox = styled.input`
  clip: rect(0 0 0 0);
  height: 0;
  position: absolute;
  width: 0;
`;

const cssLabelOff = ({ active, theme }) => css<{ active: boolean }>`
  color: ${active ? theme.colors.text : theme.colors.neutral[4]};
  margin-right: ${theme.space[2]}px;
`;

const LabelOff = styled(Box)<{ active: boolean }>`
  ${cssLabelOff}

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const cssLabelOn = ({ active, theme }) => css<{ active: boolean }>`
  color: ${active ? theme.colors.text : theme.colors.neutral[4]};
  margin-left: ${theme.space[2]}px;
`;

const LabelOn = styled(Box)<{ active: boolean }>`
  ${cssLabelOn}

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const cssToggleOuter = ({ isOn, theme }) => css`
  border-radius: 1rem;
  border: solid 1px ${theme.colors.neutral[4]};
  height: 26px;
  position: relative;
  width: 45px;

  input:focus + div & {
    outline: none;
    border-color: ${isOn ? theme.colors.primary : theme.colors.neutral[5]};
  }
`;

const ToggleOuter = styled.div`
  ${cssToggleOuter};
`;

const cssToggleInner = ({ isOn, theme }) => css`
  background-color: ${isOn ? theme.colors.primary : theme.colors.neutral[4]};
  border-radius: 999px;
  height: 20px;
  position: absolute;
  top: 2px;
  width: 20px;
`;

const ToggleInner = styled(motion.div)`
  ${cssToggleInner};
`;

const ToggleSwitch = ({
  id,
  isOn = false,
  disabled = false,
  labelOff = "Off",
  onChange,
  labelOn = "On",
  color,
  ...props
}: Props) => (
  <WrapLabel animate={isOn ? "on" : "off"} initial={false} disabled={disabled}>
    <Box color={color as any} {...props}>
      <Checkbox
        id={id}
        type="checkbox"
        checked={isOn ? true : false}
        disabled={disabled}
        onChange={event => {
          onChange(event.target.checked);
        }}
      />

      <Flex alignItems="center">
        <LabelOff active={!isOn}>{labelOff}</LabelOff>

        <ToggleOuter isOn={isOn}>
          <ToggleInner
            isOn={isOn}
            transition={{ duration: 0.15, ease: "easeOut" }}
            variants={{
              on: {
                x: 20
              },
              off: {
                x: 3
              }
            }}
          />
        </ToggleOuter>

        <LabelOn active={isOn}>{labelOn}</LabelOn>
      </Flex>
    </Box>
  </WrapLabel>
);

export default ToggleSwitch;
