import React from "react";
import styled from "styled-components";
import { InfoAlt } from "@urbaninfrastructure/react-icons";
import { Text } from "@urbaninfrastructure/react-ui-kit";

import Popover from "../Popover";

type Props = {
  display?: string;
  iconColor?: string;
  iconSize?: string;
  textStyles?: Record<string, any>;
  tooltip: React.ReactNode;
} & React.HTMLAttributes<HTMLButtonElement>;

const TooltipButton = styled(Text.withComponent("button"))`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  font-smoothing: inherit;

  appearance: none;
`;

const TooltipTrigger = styled(TooltipButton)`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  padding: 0.5rem;
  margin: -0.5rem;
  margin-left: 0;
  &:hover svg {
    color: black;
  }
`;

const HelpTooltip: React.FC<Props> = ({
  tooltip,
  children,
  iconSize,
  textStyles,
  iconColor,
  ...props
}) => {
  return (
    <Popover
      trigger="hover"
      placement="auto"
      small
      renderToggler={renderTogglerProps => {
        if (children) {
          return (
            <TooltipButton
              {...renderTogglerProps}
              textAlign="left"
              css={{ cursor: "help" }}
              {...props}
            >
              {children}
            </TooltipButton>
          );
        }
        return (
          <TooltipTrigger {...renderTogglerProps} {...props}>
            <InfoAlt
              size={iconSize || "16px"}
              color={iconColor || "neutral.4"}
            />
          </TooltipTrigger>
        );
      }}
    >
      {() => {
        return (
          <Text
            small
            style={{ maxWidth: "200px", whiteSpace: "normal", ...textStyles }}
          >
            {tooltip}
          </Text>
        );
      }}
    </Popover>
  );
};

export default HelpTooltip;
