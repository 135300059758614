import React from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps, border, BorderProps } from "styled-system";
import { HorizontalOverflow } from "../HorizontalOverflow";
import { EnhancedTabProps } from "./types";

const baseTabItemCss = ({ theme, large, isSelected }) => css`
  position: relative;
  display: inline-block;
  padding: ${theme.space[2]}px ${theme.space[4]}px;

  color: ${theme.colors.text};
  font-size: ${theme.fontSizes[0]}px;
  text-decoration: none;
  border-bottom: 1px solid ${theme.colors.neutral[2]};
  cursor: pointer;
  font-weight: normal;

  transition: all 100ms ease;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: ${theme.colors.text};
    border-bottom-color: ${theme.colors.neutral[5]};
  }

  ${large &&
    css`
      font-size: ${theme.fontSizes[1]}px;
      padding: ${theme.space[3]}px ${theme.space[4]}px;
      ${theme.mediaQueries[0]} {
        padding: ${theme.space[3]}px ${theme.space[5]}px;
      }
    `} ${isSelected &&
    css`
      color: ${theme.colors.text};
      pointer-events: none;
      border-bottom-color: ${theme.colors.text};

      &:hover,
      &:focus {
        border-bottom-color: ${theme.colors.text};
      }
    `};
`;

export const NavItem = styled.li`
  ${baseTabItemCss};
`;
export const NavItemAnchor = styled.a`
  ${baseTabItemCss};
`;

interface NavProps extends SpaceProps, BorderProps {
  large?: boolean;
}
export const Nav = styled.ul.attrs<NavProps>(props => {
  if (props.large) {
    return { borderBottom: "1px solid", borderBottomColor: "neutral.2" };
  }
})<NavProps>`
  display: flex;
  position: relative;
  z-index: 2;
  list-style: none;
  padding: 0;
  margin: 0;
  width: auto;
  ${space};
  ${border};
`;

type TabsProp = Array<EnhancedTabProps>;

type Props = {
  /** Render large tabs. */
  large?: boolean;
  /** Handler for navigation using the keyboard buttons. */
  onKeyboardNav: (key: string) => void;
  /** The tabs to display; with content being hidden unless the tab is selected. */
  tabs?: TabsProp;
};

type State = {
  wasKeyboardNav: boolean;
};

export default class TabsNav extends React.Component<Props, State> {
  state = { wasKeyboardNav: false };
  tabs: Array<{
    el: HTMLLIElement | null;
    isSelected: boolean;
  }> = [];

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // Don't re-render when we are resetting the `wasKeyboardNav` state
    if (
      nextState.wasKeyboardNav !== this.state.wasKeyboardNav &&
      !nextState.wasKeyboardNav
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    // Focus the selected tab if it was selected via keyboard nav
    this.tabs.forEach(tab => {
      if (tab.el) {
        if (this.state.wasKeyboardNav && tab.isSelected) {
          tab.el.focus();
          this.setState({ wasKeyboardNav: false });
        } else {
          // Ensure that focus does not stay on an unselected tab
          tab.el.blur();
        }
      }
    });
  }

  tabKeyDownHandler = (e: React.KeyboardEvent<HTMLLIElement>) => {
    this.setState({ wasKeyboardNav: true });
    this.props.onKeyboardNav(e.key);
  };

  tabMouseDownHandler = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
    e.preventDefault();

  render() {
    const { tabs, large } = this.props;

    return (
      <HorizontalOverflow mb={5}>
        <Nav data-testid="TabsNav" role="tablist">
          {tabs
            ? tabs.map((tab, index) => (
                <NavItem
                  large={large}
                  aria-selected={tab.isSelected}
                  ref={ref => {
                    this.tabs.push({
                      el: ref,
                      isSelected: tab.isSelected
                    });
                  }}
                  isSelected={tab.isSelected} // used in testing
                  key={index}
                  onClick={tab.onSelect}
                  onKeyDown={this.tabKeyDownHandler}
                  onMouseDown={this.tabMouseDownHandler}
                  role="tab"
                  tabIndex={tab.isSelected ? 0 : -1}
                >
                  {tab.label}
                </NavItem>
              ))
            : null}
        </Nav>
      </HorizontalOverflow>
    );
  }
}
