import React from "react";
import styled from "styled-components";
import { color } from "styled-system";
import { readableColor } from "polished";
import {
  BoxProps,
  Box,
  Button,
  Flex,
  Text,
  UIKitTheme as Theme
} from "@urbaninfrastructure/react-ui-kit";
import {
  RemoveAlt as RemoveAltIcon,
  Lock as LockIcon,
  WarningAlt as WarningAltIcon
} from "@urbaninfrastructure/react-icons";

import theme from "../theme";

type Props = React.PropsWithChildren<
  {
    disableRemove?: boolean;
    href?: string;
    large?: boolean;
    onRemoveClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
    type?: "locked" | "system";
  } & BoxProps &
    React.HTMLAttributes<HTMLElement>
>;

const Base = styled(Box)`
  white-space: nowrap;
  user-select: none;
`;

Base.displayName = "BaseTag";

const defaultBg = "neutral.2";

function styledReadableColor(props: {
  readableColorBg?: BoxProps["bg"];
  theme: Theme;
}) {
  const c = color({ theme: props.theme, color: props.readableColorBg });
  try {
    return readableColor(c.color);
  } catch {
    return;
  }
}

const TagText = styled(Text)`
  text-decoration: none;
  display: block;
`;

const Tag = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onRemoveClick,
      children,
      large = false,
      disableRemove,
      href,
      type,
      bg = defaultBg,
      ...props
    },
    ref
  ) => {
    let component;

    if (type === "system" || type === "locked") {
      bg = defaultBg;
    }

    const color = React.useMemo(
      () => styledReadableColor({ theme, readableColorBg: bg }),
      [bg]
    );

    const componentProps = {
      px: 2,
      readableColorBg: bg,
      py: large ? 1 : 0,
      fontSize: large ? "xs" : "xxs",
      color,
      theme
    };

    const icons: React.ReactElement[] = [];
    const iconProps = { size: "12px" };
    const hasRemoveIcon = onRemoveClick && type !== "locked";
    const typeIconMargin = hasRemoveIcon ? 1 : undefined;

    if (type === "locked") {
      icons.push(<LockIcon {...iconProps} key="locked" mr={typeIconMargin} />);
    } else if (type === "system") {
      icons.push(
        <WarningAltIcon {...iconProps} key="warning" mr={typeIconMargin} />
      );
    }
    if (href) {
      component = (
        <TagText as="a" {...componentProps} href={href}>
          <span>{children}</span> {icons}
        </TagText>
      );
    } else {
      component = (
        <TagText {...componentProps}>
          <span>{children}</span> {icons}
        </TagText>
      );
    }

    return (
      <Base
        ref={ref}
        display="inline-block"
        borderRadius="5px"
        fontSize={large ? "xs" : "xxs"}
        lineHeight={2}
        theme={theme}
        bg={bg}
        {...props}
      >
        <Flex>
          {component}
          {hasRemoveIcon && (
            <Button
              variant="reset"
              px={2}
              py={0}
              disabled={disableRemove}
              color={disableRemove ? "neutral.1" : undefined}
              css={`
                &:hover {
                  background-color: ${theme.colors.neutral[3]};
                }
              `}
              onClick={event => {
                onRemoveClick && onRemoveClick(event);
              }}
              aria-label={`Remove ${
                typeof children === "string" ? children : "item"
              }`}
            >
              <RemoveAltIcon {...iconProps} key="remove" />
            </Button>
          )}
        </Flex>
      </Base>
    );
  }
);
Tag.displayName = "Tag";

export default Tag;
