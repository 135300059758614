import React from "react";
import { Select } from "@urbaninfrastructure/react-ui-kit";
import { COUNTRIES } from "../../src/constants";

type FieldKey = "countryName" | "countryCode" | "timezone";

type Props = {
  setFieldValue: (key: FieldKey, value: string) => void;
  value: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const CountrySelector = ({ setFieldValue, value, ...props }: Props) => {
  return (
    <Select
      {...props}
      value={value}
      onChange={event => {
        const name = event.target.value;
        setFieldValue("countryName" as FieldKey, name);
        const country = COUNTRIES.find(c => c.name === name);
        if (country) {
          if (country.code) {
            setFieldValue("countryCode" as FieldKey, country.code);
          }
          if (country.timezone) {
            setFieldValue("timezone" as FieldKey, country.timezone);
          }
        }
      }}
    >
      <option key="0" hidden value="">
        Choose country
      </option>

      {COUNTRIES.map(country => (
        <option key={country.name} value={country.name}>
          {country.name}
        </option>
      ))}
    </Select>
  );
};
