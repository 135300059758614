import React, { FC } from "react";
import styled, { css } from "styled-components";
import {
  Arrow,
  Box,
  Flex,
  Table as UIKitTable,
  Td as UIKitTd,
  Th as UIKitTh,
  Caption,
  CellProps
} from "@urbaninfrastructure/react-ui-kit";
import { Tr } from "./Tr";

import { SortDirection } from "../Sortable/types";

const Table = (props: any) => <UIKitTable mb={4} {...props} />;

const Td = styled(UIKitTd)`
  ${({ overflowText }: { overflowText?: boolean }) =>
    overflowText &&
    css`
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 1px;
    `};
`;

const Th = (props: any) => {
  return <UIKitTh fontSize={1} {...props} />;
};

const SortStyledTh = styled(Th)`
  ${Arrow} {
    opacity: 0;
    transition: opacity 50ms ease-out;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    box-shadow: none;
    ${Arrow} {
      opacity: 0.4;
    }
  }
  ${({ sortDirection }) =>
    sortDirection &&
    css`
      ${Arrow} {
        opacity: 1;
      }
    `};
`;

const SortArrow = ({
  sortDirection
}: {
  sortDirection: SortDirection | null | undefined;
}) => (
  <Arrow
    color="primary"
    aria-label={
      sortDirection === "asc"
        ? "(sorted ascending)"
        : sortDirection === "desc"
        ? "(sorted descending)"
        : "(sort)"
    }
    direction={sortDirection === "asc" ? "up" : "down"}
    display="inline-block"
    ml={2}
    size="10px"
  />
);

const SortableTh: FC<CellProps & {
  scope?: string;
  sortDirection: SortDirection | undefined;
  actions?: React.ReactNode;
}> = ({ children, sortDirection, actions, ...props }) => {
  return (
    <SortStyledTh sortDirection={sortDirection} {...props}>
      <Flex alignItems="center">
        <Box>{children}</Box>
        <SortArrow sortDirection={sortDirection} />
        {actions && <Box ml={2}>{actions}</Box>}
      </Flex>
    </SortStyledTh>
  );
};

Table.Caption = Caption;
Table.SortableTh = SortableTh;
Table.Td = Td;
Table.Th = Th;
Table.Tr = Tr;

export default Table;
