import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "@urbaninfrastructure/react-ui-kit";
import { Manager, Reference, Popper, PopperProps } from "react-popper";
import { Properties as StyleProps } from "csstype";

interface PopperBoxProps
  extends Omit<BoxProps, "color">,
    Pick<PopperProps, "placement" | "modifiers"> {}

export type Props = {
  children: (props: { close: () => void }) => React.ReactNode;
  closeOnOutsideClick: boolean;
  initialIsOpen: boolean;
  isOpen?: boolean;
  renderToggler: (props: {
    ref: (instance: HTMLButtonElement | null) => void;
    onClick: () => void;
    onMouseLeave: () => void;
    onMouseEnter: () => void;
  }) => React.ReactNode;
  showArrow: boolean;
  small?: boolean;
  trigger: "click" | "hover";
} & PopperBoxProps;

const arrowSize = 15; // px
const arrowColor = "white";

const PopperBox = styled(Box).attrs<PopperBoxProps>(({ placement }) => {
  if (!placement) {
    return {};
  }
  const margin = arrowSize / 2;
  const style: StyleProps = {};
  if (placement.startsWith("right")) {
    style.marginLeft = `${margin}px`;
    if (placement === "right-end") {
      style.borderTopLeftRadius = 0;
    }
  }
  if (placement.startsWith("bottom")) {
    style.marginTop = `${margin}px`;
  }
  if (placement.startsWith("top")) {
    style.marginBottom = `${margin}px`;
  }
  if (placement.startsWith("left")) {
    style.marginRight = `${margin}px`;
    if (placement === "left-end") {
      style.borderBottomRightRadius = 0;
    }
  }
  return style;
})<PopperBoxProps>`
  z-index: 10;
`;

PopperBox.defaultProps = {
  bg: "white",
  boxShadow: "heavy",
  borderRadius: "md"
};

const Arrow = styled(Box)`
  position: absolute;
  width: ${arrowSize}px;
  height: ${arrowSize}px;
  &[data-placement*="bottom"] {
    bottom: 100%;
    left: 0;
    height: ${arrowSize / 2}px;
    &::before {
      border-width: 0 ${arrowSize / 2}px ${arrowSize / 2}px ${arrowSize / 2}px;
      border-color: transparent transparent ${arrowColor} transparent;
    }
  }
  &[data-placement*="top"] {
    top: 100%;
    left: 0;
    height: ${arrowSize / 2}px;
    &::before {
      border-width: ${arrowSize / 2}px ${arrowSize / 2}px 0 ${arrowSize / 2}px;
      border-color: ${arrowColor} transparent transparent transparent;
    }
  }
  &[data-placement*="right"] {
    right: 100%;
    width: ${arrowSize / 2}px;
    &::before {
      border-width: ${arrowSize / 2}px ${arrowSize / 2}px ${arrowSize / 2}px 0;
      border-color: transparent ${arrowColor} transparent transparent;
    }
  }
  &[data-placement*="left"] {
    left: 100%;
    width: ${arrowSize / 2}px;
    &::before {
      border-width: ${arrowSize / 2}px 0 ${arrowSize / 2}px ${arrowSize / 2}px;
      border-color: transparent transparent transparent ${arrowColor};
    }
  }
  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

export default function Popover({
  placement,
  initialIsOpen,
  renderToggler,
  closeOnOutsideClick,
  children,
  isOpen: controlledIsOpen,
  showArrow,
  trigger,
  modifiers,
  small,
  ...props
}: Props) {
  const togglerRef = React.useRef<HTMLButtonElement | null>();
  const popperRef = React.useRef<HTMLButtonElement | null>();
  const [_isOpen, setIsOpen] = React.useState(initialIsOpen);

  let isOpen = controlledIsOpen;

  if (isOpen === null || isOpen === undefined) {
    isOpen = _isOpen;
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef &&
      popperRef.current !== undefined &&
      popperRef.current !== null &&
      !popperRef.current.contains(event.target as Node) &&
      togglerRef &&
      togglerRef.current &&
      !togglerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (closeOnOutsideClick) {
      document.addEventListener("click", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [closeOnOutsideClick]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          return renderToggler({
            ref: _ref => {
              popperRef.current = _ref;
              // @ts-ignore because of https://github.com/popperjs/react-popper/blob/v1.3.6/typings/react-popper.d.ts#L13
              ref(_ref);
            },
            onClick: () => {
              if (trigger === "click") {
                setIsOpen(!isOpen);
              }
            },
            onMouseEnter: () => {
              if (trigger === "hover") {
                setIsOpen(true);
              }
            },
            onMouseLeave: () => {
              if (trigger === "hover") {
                setIsOpen(false);
              }
            }
          });
        }}
      </Reference>

      {isOpen ? (
        <Popper placement={placement} modifiers={modifiers}>
          {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
            scheduleUpdate();
            return (
              <PopperBox
                ref={_ref => {
                  // @ts-ignore because of https://github.com/popperjs/react-popper/blob/v1.3.6/typings/react-popper.d.ts#L13
                  togglerRef.current = _ref;
                  // @ts-ignore because of https://github.com/popperjs/react-popper/blob/v1.3.6/typings/react-popper.d.ts#L13
                  ref(_ref);
                }}
                style={style}
                data-placement={placement}
                placement={placement}
                p={small ? 2 : 3}
                {...props}
              >
                {children({ close: () => setIsOpen(false) })}
                {showArrow && (
                  <Arrow
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                    data-testid="Popover__Arrow"
                    data-placement={placement}
                  />
                )}
              </PopperBox>
            );
          }}
        </Popper>
      ) : null}
    </Manager>
  );
}

Popover.defaultProps = {
  placement: "auto-end",
  initialIsOpen: false,
  closeOnOutsideClick: false,
  showArrow: true,
  trigger: "click",
  modifiers: {
    flip: { enabled: true }
  }
};
