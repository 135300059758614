import React from "react";
import styled, { keyframes } from "styled-components";
import { Flex, Text, TextProps } from "@urbaninfrastructure/react-ui-kit";
import { Dot } from "../Dot";

const pulseAnimation = keyframes`
  0%   { opacity: 1 }
  50%  { opacity: 0 }
  100% { opacity: 1 }
`;

const PulsingDot = styled(Dot)`
  animation: ${pulseAnimation} 1s infinite linear;
`;

const Loading = ({
  children,
  large,
  color,
  as,
  size = "medium",
  ...props
}: {
  size?: "small" | "medium" | "large";
  /** @deprecated use size=large */
  large?: boolean;
  children?: React.ReactNode;
} & TextProps) => {
  let fontSize: number;
  switch (size) {
    case "small": {
      fontSize = 0;
      break;
    }
    case "medium": {
      fontSize = 1;
      break;
    }
    case "large": {
      fontSize = 2;
      break;
    }
  }
  return (
    <Text
      mb={large ? 4 : 0}
      my={large ? 7 : 0}
      py={large ? 7 : 0}
      fontSize={fontSize}
      mute
      color={color as any}
      as={as as any}
      {...props}
    >
      {children && (
        <Flex justifyContent={large ? "center" : undefined}>
          <PulsingDot>{children}</PulsingDot>
        </Flex>
      )}
      {!children && <PulsingDot>{children}</PulsingDot>}
    </Text>
  );
};

export default Loading;
