import { useContext, useCallback } from "react";

import { AdministratorContext } from "../context/administrator";
import { trackEvent } from "../gtag";

type AssemblyActions =
  | "Global controller search"
  | "Moved controller"
  | "Paired controller and vehicle"
  | "Paired controller and lock"
  | "Vehicle RFID search, vehicle edited"
  | "Vehicle RFID search, vehicle set as active"
  | "Vehicle RFID searched";

type ControllersActions =
  | "Clicked controller page tab"
  | "Created controller"
  | "Deleted controller"
  | "Forced firmware update"
  | "Paired controller and vehicle"
  | "Unpaired controller and vehicle"
  | "Updated controller";

type DashboardActions =
  | "Clicked battery stats link"
  | "Clicked dock groups stats link"
  | "Clicked operation locations link"
  | "Clicked rebalancing stats link"
  | "Clicked system alerts link"
  | "Clicked vehicle stats link";

type DockGroupsActions =
  | "Changed state"
  | "Clicked dock group page tab"
  | "Created dock group"
  | "Deleted dock group"
  | "Updated dock group";

type ListFiltersActions =
  | "Added filter"
  | "Changed filter"
  | "Changed matching type"
  | "Removed filter";

type IncidentsActions = "Resolved incident";

type MainMenuActions =
  | "Clicked first level item"
  | "Toggled main menu"
  | "Toggled main menu";

type MaintenanceActions =
  | "Added repair from repairs select"
  | "Added repair from report view"
  | "Checked replaced part in repair modal"
  | "Clicked adjustments only in repairs modal"
  | "Clicked adjustments only in repairs table"
  | "Clicked done in edit repair modal"
  | "Clicked maintenance page tab"
  | "Clicked tab for a maintenance report"
  | "Clicked trips link in vehicle section of report"
  | "Clicked vehicle link in vehicle section of report"
  | "Closed repair modal"
  | "Maintenance done from quick report modal"
  | "Maintenance done from report view"
  | "Maintenance form cancelled and reset from report view"
  | "Opened edit repair modal from report view"
  | "Removed repair from repairs select"
  | "Removed repair from report view"
  | "Removed repair in edit repair modal"
  | "Reopened maintenance from report view"
  | "Reported damage(s) from damages page"
  | "Reported damage(s) from report view"
  | "Reported damage(s) from vehicle timeline"
  | "Showed report(s) for dock group"
  | "Started maintenance from create report view"
  | "Started maintenance from dock groups overview"
  | "Started maintenance from report view"
  | "Unchecked replaced part in repair modal";

type NewVersionNotificationActions =
  | "Clicked not now"
  | "Clicked reload"
  | "Saw notification"
  | "Was force reloaded";

type RebalancingActions =
  | "Changed misplaced vehicles map filter"
  | "Clicked dock group map marker"
  | "Clicked map list dock group link"
  | "Clicked misplaced vehicles map dock group marker"
  | "Clicked misplaced vehicles map list link"
  | "Clicked misplaced vehicles map vehicle marker"
  | "Expanded dock group in list"
  | "Jumped to dock group map marker position"
  | "Jumped to misplaced vehicles map position";

type SalesActions =
  | "Created new product"
  | "Deleted product"
  | "Updated product";

type SystemAlertsActions =
  | "Created system alert"
  | "Deleted system alert"
  | "Resolved system alert"
  | "Unresolved system alert"
  | "Updated system alert";

type SystemActions =
  | "Created operation locations"
  | "Created tag"
  | "Deleted operation locations"
  | "Deleted tag"
  | "Updated operation locations"
  | "Updated system"
  | "Updated tag";

type TeamActions = "Edit admin's RFID cards" | "Edit single RFID card";

type ValueCodesActions =
  | "Clicked value codes page tab"
  | "Activated download button"
  | "Downloaded value codes"
  | "Created value codes";

type VehiclesActions =
  | "Clicked dock group on vehicles map"
  | "Clicked marker on vehicles map"
  | "Clicked vehicle page tab"
  | "Created vehicle"
  | "Deleted vehicle"
  | "Moved vehicle to operation location"
  | "Updated vehicle";

type KnownEvents =
  | {
      category: "Assembly";
      action: AssemblyActions;
    }
  | {
      category: "Controllers";
      action: ControllersActions;
    }
  | {
      category: "Dashboard";
      action: DashboardActions;
    }
  | {
      category: "Dock groups";
      action: DockGroupsActions;
    }
  | {
      category: "List filters";
      action: ListFiltersActions;
    }
  | {
      category: "Incidents";
      action: IncidentsActions;
    }
  | {
      category: "Main menu";
      action: MainMenuActions;
    }
  | {
      category: "Maintenance";
      action: MaintenanceActions;
    }
  | {
      category: "New version notification";
      action: NewVersionNotificationActions;
    }
  | {
      category: "Rebalancing";
      action: RebalancingActions;
    }
  | {
      category: "Sales";
      action: SalesActions;
    }
  | {
      category: "System alerts";
      action: SystemAlertsActions;
    }
  | {
      category: "System";
      action: SystemActions;
    }
  | {
      category: "Team";
      action: TeamActions;
    }
  | {
      category: "Value codes";
      action: ValueCodesActions;
    }
  | {
      category: "Vehicles";
      action: VehiclesActions;
    };

type Params = {
  label?: string | null;
  value?: number | null;
} & KnownEvents;

const useEventTracking = () => {
  const administrator = useContext(AdministratorContext);

  return useCallback(
    ({ action, category, label = null, value = null }: Params) => {
      if (administrator) {
        const adminId = administrator.id;
        const roleIds = administrator.roles.map(({ id }) => id);
        const systemId = administrator.selectedSystem.id;

        trackEvent({
          action,
          adminId,
          category,
          label,
          roleIds,
          systemId,
          value
        });
      }
    },
    [administrator]
  );
};

export default useEventTracking;
