import React from "react";
import {
  Text,
  Box,
  Button,
  Flex,
  RelativeScale
} from "@urbaninfrastructure/react-ui-kit";
import Avatar from "./Avatar";
import Popover from "./Popover";

const defaultImage = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 29 29'%3E%3Ccircle cx='14.5' cy='14.5' r='14.5' fill='%23E3E6EB'%3E%3C/circle%3E%3C/svg%3E`;

export default function AdministratorPopover({
  administrator,
  typoStyle = "xxs"
}: {
  administrator: {
    id: string;
    name: string | null;
    profilePictureUrl: string | null;
    email: string | null;
  };
  typoStyle?: RelativeScale;
}) {
  return (
    <Popover
      closeOnOutsideClick
      placement="bottom"
      renderToggler={({ ref, onClick }) => {
        return (
          <span ref={ref}>
            <Button
              type="button"
              variant="reset"
              px={0}
              py={0}
              bg="transparent"
              onClick={event => {
                event.stopPropagation();
                onClick();
              }}
            >
              <Flex
                borderRadius={1000}
                borderStyle="solid"
                borderWidth={1}
                borderColor="borderGray"
                pl={1}
                pr={3}
                py={1}
                alignItems="center"
              >
                <Box mr={1}>
                  <Avatar
                    width="20px"
                    src={administrator.profilePictureUrl || defaultImage}
                  />
                </Box>
                <Text typoStyle={typoStyle}>
                  {administrator.name || administrator.id}
                </Text>
              </Flex>
            </Button>
          </span>
        );
      }}
    >
      {() => (
        <Flex alignItems="center">
          <Text>{administrator.email}</Text>
        </Flex>
      )}
    </Popover>
  );
}
