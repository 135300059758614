import React from "react";
import { debounce } from "lodash";
import styled, { keyframes, css } from "styled-components";
import { Box } from "@urbaninfrastructure/react-ui-kit";
import { MoreCircleAlt } from "@urbaninfrastructure/react-icons";

type Props = React.PropsWithChildren<{
  width?: string;
}>;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

const ContentWrapper = styled.div<{ overflowRight: boolean }>`
  max-height: 1.5rem;
  &:not(:hover) {
    white-space: nowrap;
    overflow: hidden;
    & > * {
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }
  ${({ overflowRight }) =>
    overflowRight &&
    css`
      /* To avoid parent height changes on hover on tag groups */
      max-height: none;
      &:not(:hover) {
        max-height: 1.5rem;
      }
    `}
`;

const SuffixIcon = styled.span`
  position: absolute;
  top: 0;
  right: -24px;
  display: inline-block;
  height: 100%;
  min-height: 24px;
  width: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 2px;
  pointer-events: none;
`;

const Base = styled(Box)<{ overflowRight: boolean; width?: string }>`
  ${({ overflowRight, width }) => css`
    white-space: nowrap;
    max-width: ${width ? `${width}px` : "none"};
    ${overflowRight &&
      css`
        position: relative;
        margin-right: 24px;
        &:hover {
          ${SuffixIcon} {
            display: none;
          }
          width: ${width ? `${width}px` : "auto"};
          overflow: visible;
          ${ContentWrapper} {
            overflow: visible;
            white-space: normal;
            position: absolute;
            z-index: 2;
            width: ${width
              ? parseFloat(width) > 300
                ? `${width + 24}px`
                : "324px"
              : "calc(100% + 24px)"};
            white-space: wrap;
            border: none;
            background: ${({ theme }) => theme.colors.white};
            box-shadow: ${({ theme }) => theme.shadows.light};
            padding: ${({ theme }) => theme.space[1]}px;
            margin-left: -${({ theme }) => theme.space[1]}px;
            margin-top: -${({ theme }) => theme.space[1]}px;
            animation: ${fadeIn} 200ms ease-in;
          }
        }
      `}
  `}
`;

const Ellipses = ({ children, width, ...props }: Props) => {
  const [overflowRight, setOverflowRight] = React.useState(false);
  const contentWrapper = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const elem = contentWrapper.current;
    if (!elem) {
      return;
    }

    const updateOverflow = () => {
      // Check scroll width and position to detect overflow
      setOverflowRight(
        elem.offsetWidth < elem.scrollWidth &&
          elem.scrollLeft - elem.clientLeft + elem.offsetWidth <
            elem.scrollWidth
      );
    };

    updateOverflow();

    const debounced = debounce(() => updateOverflow(), 250);

    window.addEventListener("resize", debounced);

    return () => window.removeEventListener("resize", debounced);
  }, []);

  return (
    <Base {...props} width={width} overflowRight={overflowRight}>
      <ContentWrapper ref={contentWrapper} overflowRight={overflowRight}>
        {children}
      </ContentWrapper>

      {overflowRight && (
        <SuffixIcon>
          <MoreCircleAlt color="neutral.3" size="20px" />
        </SuffixIcon>
      )}
    </Base>
  );
};

export default Ellipses;
