import React from "react";
import {
  Alarm,
  Cart,
  City,
  Dashboard,
  HomeAlt,
  LockerAlt,
  Operate,
  Plan,
  Profile as ProfileIcon,
  Receipt,
  RfidAlt,
  Station,
  Tune,
  UserShield,
  Valuecode,
  Warning,
  Wrench,
  Info
} from "@urbaninfrastructure/react-icons";
import {
  SvgIconProps,
  SvgIconInternalProps
} from "@urbaninfrastructure/react-icons/lib/Svg";

import VehicleIcon from "./VehicleIcon";

type IconMap = Partial<
  Record<Pathname, (props: SvgIconInternalProps) => JSX.Element>
>;

const icons: IconMap = {
  "/[systemId]/assembly": Tune,
  "/[systemId]/business": HomeAlt,
  "/[systemId]/controllers": RfidAlt,
  "/[systemId]": Dashboard,
  "/[systemId]/discounts": Valuecode,
  "/[systemId]/stations": Station,
  "/[systemId]/docks": LockerAlt,
  "/[systemId]/incidents": Warning,
  "/[systemId]/maintenance": Wrench,
  "/[systemId]/rebalancing": Operate,
  "/[systemId]/sales": Cart,
  "/[systemId]/system": City,
  "/[systemId]/system-alerts": Alarm,
  "/[systemId]/team": UserShield,
  "/[systemId]/trips": Plan,
  "/[systemId]/users": ProfileIcon,
  "/[systemId]/value-codes": Receipt,
  "/[systemId]/vehicles": VehicleIcon,
  "/[systemId]/support": Info
};

const RouteIcon = ({
  route,
  ...props
}: {
  route: Pathname;
} & SvgIconProps) => {
  const Icon = icons[route];

  if (!Icon) {
    return null;
  }

  return <Icon {...props} />;
};

export default RouteIcon;
