import React from "react";
import { SpaceProps } from "styled-system";
import styled, { css } from "styled-components";
import { Text, Box } from "@urbaninfrastructure/react-ui-kit";

type Props = SpaceProps & {
  caption: React.ReactNode;
  value?: React.ReactNode;
  large?: boolean;
  block?: boolean;
  inline?: boolean;
};

const blockCss = ({ theme, block }) => css`
  ${block &&
    css`
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.borderGray};
      border-radius: ${({ theme }) => theme.radii["md"]};
    `};
`;

const horizontalScrollableCss = ({ horizontalScrollable }) =>
  horizontalScrollable &&
  css`
    overflow-y: hidden;
    overflow-x: auto;
  `;

const Wrapper = styled(Box)<{ block: boolean; horizontalScrollable: boolean }>`
  ${horizontalScrollableCss};
  ${blockCss};
`;

const Value = styled(Text)`
  line-height: 1;
  font-weight: bold;
`;

const Kpi = ({ caption, value, large, block, inline, ...props }: Props) => {
  return (
    <Wrapper
      block={block || false}
      p={block ? 5 : 0}
      horizontalScrollable={large || false}
      {...props}
    >
      <Value
        fontSize={large ? 5 : 4}
        mb={0}
        display={inline ? "inline-block" : undefined}
        mr={inline ? 2 : undefined}
      >
        {value}
      </Value>

      <Text m={0} mt={3} display={inline ? "inline-block" : undefined}>
        {caption}
      </Text>
    </Wrapper>
  );
};

export default Kpi;
