import React from "react";
//import invariant from "invariant";
import Downshift from "downshift";
import {
  Flex,
  Box,
  inputDefaultProps,
  Text,
  List,
  Label
} from "@urbaninfrastructure/react-ui-kit";
import { Add as AddIcon } from "@urbaninfrastructure/react-icons";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DropResult
} from "react-beautiful-dnd";

import Tag from "../Tag";
import { FormGroup } from "../Form/Group";
import {
  AddIcon as AddIconWrapper,
  ArrowIcon,
  ArrowIconWrapper,
  SpinnerIcon,
  DropdownGroups,
  DropdownGroupLabel,
  DropdownItem,
  AutocompleteInput,
  AutocompleteInputWrapper
} from "./styled";

export type ItemType = {
  id: string;
  name: string;
  colour?: string | null | undefined;
  type?: "locked" | "system";
  locked?: boolean | null | undefined;
};

export type GroupType = {
  label: string | null | undefined;
  items: ItemType[];
  suggestions?: boolean;
};

type GroupsForView = {
  groups: React.ReactNode[];
  itemIndex: number;
};

export type OnChange = (itemTypes: ItemType[]) => void;

export type Props = {
  label: React.ReactNode;
  id?: string;
  placeholder?: string;
  items?: ItemType[];
  groups?: GroupType[];
  values: ItemType[];
  onChange: OnChange;
  onAdd: (itemType: ItemType) => void;
  onRemove: (itemType: ItemType) => void;
  autofocus?: boolean;
  itemToString: (itemType: ItemType) => string;
  inputProps?: Record<string, any>;
  removeSelected?: boolean;
  removeSelectedOnClick?: boolean;
  hideTags?: boolean;
  "data-testid"?: string;
  large?: boolean;
  disabled?: boolean;
  openOnFocus?: boolean;
  renderAfterInput?: React.ReactNode;
  loading?: boolean;
  removeValuesOnBackspace?: boolean;
  draggable?: boolean;
  defaultIsOpen?: boolean;
  position?: "absolute" | "static";
};

type State = {
  input: string;
  isFocused: boolean;
};

type ValueListProps = Pick<Props, "draggable" | "loading" | "values"> & {
  removeItem: (item: ItemType) => void;
  itemToString: NonNullable<Props["itemToString"]>;
};

const noop = () => {
  // noop
};

function DropdownItems({
  group,
  accum,
  large,
  highlightedIndex,
  values,
  getItemProps,
  itemToString
}) {
  return (
    <List>
      {group.items.map(item => {
        const index = accum.itemIndex++;
        const isActive = highlightedIndex === index;
        const selected = values.some(value => value.id === item.id);
        const props = getItemProps({
          item,
          index
        });

        let bg = "white";
        let color = "black";
        if (isActive) {
          bg = "primary";
          color = "white";
        } else if (selected) {
          bg = "white";
        }

        return (
          <DropdownItem
            key={item.id}
            px={3}
            py={large ? 3 : 2}
            bg={bg}
            color={color}
            selected={selected}
            large={large}
            {...props}
          >
            {selected ? (
              <Tag
                bg={isActive ? "white" : "neutral.3"}
                color="black"
                large
                style={{ cursor: "default" }}
              >
                {itemToString(item)} ×
              </Tag>
            ) : (
              <>
                <Box>{itemToString(item)}</Box>
                {item.description ? (
                  <Text
                    color={isActive ? "white" : "neutral.5"}
                    style={{
                      fontStyle: "italic"
                    }}
                  >
                    {item.description}
                  </Text>
                ) : null}
              </>
            )}
          </DropdownItem>
        );
      })}
    </List>
  );
}

// a little function to help us with reordering the result
const reorder = (
  list: ItemType[],
  startIndex: number,
  endIndex: number
): ItemType[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ValueList = React.memo(function ValueList({
  values,
  loading,
  itemToString,
  draggable,
  removeItem
}: ValueListProps) {
  return (
    <Flex data-testid="MultiSelect__SelectedItems" flexWrap="wrap">
      {values.map((value, index) => {
        return (
          <Draggable
            key={value.id}
            draggableId={value.id}
            index={index}
            isDragDisabled={!draggable}
          >
            {(provided: DraggableProvided) => {
              return (
                <Box
                  ref={provided.innerRef}
                  css={
                    draggable &&
                    `
                    cursor: drag;
                  `
                  }
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Tag
                    ml={2}
                    mt="9px"
                    data-testid={`MultiSelect__SelectedItem__${value.id}`}
                    onRemoveClick={
                      !value.locked ? () => removeItem(value) : undefined
                    }
                    disableRemove={loading}
                    bg={value.colour ? value.colour : "neutral.2"}
                    type={value.type}
                    large
                  >
                    {itemToString(value)}
                  </Tag>
                </Box>
              );
            }}
          </Draggable>
        );
      })}
    </Flex>
  );
});

ValueList.displayName = "ValueList";

class MultiSelect extends React.Component<Props, State> {
  static defaultProps = {
    itemToString: (i: ItemType) => (i ? i.name : ""),
    onAdd: noop,
    onRemove: noop,
    removeSelected: true,
    removeSelectedOnClick: true,
    openOnFocus: false,
    removeValuesOnBackspace: true,
    position: "absolute"
  };

  state = {
    input: "",
    isFocused: false
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    const { values } = this.props;
    if (values.length && !this.state.input.length && event.keyCode === 8) {
      const lastItem = values[values.length - 1];
      if (lastItem) {
        this.removeItem(lastItem);
      }
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ input: event.target.value });
  };

  handleChange = (item: ItemType) => {
    if (!item) {
      return;
    }

    const { values, removeSelectedOnClick } = this.props;
    if (values.some(value => value.id === item.id)) {
      if (removeSelectedOnClick) {
        this.removeItem(item);
      }
    } else {
      this.addItem(item);
    }
    this.setState({ input: "" });
  };

  addItem = (item: ItemType) => {
    const { values, onChange, onAdd } = this.props;
    onAdd(item);
    onChange([...values, item]);
  };

  removeItem = (item: ItemType) => {
    const { onChange, onRemove, values } = this.props;
    if (!item.locked) {
      onRemove(item);
      onChange(values.filter(v => v.id !== item.id));
    }
  };

  onFocus = () => {
    this.setState({
      isFocused: true
    });
  };

  onBlur = () => {
    this.setState({
      isFocused: false
    });
  };

  getGroups = () => {
    const { input } = this.state;
    const { values, itemToString, removeSelected } = this.props;
    const valueIds = values.map(v => v.id);

    let filtered = false;

    let groups: GroupType[] = [];

    if (this.props.groups) {
      // Copy out objects and loose the reference
      this.props.groups.map(group => {
        groups.push({
          label: group.label,
          items: group.items,
          suggestions: group.suggestions
        });
      });
    } else if (this.props.items) {
      groups.push({ label: null, items: this.props.items, suggestions: false });
    }

    groups = groups.reduce<GroupType[]>((accum, group) => {
      // Filter by input
      if (input) {
        let filteredItems;
        if (!isNaN(Number(input))) {
          // if numbers, filter by startsWith
          filteredItems = group.items.filter(
            item =>
              itemToString(item)
                .toLowerCase()
                .replace(".", "")
                .startsWith(input.toLowerCase().replace(".", "")) // remove . from strings
          );
        } else {
          // if anything else, filter by includes
          filteredItems = group.items.filter(item =>
            itemToString(item)
              .toLowerCase()
              .includes(input.toLowerCase())
          );
        }
        if (filteredItems.length === 0) {
          group.items = [];
        } else {
          group.items = filteredItems;
          filtered = true;
        }
      }

      // If removeSelected is default, or the group consist of suggestions
      if (removeSelected || group.suggestions) {
        // Remove selected items
        group.items = group.items.filter(item => !valueIds.includes(item.id));
      }

      accum.push(group);

      return accum;
    }, []);

    groups = groups.reduce<GroupType[]>((accum, group) => {
      // Show groups with more than 0 items
      if (group.items.length > 0) {
        // When searching show groups that are not labeled suggestions
        if (!filtered || (filtered && !group.suggestions)) {
          accum.push(group);
        }
      }

      return accum;
    }, []);

    return groups;
  };

  render() {
    // invariant(
    //   this.props.items || this.props.groups,
    //   "Either items or groups is required"
    // );

    const {
      label,
      onChange,
      placeholder,
      values,
      itemToString,
      inputProps,
      removeSelected,
      removeValuesOnBackspace,
      autofocus,
      hideTags,
      large,
      disabled,
      loading,
      openOnFocus,
      renderAfterInput,
      draggable,
      position,
      ...rest
    } = this.props;
    const { input } = this.state;
    const groups = this.getGroups();

    function onDragEnd(result: DropResult) {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newValues = reorder(
        values,
        result.source.index,
        result.destination.index
      );

      onChange(newValues);
    }

    return (
      <FormGroup mb={0}>
        <Downshift<ItemType>
          inputValue={input}
          onChange={this.handleChange}
          itemToString={itemToString}
          onInputValueChange={(inputValue, stateAndHelpers) => {
            if (
              !inputValue &&
              // @ts-ignore type is there
              stateAndHelpers.type === "__autocomplete_blur_input__"
            ) {
              this.setState({ input: "" });
            }

            if (inputValue && inputValue.length > 0) {
              stateAndHelpers.setHighlightedIndex(0);
            } else {
              stateAndHelpers.setHighlightedIndex(-1);
            }
          }}
          {...rest}
        >
          {({
            getInputProps,
            getLabelProps,
            getItemProps,
            isOpen,
            highlightedIndex,
            openMenu,
            toggleMenu
          }) => {
            const showGroups =
              (openOnFocus && this.state.isFocused) ||
              !!(isOpen && groups.length);
            return (
              <div
                data-testid={rest["data-testid"] || "MultiSelect"}
                style={{ position: "relative" }}
              >
                {!large && <Label {...getLabelProps()}>{label}</Label>}
                <Flex alignItems="center" justifyContent="center">
                  <AutocompleteInputWrapper
                    {...inputDefaultProps}
                    px={0}
                    py={0}
                    m={0}
                    alignItems="flex-start"
                    flexWrap="wrap"
                    position="relative"
                    bg="white"
                    isFocused={this.state.isFocused}
                    large={large}
                    disabled={disabled}
                  >
                    {large && (
                      <AddIconWrapper
                        mt={3}
                        ml={3}
                        onClick={() => toggleMenu()}
                      >
                        <AddIcon color="white" size="12px" />
                      </AddIconWrapper>
                    )}
                    {!hideTags && values.length ? (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                          droppableId="MultiSelect"
                          direction="horizontal"
                        >
                          {(provided: DroppableProvided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <ValueList
                                  values={values}
                                  loading={loading}
                                  itemToString={itemToString}
                                  draggable={draggable}
                                  removeItem={this.removeItem}
                                />
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </DragDropContext>
                    ) : null}

                    <AutocompleteInput
                      flex={1}
                      onClick={openMenu}
                      placeholder={
                        values.length && !hideTags ? "" : placeholder
                      }
                      style={{ minWidth: "6rem" }}
                      autoFocus={autofocus}
                      ariaLabel={large && label}
                      py={large && 4}
                      {...(getInputProps({
                        onChange: this.handleInputChange,
                        onKeyDown: removeValuesOnBackspace
                          ? this.handleKeyDown
                          : undefined,
                        onFocus: this.onFocus,
                        onBlur: this.onBlur
                      }) as any)}
                      disabled={disabled}
                      autoComplete="search"
                      type="search"
                      {...inputProps}
                    />

                    <ArrowIconWrapper right="15px" onClick={() => toggleMenu()}>
                      {loading ? <SpinnerIcon /> : <ArrowIcon />}
                    </ArrowIconWrapper>
                  </AutocompleteInputWrapper>

                  {renderAfterInput}
                </Flex>

                {showGroups && (
                  <div data-testid="MultiSelect__DropdownGroups">
                    <DropdownGroups position={position} large={large}>
                      {
                        groups.reduce<GroupsForView>(
                          (accum, group, groupIndex) => {
                            accum.groups.push(
                              <Box key={groupIndex}>
                                {group.label && (
                                  <DropdownGroupLabel
                                    px={3}
                                    pb={1}
                                    pt={1}
                                    color="neutral.5"
                                    bg="neutral.1"
                                    large={large}
                                  >
                                    {group.label}
                                  </DropdownGroupLabel>
                                )}
                                <DropdownItems
                                  group={group}
                                  accum={accum}
                                  large={large}
                                  getItemProps={getItemProps}
                                  highlightedIndex={highlightedIndex}
                                  itemToString={itemToString}
                                  values={values}
                                />
                              </Box>
                            );
                            return accum;
                          },
                          { groups: [], itemIndex: 0 }
                        ).groups
                      }
                    </DropdownGroups>
                  </div>
                )}
              </div>
            );
          }}
        </Downshift>
      </FormGroup>
    );
  }
}

export default MultiSelect;
