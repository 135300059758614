import React from "react";
import {
  Text,
  Arrow,
  theme,
  UIKitTheme as Theme
} from "@urbaninfrastructure/react-ui-kit";
import styled, { keyframes, css } from "styled-components";

type Props = {
  caption: React.ReactNode;
  breakpoint: number;
};

type State = {
  show: boolean;
};

type StyledProps = {
  theme: Theme;
  show: boolean;
  breakpoint: number;
};

const dropdownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px)
  }
  to {
    opacity:1;
    transform: translateY(1px)
  }
`;

const toggleWrapperCss = ({ theme, show, breakpoint }) => css`
  @media screen and (max-width: ${theme.breakpoints[breakpoint]}) {
    border-top: 1px solid transparent;
    margin-bottom: ${theme.space[4]}px;
    ${show &&
      css`
        width: 100%;
        background-color: ${theme.colors.neutral[1]};
        border: 1px solid ${theme.colors.neutral[2]};
        border-radius: ${({ theme }) => theme.radii["md"]};
        padding: ${theme.space[4]}px;
        padding-top: 0;
      `};
  }
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    ${show &&
      css`
        width: calc(100% + ${theme.space[4]}px + ${theme.space[4]}px);
        border-left: none;
        border-right: none;
        border-radius: 0;
        margin-left: -${theme.space[4]}px;
        margin-right: -${theme.space[4]}px;
      `};
  }
`;

const contentWrapperCss = ({ theme, show, breakpoint }: StyledProps) => css`
  @media screen and (max-width: ${theme.breakpoints[breakpoint]}) {
    display: none;
    ${show &&
      css`
        display: block;
        animation: 100ms ease-out ${dropdownAnimation};
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        /* Fix z-index animation bug on child elements */
        -webkit-animation-fill-mode: none;
        animation-fill-mode: none;
      `};
  }
`;

const toggleButtonCss = ({ theme, show, breakpoint }: StyledProps) => css`
  display: none;
  @media screen and (max-width: ${theme.breakpoints[breakpoint]}) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${theme.colors.neutral[2]};
    font-size: ${theme.fontSizes[1]}px;
    color: ${theme.colors.text};
    cursor: pointer;
    padding: ${theme.space[3]}px ${theme.space[4]}px;
    ${theme.mediaQueries[0]} {
      padding: ${theme.space[3]}px ${theme.space[5]}px;
    }
    ${show &&
      css`
        margin-bottom: ${theme.space[6]}px;
      `};
    &:focus {
      outline: none;
    }
  }
`;

const ToggleWrapper = styled.div`
  ${toggleWrapperCss};
`;

ToggleWrapper.defaultProps = {
  theme
};

const ContentWrapper = styled.div`
  ${contentWrapperCss};
`;

ContentWrapper.defaultProps = {
  theme
};

const ToggleButton = styled.button`
  ${toggleButtonCss};
`;

ToggleButton.defaultProps = {
  theme
};

export default class ToggleOnMobile extends React.Component<Props, State> {
  static defaultProps = {
    breakpoint: 0
  };

  state = { show: false };

  render() {
    const { caption, breakpoint } = this.props;
    const { show } = this.state;
    const title = show ? "Show options" : "Hide options";

    return (
      // @ts-ignore
      <ToggleWrapper show={show} breakpoint={breakpoint}>
        <ToggleButton
          type="button"
          onClick={() => this.setState({ show: !this.state.show })}
          aria-expanded={show}
          aria-label={title}
          title={title}
          show={show}
          breakpoint={breakpoint}
        >
          <Text>{caption}</Text>

          <Arrow direction={show ? "up" : "down"} />
        </ToggleButton>

        <ContentWrapper show={show} {...this.props} />
      </ToggleWrapper>
    );
  }
}
