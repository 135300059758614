import React from "react";
import { Button, Arrow, Flex, Box } from "@urbaninfrastructure/react-ui-kit";
import Table from "../Table";
import { I18NEdit } from "../buttons-i18n";
import DataTableFormRow from "./DataTableFormRow";
import DataTableFormCreateRow from "./DataTableFormCreateRow";
import ActionsDialog from "./ActionsDialog";

import { DataTableProps } from "./types";
import { ariaSortMap, Sortable } from "../Sortable";
import { HorizontalOverflow } from "../HorizontalOverflow";
import { SortProps, SortDirection } from "../Sortable/types";
import ModalRenderer from "components/ModalRenderer";

export default class DataTable extends React.Component<
  DataTableProps<any, any>
> {
  static defaultProps = {
    idRow: "id",
    rowProps: {},
    expandedRowBg: "#ffc",
    wrap: false
  };
  shouldRenderArrow = () => {
    const {
      columns,
      onRowClick,
      noEdit,
      expandedRowIds,
      expandableRowIds
    } = this.props;

    if (expandedRowIds || onRowClick) {
      if (expandableRowIds && expandableRowIds.length === 0) {
        return false;
      }

      return true;
    }

    const editable = columns.some(column => {
      if (column.editable && !noEdit) {
        return true;
      }
    });

    return editable;
  };
  getRowProps = (rowIndex: number) => {
    const { rowProps } = this.props;
    if (typeof rowProps === "function") {
      return rowProps(rowIndex) || {};
    }
    return rowProps;
  };
  getRowWhiteSpace = () => {
    return this.props.wrap ? "normal" : "nowrap";
  };
  renderHead = (
    getSortProps: (key: string) => SortProps,
    getSortDirectionFor: (key: string) => SortDirection | undefined,
    renderArrow: boolean
  ) => {
    const { columns, expandedRowIds, actions } = this.props;

    const actionsDialog = actions ? <ActionsDialog items={actions} /> : null;
    return (
      <thead>
        <Table.Tr whiteSpace={this.getRowWhiteSpace()}>
          {renderArrow && expandedRowIds ? <Table.Th /> : null}
          {columns.map((column, i) => {
            const {
              property,
              key,
              header: { sortable }
            } = column;
            const sortKey = key || property;
            const thProps = sortable ? { ...getSortProps(sortKey) } : {};

            return sortable ? (
              <Table.SortableTh
                sortDirection={getSortDirectionFor(sortKey)}
                key={i}
                {...thProps}
                {...column.header.props}
                scope="col"
              >
                {column.header.label}
              </Table.SortableTh>
            ) : (
              <Table.Th key={i} {...column.header.props} scope="col">
                <Flex>
                  <Box flex={1}>{column.header.label}</Box>
                </Flex>
              </Table.Th>
            );
          })}
          {(renderArrow || actionsDialog) && !expandedRowIds ? (
            <Table.Th>{actionsDialog}</Table.Th>
          ) : null}
        </Table.Tr>
      </thead>
    );
  };
  renderBody = (renderArrow: boolean) => {
    const {
      columns,
      expandableRowIds,
      formOperation,
      formWrapperProps,
      idBeingEdited,
      noEdit,
      onRowCancelClick,
      onRowClick,
      onRowDeleteClick,
      onRowEditClick,
      onRowSubmit,
      renderExpanded,
      expandedRowIds,
      highlightRowIds,
      expandedRowBg,
      rows,
      actions,
      modal,
      modalToggle
    } = this.props;

    return (
      <tbody>
        {!modal && formOperation === "create" ? (
          <DataTableFormCreateRow
            columns={columns}
            onCancelClick={onRowCancelClick}
            onSubmit={onRowSubmit}
            formWrapperProps={formWrapperProps}
          />
        ) : null}
        {rows.map((row, i) => {
          if (!row) {
            return null;
          }
          const key = row.id || i;
          if (
            !modal &&
            formOperation === "update" &&
            idBeingEdited === row.id
          ) {
            return (
              <DataTableFormRow
                i={i}
                key={`update-${key}`}
                columns={columns}
                row={row}
                onCancelClick={onRowCancelClick}
                onDeleteClick={onRowDeleteClick}
                getRowProps={this.getRowProps}
                onSubmit={onRowSubmit}
                formWrapperProps={formWrapperProps}
              />
            );
          }
          let editable = false;
          const canExpand = !!expandedRowIds;
          const isExpanded = expandedRowIds && expandedRowIds.includes(row.id);
          const columnCount = renderArrow ? columns.length + 1 : columns.length;
          const canClickRow =
            !expandableRowIds || expandableRowIds.includes(row.id);
          const onClick =
            canClickRow && onRowClick
              ? (event: React.SyntheticEvent<HTMLElement>) => {
                  if (onRowClick) {
                    onRowClick(event, i);
                  }
                }
              : undefined;

          let arrowDirection: "up" | "down" | "left" | "right" = "right";

          if (canExpand) {
            arrowDirection = isExpanded ? "up" : "down";
          }

          const arrowTd = (
            <Table.Td
              textAlign="right"
              py={2}
              style={{ verticalAlign: "middle" }}
            >
              {canClickRow ? (
                <Arrow
                  direction={arrowDirection}
                  color="neutral.5"
                  style={{ verticalAlign: "middle" }}
                  width={this.props.props && this.props.props.small ? 6 : 8}
                  height={this.props.props && this.props.props.small ? 6 : 8}
                />
              ) : null}
            </Table.Td>
          );

          const bg =
            isExpanded || (highlightRowIds && highlightRowIds.includes(row.id))
              ? expandedRowBg
              : undefined;

          let rowProps = this.getRowProps(i) || {};

          if (bg) {
            rowProps = { ...rowProps, bg };
          }

          return (
            <React.Fragment key={key}>
              <Table.Tr
                onClick={onClick}
                whiteSpace={this.getRowWhiteSpace()}
                {...rowProps}
              >
                {!editable && renderArrow && expandedRowIds && arrowTd}
                {columns.map(column => {
                  if (!row) {
                    return null;
                  }
                  // try to find the row accociated with the column
                  const rowKey = Object.keys(row).find(
                    _rowKey => column.property === _rowKey
                  );

                  if (!rowKey) {
                    return null;
                  }

                  let rowValue = row[rowKey];

                  if (column.cell && column.cell.formatter) {
                    rowValue = column.cell.formatter({ value: rowValue, row });
                  }

                  const tdProps =
                    column.cell && column.cell.props ? column.cell.props : {};

                  if (column.editable && !noEdit) {
                    editable = true;
                  }

                  const rowHeader =
                    column.cell && column.cell.rowHeader
                      ? column.cell.rowHeader
                      : false;

                  return rowHeader ? (
                    <Table.Th
                      key={column.key || rowKey}
                      {...tdProps}
                      scope="row"
                    >
                      {rowValue}
                    </Table.Th>
                  ) : (
                    <Table.Td
                      key={column.key || rowKey}
                      {...tdProps}
                      verticalAlign={"middle"}
                    >
                      {rowValue}
                    </Table.Td>
                  );
                })}
                {/* Here can implement a Dialog Box */}
                {modal && editable && (
                  <Table.Td textAlign="right" py={2}>
                    <ModalRenderer
                      closeOnOutsideClick={false}
                      renderToggler={({ openPortal }) => {
                        return (
                          <>{modalToggle && modalToggle(openPortal, row)}</>
                        );
                      }}
                      render={({ closePortal }) => {
                        return (
                          <>
                            {modal &&
                              modal(closePortal, i, key, row, this.getRowProps)}
                          </>
                        );
                      }}
                    />
                  </Table.Td>
                )}
                {!modal && editable && (
                  <Table.Td textAlign="right" py={2}>
                    <Button
                      small
                      onClick={() =>
                        row && onRowEditClick && onRowEditClick(row.id)
                      }
                      disabled={!!formOperation}
                      data-testid="DataTable__EditButton"
                      type="button"
                    >
                      <I18NEdit />
                    </Button>
                  </Table.Td>
                )}
                {!editable && renderArrow && !expandedRowIds && arrowTd}
                {!editable && !renderArrow && actions && <Table.Td />}
              </Table.Tr>

              {isExpanded && renderExpanded && (
                <Table.Tr>
                  <Table.Td colSpan={columnCount} bg={bg}>
                    {renderExpanded(i)}
                  </Table.Td>
                </Table.Tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    );
  };
  getSortBy = (): { key: string; direction: SortDirection } | undefined => {
    const { options } = this.props;

    if (options && options.order) {
      const direction = Object.keys(ariaSortMap).find(v => v === options.order);
      const key = options.orderBy;
      if (key && direction) {
        return { key, direction: direction as SortDirection };
      }
    }
  };
  render() {
    const {
      caption,
      loading,
      props,
      footer,
      onSortClick = () => {
        // noop
      }
    } = this.props;
    const sortBy = this.getSortBy();
    const renderArrow = this.shouldRenderArrow();

    return (
      <>
        <Sortable
          sortBy={sortBy}
          onSort={onSortClick}
          render={({ getSortProps, getSortDirectionFor }) => {
            return (
              <HorizontalOverflow>
                <Table style={{ opacity: loading ? 0.5 : 1 }} {...props}>
                  {caption && <Table.Caption>{caption}</Table.Caption>}
                  {this.renderHead(
                    getSortProps,
                    getSortDirectionFor,
                    renderArrow
                  )}
                  {this.renderBody(renderArrow)}
                  {!!footer && <tfoot>{footer}</tfoot>}
                </Table>
              </HorizontalOverflow>
            );
          }}
        />
      </>
    );
  }
}
