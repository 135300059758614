import React from "react";
import styled from "styled-components";
import {
  Hide,
  Flex,
  theme,
  Label,
  Input,
  Textarea
} from "@urbaninfrastructure/react-ui-kit";
import array from "../../src/utils/array";
import { LanguageCode, InputLocaleString } from "../../src/core-types";
import { Legend } from "../Form";
import { Fieldset, FieldsetProps } from "../Form/Fieldset";

type Props = FieldsetProps & {
  languageCodes: LanguageCode[];
  legend: React.ReactNode;
  name: string;
  onChange: (data: InputLocaleString[]) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: "textarea" | "string";
  value: InputLocaleString[] | null | undefined;
  disabled?: boolean;
};

const StyledLabel = styled(Label)`
  position: absolute;
  right: 0;
  text-transform: uppercase;
  color: ${theme.colors.neutral[4]};
`;

StyledLabel.defaultProps = {
  ...StyledLabel.defaultProps,
  theme
};

const inputDefaultProps = Input.defaultProps || {};

export default class LocaleString extends React.Component<Props> {
  static defaultProps = {
    type: "string"
  };

  onChange = (
    languageCode: LanguageCode,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newValue = this.props.value ? [...this.props.value] : [];
    const idx = newValue.findIndex(v => v.language === languageCode);
    const newInputLocaleString = {
      language: languageCode,
      text: event.target.value
    };
    if (idx > -1) {
      newValue = array.update(newValue, idx, newInputLocaleString);
    } else {
      newValue.push(newInputLocaleString);
    }
    this.props.onChange(newValue);
  };

  render() {
    const {
      languageCodes,
      legend,
      name,
      type,
      placeholder,
      value,
      onBlur,
      onChange,
      disabled,
      ...props
    } = this.props;

    const hasMultipleLanguages = languageCodes && languageCodes.length > 1;

    return (
      <Fieldset mb={2} {...props}>
        <Legend>{legend}</Legend>

        {languageCodes &&
          languageCodes.map((languageCode, index) => {
            const currentValue =
              value && value.find(v => v.language === languageCode);
            const id = `${name}-${languageCode}`;
            const inputProps = {
              id,
              value: currentValue ? currentValue.text : "",
              placeholder,
              p: "initial",
              py: inputDefaultProps.p,
              pr: inputDefaultProps.p,
              pl: hasMultipleLanguages
                ? `${theme.space[6] + theme.space[2]}px`
                : inputDefaultProps.p,
              onBlur,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                this.onChange(languageCode, event);
              },
              disabled
            };
            const isTextarea = type === "textarea";

            return (
              <Flex
                key={id}
                alignItems={isTextarea ? undefined : "center"}
                width={1}
                mt={index > 0 ? 2 : undefined}
                position="relative"
              >
                {hasMultipleLanguages && (
                  <StyledLabel
                    htmlFor={id}
                    mr={3}
                    mt={isTextarea ? 4 : 0}
                    mb={0}
                    mute
                    small
                    lineHeight={0}
                  >
                    <Hide as="span" all srOnly>
                      {legend}{" "}
                    </Hide>

                    {languageCode}
                  </StyledLabel>
                )}

                {type === "string" && (
                  <Input
                    data-testid={`LocaleString__Input__${id}`}
                    {...inputProps}
                  />
                )}

                {isTextarea && (
                  // @ts-ignore
                  <Textarea
                    data-testid={`LocaleString__Textarea__${id}`}
                    {...inputProps}
                  />
                )}
              </Flex>
            );
          })}
      </Fieldset>
    );
  }
}
