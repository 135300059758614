import React from "react";
import { useRouter } from "next/router";
import { NavItemAnchor, Nav } from "./Tabs/TabsNav";
import { SystemLinkHref } from "../src/hooks";
import { HorizontalOverflow } from "./HorizontalOverflow";
import { SystemLink } from "./SystemLink";

export interface PageTab {
  href: SystemLinkHref;
  label: React.ReactNode;
  isSelected?: boolean;
}

export const PageTabs = ({ tabs, ...props }: { tabs: PageTab[] }) => {
  const router = useRouter();
  return (
    <HorizontalOverflow mb={5}>
      <Nav data-testid="PageTabs" {...props} large={false}>
        {tabs.map(tab => {
          const isSelected =
            tab.isSelected !== undefined
              ? tab.isSelected
              : router.route === tab.href;

          return (
            <li key={JSON.stringify(tab.href)}>
              <SystemLink href={tab.href} passHref>
                <NavItemAnchor large isSelected={isSelected}>
                  {tab.label}
                </NavItemAnchor>
              </SystemLink>
            </li>
          );
        })}
      </Nav>
    </HorizontalOverflow>
  );
};
