import React from "react";
import { PortalWithState } from "react-portal";
import styled, { keyframes, css } from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps
} from "styled-system";
import { transparentize } from "polished";

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
`;

const scaleIn = keyframes`
	from {
		transform: scale(0.5)
	}
`;

const Backdrop = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${transparentize(0.4, theme.colors.neutral[5])};
    z-index: ${theme.layers.dialog};
    animation: ${fadeIn} 300ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
    overflow-y: scroll;
    overflow-x: hidden;
    ${theme.mediaQueries[0]} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`;

const Dialog = styled.div<
  ColorProps & LayoutProps & SpaceProps & { bg?: string; boxShadow?: string }
>`
  ${layout};
  ${space};
  ${color};
  position: relative;
  background: ${({ bg }) => bg || "white"};
  max-width: 800px;
  min-height: 100vh;
  border-radius: 0;
  animation: ${scaleIn} 200ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
  ${({ theme }) => theme.mediaQueries[0]} {
    box-shadow: ${({ theme, boxShadow }) =>
      boxShadow || theme.boxShadows.join(",")};
    min-height: 0;
    border-radius: ${({ theme }) => theme.radii.lg};
    max-height: 90vh;
    overflow: auto;
  }
`;

export type PortalRenderProps = {
  isOpen: boolean;
  closePortal: () => void;
  openPortal: (event: React.SyntheticEvent) => void;
};

type PseudoModalProps = React.PropsWithChildren<
  PortalRenderProps & {
    bg: string;
    boxShadow?: string;
    closeOnOutsideClick?: boolean;
  }
>;

type PortalProps = {
  defaultOpen?: boolean;
  closeOnEsc?: boolean;
  closeOnOutsideClick?: boolean;
  onClose?: () => void;
};

type Props = PortalProps & {
  bg: string;
  boxShadow?: string;
  render: (props: PortalRenderProps) => React.ReactNode;
  renderToggler: (props: PortalRenderProps) => React.ReactNode;
};

const PseudoModal = ({
  bg,
  boxShadow,
  closeOnOutsideClick,
  isOpen,
  closePortal,
  ...props
}: PseudoModalProps) => {
  return (
    <Backdrop
      onClick={() => {
        if (closeOnOutsideClick) {
          closePortal();
        }
      }}
    >
      <Dialog
        bg={bg}
        boxShadow={boxShadow}
        width={[1, 1, 2 / 3, 2 / 3, 1 / 2]}
        p={[5, 5, 6]}
        onClick={e => e.stopPropagation()}
        {...props}
      />
    </Backdrop>
  );
};

const ModalRenderer = (props: Props) => {
  const {
    bg,
    boxShadow,
    render,
    renderToggler,
    closeOnEsc,
    closeOnOutsideClick,
    defaultOpen,
    onClose
  } = props;
  return (
    <PortalWithState
      defaultOpen={defaultOpen}
      closeOnEsc={closeOnEsc}
      onClose={onClose}
      {...props}
    >
      {({ portal, ...portalProps }) => (
        <React.Fragment>
          {portal(
            <PseudoModal
              bg={bg}
              boxShadow={boxShadow}
              closeOnOutsideClick={closeOnOutsideClick}
              {...portalProps}
            >
              {render(portalProps)}
            </PseudoModal>
          )}

          {renderToggler(portalProps)}
        </React.Fragment>
      )}
    </PortalWithState>
  );
};

ModalRenderer.defaultProps = {
  bg: "white"
};

export default ModalRenderer;
