import React, { FC } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "@urbaninfrastructure/react-ui-kit";

import HelpTooltip from "../HelpTooltip";

const Base = styled(Box)`
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
`;

Base.defaultProps = {
  bg: "neutral.3",
  width: 16,
  height: 16,
  display: "inline-block"
};

const DotBase = ({
  children,
  bg,
  haveSubTitle,
  ...props
}: {
  bg?: string;
  haveSubTitle: boolean;
  children?: React.ReactNode;
}) => {
  if (!children) {
    return <Base bg={bg} {...props} />;
  }

  return (
    <Flex alignItems={haveSubTitle ? "flex-start" : "center"}>
      <Base bg={bg} mt={haveSubTitle ? "3px" : undefined} mr={2} {...props} />
      <Box {...props}>{children}</Box>
    </Flex>
  );
};

export const Dot: FC<{
  bg?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}> = ({ title, subTitle, children, ...props }) => {
  const dotBase = (
    <DotBase haveSubTitle={Boolean(subTitle)} {...props}>
      {children}
      {subTitle && (
        <Text small mute>
          {subTitle}
        </Text>
      )}
    </DotBase>
  );
  if (title) {
    return <HelpTooltip tooltip={title}>{dotBase}</HelpTooltip>;
  }
  return dotBase;
};
