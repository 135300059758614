import gql from "graphql-tag";

// notes about fragments: apart from re-usability,
// fragments helps us strip mutations of unnecessary properties:
// https://stackoverflow.com/questions/42631523/remove-unnecessary-fields-before-mutation-in-graphql

// Tags
export const TAG_FRAGMENT = gql`
  fragment Tag on Tag {
    id
    name
    colour
    systemTag
  }
`;

// Entity tags
export const entityTag = gql`
  ${TAG_FRAGMENT}
  fragment EntityTag on EntityTag {
    id
    entityKind
    entityId
    createdAt
    tag {
      ...Tag
    }
  }
`;

export const entityTagsMutable = gql`
  fragment EntityTagMutable on Tag {
    name
    colour
  }
`;

// Docks
export const dockMutable = gql`
  fragment DockMutable on Dock {
    number
    type
    state
    rfid
    coords {
      lat
      lng
    }
    brokenAt
  }
`;

export const dock = gql`
  ${dockMutable}
  fragment Dock on Dock {
    id
    vehicle {
      id
      name
    }
    deletedAt
    ...DockMutable
  }
`;

// Dock groups
export const dockGroupMutable = gql`
  fragment DockGroupMutable on DockGroup {
    name
    type
    address
    title
    subTitle
    localeSubTitle {
      language
      text
    }
    state
    availabilityInfo {
      physicalDocks
      virtualDocks
    }
    elevation
    adSurfaces
    description
    coord {
      lat
      lng
    }
    lockFences {
      lat
      lng
    }
    requestFences {
      lat
      lng
    }
    showInPublicOverride
  }
`;

export const mapDockGroup = gql`
  fragment MapDockGroup on DockGroupListItem {
    id
    name
    title
    state
    type
    availabilityInfo {
      physicalDocks
      virtualDocks
      totalNumberOfDocks
      availableDocks
      availableVehicles
    }
    adSurfaces
    coord {
      lat
      lng
    }
    description
    updatedAt
    createdAt
  }
`;

export const dockGroup = gql`
  ${dockGroupMutable}
  ${entityTag}
  fragment DockGroup on DockGroup {
    ...DockGroupMutable
    id
    systemId
    sanityId
    entityTags {
      ...EntityTag
    }
    deletedAt
    availabilityInfo {
      physicalDocks
      virtualDocks
      totalNumberOfDocks
    }
    stationClient {
      id
      state
      description
      verificationCode
      kioskId
      createdAt
      updatedAt
    }
    operationsManagesCapacity
    controller {
      id
      ip
      imei
      serialNumber
      physicalLockId
      firmwareVersion
      state
      powerState
      model {
        id
        name
        emptyBatteryChargeThreshold
        lowBatteryChargeThreshold
      }
      batteryStatus
      batteryCharge
      batteryVoltage
      latestResponse
    }
    reportedVirtualDockCount
    maxVirtualDockCount
  }
`;

// Systems
export const systemMutable = gql`
  fragment SystemMutable on System {
    name
    description
    state
    coords {
      lng
      lat
    }
    type
    countryName
    countryCode
    cityName
    defaultLanguageCode
    languageCodes
    operator
    currency
    contactNumber
    contactEmail
    timezone
    maxPenaltyPoints
    sanityProjectId
    sanityDataset
    sanityToken
    colourKey
    publicDomain
    workingHoursId
    vehicleQuickReturnPeriodSec
    vehicleQuickReturnsToBroken
    vehicleLostPeriodDays
    dispatcherSmsSenderID
    iosAppUrl
    androidAppUrl
    webUrl
    sponsor
    settings
    intercomHelpSite
    defaultVatRate
    tripAdditionalMinutesPeriod
    maxTripAdditionaMinutesPeriodCount
    minTripLengthForCoolOffPeriodSec
    timeToRequestAdditionalMinutes
    coolOffPeriodSec
    pauseTripEnabled
    shouldAskForPushPermissions
    physicalDockGroupRequestVehicleEnabled
    virtualDockGroupRequestVehicleEnabled
    hybridDockGroupRequestVehicleEnabled
    qrCodeScanEnabled
    standaloneQrCodeScanEnabled
    vehicleReservationEnabled
    appDeepLinkingPrefix
    suspendDebtorEnabled
    feedbackFormUrl
    clickUpListId
    tripChargeConsolidationEnabled
    tripChargeConsolidationPeriod
    tripChargeConsolidationAmount
    registrationFeeEnabled
    registrationFeeAmount
    registrationAgreementWithdrawalConsent
    subscriptionAgreementWithdrawalConsent
    sendDataToThirdPartiesConsent
    walletEnablement
    walletMinBalance
    vehicleReservationCoolOffPeriodSec
    vehicleReservationLimitPerDay
    freeFloatingEnabled
    vehicleReservationQuickCancellationPeriodSec
    vehicleReservationPeriodMins
  }
`;

export const system = gql`
  fragment System on System {
    ...SystemMutable
    id
    vehicleCategories
    connectionPriceMatrixes {
      type
      priceMatrix {
        id
        title
      }
      vehicleCategory
    }
    partners {
      id
      name
    }
  }
  ${systemMutable}
`;

// Roles
export const roleMutable = gql`
  fragment RoleMutable on Role {
    name
    systemId
    description
    acl {
      systemId
      entity
      action
    }
  }
`;

export const role = gql`
  fragment Role on Role {
    ...RoleMutable
    id
  }
  ${roleMutable}
`;

// Administrators
export const administrator = gql`
  fragment Administrator on Administrator {
    id
    roles {
      id
      name
      acl {
        systemId
        entity
        action
      }
    }
    name
    profilePictureUrl
    email
    rfidAuth {
      id
      enabled
      rfid
      systemId
      action
      parameters
    }
    settings
    createdAt
    operationsApiExternalId
  }
`;

// Controller event logs
export const controllerEventLog = gql`
  fragment ControllerEventLog on ControllerEventLog {
    id
    topic
    payload
    controllerId
    generatedAt
    publishedAt
    createdAt
    cellularNetworkLatency
    pubSubLatency
    direction
    bridgeId
  }
`;

// Vehicle availability
export const vehicleAvailability = gql`
  fragment VehicleAvailability on VehicleAvailability {
    id
    systemId
    vehicleId
    availabilityState
    reason
    subscription {
      id
      userId
    }
    administrator {
      id
      name
    }
    dock {
      id
      number
      type
      dockGroup {
        id
        name
        title
        type
      }
    }
    createdAt
    updatedAt
  }
`;

// Controller
export const controllerMutable = gql`
  fragment ControllerMutable on Controller {
    systemId
    serialNumber
    ip
    imei
    physicalLockId
    desiredFirmwareVersion
    modelId
  }
`;

// Vehicle
export const vehicleMutable = gql`
  fragment VehicleMutable on Vehicle {
    systemId
    modelId
    name
    number
    frameNumber
    state
    priority
  }
`;

// User
export const userMutable = gql`
  fragment UserMutable on User {
    systemId
    superpowers
    gender
    name
    birthYear
    phoneNumber
    email
    postalAddress
    postalCode
    countryName
    intercomId
    active
    rfid
  }
`;

export const GITHUB_RELEASE_LIST = gql`
  fragment GH_ReleaseList on GH_Release {
    id
    name
    description
    url
    tag {
      name
    }
    publishedAt
    isDraft
  }
`;

// System alerts
export const TIMELINE_SYSTEM_ALERT_ENDED_FRAGMENT = gql`
  fragment TimelineSystemAlertEndedFragment on SystemAlertEnded {
    id
    createdAt
    systemAlert {
      id
      incidentEndTime
      systemAlertType
      resolvedAt
      resolvedBy {
        id
        name
        email
        profilePictureUrl
      }
      title {
        language
        text
      }
    }
  }
`;

export const TIMELINE_SYSTEM_ALERT_STARTED_FRAGMENT = gql`
  fragment TimelineSystemAlertStartedFragment on SystemAlertStarted {
    id
    createdAt
    systemAlert {
      id
      createdAt
      incidentStartTime
      incidentEndTime
      systemAlertType
      title {
        language
        text
      }
      description {
        language
        text
      }
      administrator {
        id
        name
        email
        profilePictureUrl
      }
      dockGroups {
        id
        title
      }
      updates {
        id
        createdAt
        title {
          language
          text
        }
      }
    }
  }
`;

export const VEHICLE_DISPLAY_NAME_FRAGMENT = gql`
  fragment VehicleDisplayNameFragment on VehicleDisplayName {
    ... on VehicleDisplayNamed {
      name
      number
    }
    ... on VehicleDisplayNumbered {
      number
    }
    ... on VehicleDisplayAnonymous {
      id
    }
  }
`;
