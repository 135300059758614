import {
  AclEntity,
  AclAction,
  globalData_administrator_roles as Role
} from "../../core-types";

export function can(
  systemId: string,
  entity: keyof typeof AclEntity,
  action: keyof typeof AclAction,
  roles: Role[]
) {
  if (!roles) {
    return false;
  }

  return roles.some(role => {
    if (!role || !role.acl) {
      return false;
    }

    return role.acl.some(permissions => {
      if (permissions.systemId !== "any" && permissions.systemId !== systemId) {
        return false;
      }

      if (permissions.entity !== "any" && permissions.entity !== entity) {
        return false;
      }

      if (permissions.action !== "any" && permissions.action !== action) {
        return false;
      }

      return true;
    });
  });
}
