import React from "react";
import styled from "styled-components";
import { ArrowRight as ArrowRightIcon } from "@urbaninfrastructure/react-icons";
import { capitalize } from "lodash";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Text, Flex, Box } from "@urbaninfrastructure/react-ui-kit";

import TimelineItem from "../TimelineItem";

type Mutation = {
  mutations: string | null;
  createdAt: string | null;
};

type Props = WrappedComponentProps & {
  mutationLogEvent?: Mutation;
};

type RhsLhs = string | Record<string, any> | Array<any> | undefined | null;
type Kind = "N" | "D" | "E" | "A";

export type ParsedMutation = {
  path: string[];
  kind: Kind;
  rhs?: RhsLhs;
  lhs?: RhsLhs;
  index: number;
  item?: {
    kind: Kind;
    rhs?: RhsLhs;
    lhs?: RhsLhs;
  };
};

function stringify(strOrObj: RhsLhs): string {
  if (typeof strOrObj === "string") {
    return strOrObj;
  }
  return JSON.stringify(strOrObj);
}

const LineThrough = styled(Text)`
  text-decoration: line-through;
`;
const Added = props => <Text as="span" bg="paletteLight.green01" {...props} />;

const RenderLine = (props: {
  path: string;
  kind: Kind;
  rhs?: RhsLhs;
  lhs?: RhsLhs;
}) => {
  const lhs = stringify(props.lhs);
  const rhs = stringify(props.rhs);
  const { kind, path } = props;

  if (path === "updated_at" || path === "version") {
    return null;
  }

  if (kind === "N") {
    return (
      <Flex alignItems="center" flexWrap="wrap" mt={3}>
        <Box width={1}>
          <Text color="gray" small>
            {capitalize(path)}
          </Text>
        </Box>
        <Box width={1}>
          <Flex alignItems="center">
            <Added>{capitalize(rhs)}</Added>
          </Flex>
        </Box>
      </Flex>
    );
  }
  if (kind === "D") {
    return (
      <Flex alignItems="center" flexWrap="wrap" mt={3}>
        <Box width={1}>
          <Text color="gray" small>
            {capitalize(path)}
          </Text>
        </Box>
        <Box width={1}>
          <Flex alignItems="center">
            <LineThrough>{capitalize(lhs)}</LineThrough>
          </Flex>
        </Box>
      </Flex>
    );
  }
  if (kind === "E") {
    return (
      <Flex alignItems="center" flexWrap="wrap" mt={3}>
        <Box width={1}>
          <Text color="gray" small>
            {capitalize(path)}
          </Text>
        </Box>
        <Box width={1}>
          <Flex alignItems="center">
            <LineThrough>{capitalize(lhs)}</LineThrough>
            <ArrowRightIcon color="gray" size="12px" mr={2} ml={2} />
            <Text as="span">{capitalize(rhs)}</Text>
          </Flex>
        </Box>
      </Flex>
    );
  }
  return null;
};

function formatDiff(mutations: ParsedMutation[] = []) {
  const lines = mutations.map(d => {
    if (d.path[d.path.length - 1] === "0") {
      d.path.pop();
    }

    const stringifiedPath: string = d.path.join(".");

    if (d.kind !== "A") {
      const { path, ...props } = d;
      return <RenderLine {...props} path={stringifiedPath} />;
    } else if (d.item) {
      const { ...props } = d.item;
      return <RenderLine {...props} path={`${stringifiedPath}[${d.index}]`} />;
    }
  });

  return lines;
}

const MutationLogTimelineEvent = ({ mutationLogEvent }: Props) => {
  if (
    !mutationLogEvent ||
    !mutationLogEvent.createdAt ||
    !mutationLogEvent.mutations
  ) {
    return null;
  }
  const anchorRef = `mutation-${mutationLogEvent.createdAt}`;
  const parsedMutations: ParsedMutation[] = JSON.parse(
    mutationLogEvent.mutations
  );
  const lines = formatDiff(parsedMutations);

  return (
    <TimelineItem
      id={anchorRef}
      heading="🔧Updated"
      datetime={mutationLogEvent.createdAt}
    >
      {lines.map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </TimelineItem>
  );
};

export default injectIntl(MutationLogTimelineEvent);
