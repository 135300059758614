import React from "react";
import styled from "styled-components";
import marked from "marked";
import { Text } from "@urbaninfrastructure/react-ui-kit";

const Base = styled(Text)`
  color: ${({ theme }) => theme.colors.neutral[5]};
  font-style: italic;
  & p {
    margin: 0;
  }
`;

type Props = {
  content?: string;
};

const MarkdownText = ({ content, ...props }: Props) => {
  return (
    <Base
      dangerouslySetInnerHTML={{
        __html: marked(content)
      }}
      {...props}
    />
  );
};

export default MarkdownText;
