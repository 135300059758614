import React from "react";
import { Box, Text } from "@urbaninfrastructure/react-ui-kit";
import HelpTooltip from "../HelpTooltip";
import Kpi from "../Kpi";
import { Dot } from "../Dot";
import {
  Section,
  SectionLink,
  SectionContent,
  SectionRow,
  SectionHeading
} from "../Section";
import { SystemLinkHref } from "../../src/hooks";

export type KpiValue = {
  highlight?: boolean;
  label: React.ReactNode;
  value: React.ReactNode;
  onClick?: (event: React.SyntheticEvent<unknown>) => void;
  children?: KpiValue[];
  colorDot?: string;
  noBorder?: boolean;
  systemLink?: SystemLinkHref;
  "data-testid"?: string;
};

type Props = {
  caption?: string | React.ReactNode;
  tooltip?: string;
  values: KpiValue[];
  header?: React.ReactNode;
  highlightFirst?: boolean;
};

const ValuesLoop = ({
  values,
  highlightFirst
}): { values: KpiValue[]; highlightFirst } =>
  values.map((value, i) => {
    return (
      <React.Fragment key={i}>
        {(i === 0 && highlightFirst) || value.highlight ? (
          value.route || value.systemLink ? (
            <SectionLink
              key={i}
              route={value.route}
              systemLinkHref={value.systemLink}
              params={value.params}
              onClick={value.onClick}
              noBorder={value.noBorder}
            >
              <Kpi
                large
                inline
                caption={value.label}
                value={value.value}
                my={1}
                data-testid={value["data-testid"]}
              />
            </SectionLink>
          ) : (
            <SectionContent key={i} noBorder={value.noBorder}>
              <Kpi
                large
                inline
                caption={value.label}
                value={value.value}
                mb={4}
                data-testid={value["data-testid"]}
              />
            </SectionContent>
          )
        ) : (
          <SectionRow
            key={i}
            onClick={value.onClick}
            route={value.route}
            systemLinkHref={value.systemLink}
            params={value.params}
            noBorder={value.noBorder}
          >
            {value.colorDot && (
              <Box mr="2px">
                <Dot bg={value.colorDot} />
              </Box>
            )}
            <Text as="span" bold mr="6px" data-testid={value["data-testid"]}>
              {value.value}
            </Text>
            <Text as="span" typoStyle="xxs">
              {value.label}
            </Text>
          </SectionRow>
        )}
        {value.children && (
          <Box ml="xxs" mr="xxs">
            {ValuesLoop({
              values: value.children,
              highlightFirst: value.highlightFirst
            })}
          </Box>
        )}
      </React.Fragment>
    );
  });

const DashboardKpi = ({
  caption,
  values,
  header,
  tooltip,
  highlightFirst = true
}: Props) => {
  return (
    <Section>
      {(caption || header) && (
        <SectionHeading>
          {caption}

          {tooltip && (
            <span style={{ float: "right" }}>
              <HelpTooltip tooltip={tooltip} />
            </span>
          )}

          {header}
        </SectionHeading>
      )}

      {ValuesLoop({ values, highlightFirst })}
    </Section>
  );
};

export default DashboardKpi;
