import React from "react";
import { Button } from "@urbaninfrastructure/react-ui-kit";
import styled, { css, keyframes } from "styled-components";

import Time from "../Time";

type Props = {
  children?: React.ReactNode;
  screenname?: string;
  fullname?: string;
  datetime?: string;
  image?: string;
  options?: any;
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Options = styled.span`
  opacity: 0;
`;

const baseCss = ({ theme }) => css`
  display: flex;
  width: 100%;
  max-width: 45em;
  align-items: flex-start;
  margin-bottom: ${theme.space[4]}px;
  margin-top: ${theme.space[4]}px;
  font-size: ${theme.fontSizes[2]}px;
  color: ${theme.colors.text};
  &:hover,
  &:focus-within {
    ${Options} {
      opacity: 1;
      animation: ${fadeIn} 200ms linear;
    }
  }
`;

const contentCss = ({ theme }) => css`
  padding-left: ${theme.space[2]}px;
  position: relative;
  width: 100%;
`;

const nameCss = ({ theme }) => css`
  display: block;
  margin: 0;
  font-weight: normal;
  font-size: ${theme.fontSizes[0]}px;
`;

const timeCss = ({ theme }) => css`
  font-weight: normal;
  font-size: ${theme.fontSizes[0]}px;
  color: ${theme.colors.neutral[5]};
`;

export const optionsItemCss = ({ theme }) => css`
  font-weight: normal;
  font-size: ${theme.fontSizes[0]}px;
  color: ${theme.colors.neutral[5]};
  margin-left: ${theme.space[2]}px;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: ${theme.colors.primary};
  }
`;

const Base = styled.article`
  ${baseCss};
`;

const ContentWrapper = styled.div`
  ${contentCss};
`;

const NameItem = styled.div`
  ${nameCss};
`;

const TimeItem = styled.span`
  ${timeCss};
`;

const ImageWrapper = styled.div`
  flex-basis: 2.5rem;
  max-width: 2.5rem;
`;

const ImageItem = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

const ButtonItem = styled(Button)`
  ${optionsItemCss};
`;

const Comment = ({
  children,
  image,
  screenname,
  fullname,
  datetime,
  options,
  ...props
}: Props) => {
  const defaultImage = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 29 29'%3E%3Ccircle cx='14.5' cy='14.5' r='14.5' fill='%23E3E6EB'%3E%3C/circle%3E%3C/svg%3E`;
  return (
    <Base {...props}>
      <ImageWrapper {...props}>
        <ImageItem
          {...props}
          src={image ? image : defaultImage}
          title={fullname && fullname}
          alt={fullname && fullname}
        />
      </ImageWrapper>

      <ContentWrapper {...props}>
        {screenname && (
          <NameItem {...props}>
            <span title={fullname && fullname}>{screenname}</span>

            {datetime && (
              <>
                {" "}
                <TimeItem {...props}>
                  <Time value={datetime} />
                </TimeItem>
              </>
            )}

            {options && (
              <Options>
                {options.map(item => {
                  return (
                    <ButtonItem
                      key={item.id}
                      onClick={() => item.handleClick()}
                      variant="reset"
                    >
                      {item.name}
                    </ButtonItem>
                  );
                })}
              </Options>
            )}
          </NameItem>
        )}

        {children}
      </ContentWrapper>
    </Base>
  );
};
Comment.displayName = "Comment";

export default Comment;
