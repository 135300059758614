import React from "react";
import { FormattedNumber } from "react-intl";
import { Text, Flex } from "@urbaninfrastructure/react-ui-kit";
import {
  BatteryEmpty,
  BatteryAlmostEmpty,
  BatteryHalfFull,
  BatteryAlmostFull,
  BatteryFull
} from "@urbaninfrastructure/react-icons";

import { BatteryStatus } from "../../src/core-types";

type Props = {
  batteryCharge: number | null;
  emptyBatteryThreshold: number | null | undefined;
  lowBatteryThreshold: number | null | undefined;
  batteryVoltage?: number | null;
  batteryStatus?: BatteryStatus | null;
};

const limitToOneHundred = (charge: number): number => Math.min(100, charge);

const BatteryLevel = ({
  batteryStatus,
  batteryVoltage,
  batteryCharge,
  emptyBatteryThreshold,
  lowBatteryThreshold
}: Props) => {
  const getVoltageEmoji = () => {
    let overrideColor;
    let overrideTitle;

    // Priority of battery data:
    // 1. Asset models thresholds
    // 2. Controller Battery Status
    // 3. batteryCharge (Vehicle or Controller)
    if (
      lowBatteryThreshold &&
      emptyBatteryThreshold &&
      batteryCharge !== null &&
      batteryCharge !== undefined
    ) {
      if (batteryCharge < emptyBatteryThreshold) {
        overrideColor = "state.error";
        overrideTitle = "Empty battery";
      } else if (batteryCharge < lowBatteryThreshold) {
        overrideColor = "#F9D320";
        overrideTitle = "Low battery";
      }
    } else if (batteryStatus) {
      if (batteryStatus === "dead") {
        return (
          <span
            title="Battery is dead since the voltage is too low"
            style={{ cursor: "help" }}
          >
            <BatteryEmpty color="state.error" />
          </span>
        );
      }
      if (batteryStatus === "needs_replacement") {
        return (
          <span title="Battery is low" style={{ cursor: "help" }}>
            <BatteryAlmostEmpty color="#F9D320" />
          </span>
        );
      }
    }

    if (batteryCharge !== null && batteryCharge !== undefined) {
      return (
        <span
          title={overrideTitle || "Battery is good"}
          style={{ cursor: "help" }}
        >
          {batteryCharge > 75 ? (
            <BatteryFull color={overrideColor || "state.success"} />
          ) : batteryCharge > 50 ? (
            <BatteryAlmostFull color={overrideColor || "state.success"} />
          ) : batteryCharge > 15 ? (
            <BatteryHalfFull color={overrideColor || "state.success"} />
          ) : batteryCharge > 5 ? (
            <BatteryAlmostEmpty color={overrideColor || "#F9D320"} />
          ) : (
            <BatteryEmpty color={overrideColor || "state.error"} />
          )}
        </span>
      );
    }

    return (
      <span title="Battery state is not known" style={{ cursor: "help" }}>
        <BatteryFull color="borderGray" />
      </span>
    );
  };

  let component = <Text pl={1}>?</Text>;

  if (
    batteryVoltage !== null &&
    batteryVoltage !== undefined &&
    batteryCharge !== null &&
    batteryCharge !== undefined
  ) {
    component = (
      <FormattedNumber
        style="percent"
        value={limitToOneHundred(batteryCharge) / 100}
      >
        {percent => {
          return (
            <FormattedNumber
              style="decimal"
              value={batteryVoltage}
              maximumFractionDigits={2}
            >
              {v => {
                return (
                  <Text
                    title={`${batteryCharge}% (${v}V)`}
                    pl={1}
                    color={batteryStatus === "dead" ? "#E02D00" : undefined}
                  >
                    {percent}
                  </Text>
                );
              }}
            </FormattedNumber>
          );
        }}
      </FormattedNumber>
    );
  } else if (batteryCharge !== null && batteryCharge !== undefined) {
    component = (
      <FormattedNumber
        style="percent"
        value={limitToOneHundred(batteryCharge) / 100}
      >
        {percent => {
          return (
            <Text
              title={`${batteryCharge}%`}
              pl={1}
              color={batteryStatus === "dead" ? "#E02D00" : undefined}
            >
              {percent}
            </Text>
          );
        }}
      </FormattedNumber>
    );
  } else if (batteryVoltage !== null && batteryVoltage !== undefined) {
    component = (
      <FormattedNumber
        style="decimal"
        value={batteryVoltage}
        maximumFractionDigits={2}
      >
        {voltage => {
          return (
            <Text
              title={`${batteryVoltage}V`}
              pl={1}
              color={batteryStatus === "dead" ? "#E02D00" : undefined}
            >
              {voltage}V
            </Text>
          );
        }}
      </FormattedNumber>
    );
  }

  return (
    <Flex>
      {getVoltageEmoji()}
      {component}
    </Flex>
  );
};

export default BatteryLevel;
