import React from "react";
import * as acl from "../../src/utils/acl";
import { AdministratorContext } from "../context/administrator";

import { AclAction, AclEntity } from "../core-types";

type AclSystem = "any" | string;

const useAccessControl = (
  action: keyof typeof AclAction,
  entity: keyof typeof AclEntity,
  systemId?: AclSystem
): boolean => {
  const administrator = React.useContext(AdministratorContext);
  if (!administrator) {
    return false;
  }

  return acl.can(
    !systemId || typeof systemId === "undefined"
      ? administrator.selectedSystem.id
      : systemId,
    entity,
    action,
    administrator.roles
  );
};

export default useAccessControl;
