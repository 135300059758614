import React from "react";
import { Box, Text, Input, Label } from "@urbaninfrastructure/react-ui-kit";
import { SegmentButton } from "../SegmentButton";
import { FormGroup } from "../Form/Group";

const VALID_RFID_LENGTH_HEX_INPUT = 16;
const VALID_RFID_LENGTH_SCAN = 10;

export const isValidRfidScan = (input: string) =>
  input.length === VALID_RFID_LENGTH_SCAN;
export const isValidRfidHex = (input: string) =>
  input.length <= VALID_RFID_LENGTH_HEX_INPUT && /^[0-9A-F]+$/gm.test(input);
export const isValidRfidDecimal = (input: string) => /^\d+$/g.test(input);
export const convertDecToHex = (input: string) =>
  parseInt(input)
    .toString(16)
    .toUpperCase();
export const convertHexToDec = (input: string) =>
  parseInt(input, 16).toString();

type Props = {
  value: string | null | undefined;
  setValue: (string) => void;
  label: React.ReactNode;
  rfidMode: "hex" | "decimal" | "scan";
  setRfidMode: (string) => void;
  invalidRfid: string;
  setInvalidRfid: (string) => void;
};

const RfidInput = ({
  value,
  setValue,
  label,
  rfidMode,
  setRfidMode,
  invalidRfid,
  setInvalidRfid
}: Props) => {
  let rfidInputField;

  return (
    <FormGroup id="rfid" error={invalidRfid}>
      <Label>{label}</Label>
      <Box mb="xxs">
        <SegmentButton
          items={[
            {
              id: "hex",
              name: "Hex"
            },
            {
              id: "decimal",
              name: "Decimal"
            },
            {
              id: "scan",
              name: "Scanner"
            }
          ]}
          initialSelected={rfidMode}
          onChange={mode => {
            if (rfidMode === mode.id) {
              return;
            }

            setRfidMode(mode.id);
            rfidInputField?.focus();

            if (!value) {
              return;
            }

            if (rfidMode === "decimal") {
              setValue(convertDecToHex(value));
              return;
            }

            if (mode.id === "decimal") {
              setValue(convertHexToDec(value));
            }
          }}
        />
      </Box>
      {(rfidMode === "hex" && (
        <Box>
          <Text mb="xxs" mute>
            A hexadecimal representation of the RFID. Example: 1B4E8A
          </Text>
          <Input
            id="rfid"
            value={value || ""}
            onChange={event => {
              const value = event.target.value.toUpperCase();
              if (isValidRfidHex(value) || value.length === 0) {
                setInvalidRfid("");
              } else {
                setInvalidRfid("RFID is not valid");
              }
              setValue(value);
            }}
            ref={i => {
              rfidInputField = i;
            }}
            autoComplete="off"
          />
        </Box>
      )) ||
        (rfidMode === "decimal" && (
          <Box>
            <Text mb="xxs" mute>
              A decimal representation of the RFID. Example: 1789578
            </Text>
            <Input
              id="rfid"
              value={value || ""}
              onChange={event => {
                if (isValidRfidDecimal(event.target.value)) {
                  setInvalidRfid("");
                } else {
                  setInvalidRfid("RFID is not valid");
                }
                setValue(event.target.value);
              }}
              ref={i => {
                rfidInputField = i;
              }}
              autoComplete="off"
            />
          </Box>
        )) ||
        (rfidMode === "scan" && (
          <Box>
            <Text mb="xxs" mute>
              If you have an RFID scanner that outputs decimal numbers, it will
              automatically be converted
            </Text>
            <Input
              id="rfid"
              value={value || ""}
              onChange={event => setValue(event.target.value)}
              ref={i => {
                rfidInputField = i;
              }}
              onKeyPress={event => {
                if (event?.key === "Enter") {
                  event.preventDefault();
                  const target = event.target as HTMLInputElement;
                  const rfid = target.value;
                  if (isValidRfidScan(rfid)) {
                    setValue(convertDecToHex(rfid));
                    setInvalidRfid("");
                  } else {
                    setInvalidRfid("RFID is not valid");
                  }
                }
              }}
              autoComplete="off"
            />
          </Box>
        ))}
    </FormGroup>
  );
};

export default RfidInput;
