import styled, { keyframes, css } from "styled-components";
import {
  UIKitTheme as Theme,
  Box,
  Text
} from "@urbaninfrastructure/react-ui-kit";

type Props = {
  isOpen: boolean;
};

type ItemProps = {
  selected?: boolean;
  theme: Theme;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const dropdownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px)
  }
  to {
    opacity:1;
    transform: translateY(1px)
  }
`;

const ItemCss = ({ theme, selected }: ItemProps) => css`
  border: none;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 1;
  &:hover,
  &:focus {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.primaryContrast};
  }
  ${selected &&
    css`
      background-color: ${theme.colors.neutral[1]};
      font-weight: bold;
      &:hover,
      &:focus {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.primaryContrast};
      }
    `};
`;

export const DropdownItem = styled(Text)`
  ${ItemCss};
`;

DropdownItem.defaultProps = {
  as: "a",
  color: "text",
  px: 3,
  py: 2,
  selected: false
};

export const DropdownCaption = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  text-decoration: none;
  display: block;
  position: relative;
  text-align: left;
  z-index: 1;
`;

DropdownCaption.defaultProps = {
  px: 3,
  py: 2,
  color: "neutral.5"
};

export const Dropdown = styled(Box).attrs((props: Props) => {
  return { ["aria-hidden"]: !props.isOpen };
})`
  ${({ isOpen }: Props) => !isOpen && `display: none;`}
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 6;
  border-radius: ${({ theme }) => theme.radii["md"]};
  animation: 100ms ease-out ${dropdownAnimation};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  overflow: auto;
  margin-bottom: ${({ theme }) => theme.space[4]}px;
  max-height: 300px;
  &:after {
    content: "";
    display: block;
    width: ${({ theme }) => theme.space[2]}px;
    height: ${({ theme }) => theme.space[2]}px;
    background: ${({ theme }) => theme.colors.white};
    border-left: 1px solid ${({ theme }) => theme.colors.neutral[1]};
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[1]};
    transform: translate(0, -50%) rotate(45deg);
    position: absolute;
    top: 0;
    right: ${({ theme }) => theme.space[3]}px;
    z-index: 0;
  }
`;

Dropdown.defaultProps = {
  bg: "white",
  width: 1,
  minWidth: "10rem"
};
