import { TAG_FRAGMENT } from "../../src/fragments";
import gql from "graphql-tag";

export const CREATE_ENTITY_TAG_MUTATION = gql`
  mutation createEntityTagMutation($input: EntityTagInput) {
    createEntityTag(input: $input) {
      id
    }
  }
`;

export const DELETE_ENTITY_TAG_MUTATION = gql`
  mutation deleteEntityTagMutation($id: ID!) {
    deleteEntityTag(id: $id) {
      id
    }
  }
`;

export const TAGS_QUERY = gql`
  ${TAG_FRAGMENT}
  query tagsQuery(
    $systemId: ID!
    $entityKind: Entity!
    $includeSystemTags: Boolean
  ) {
    tags(
      systemId: $systemId
      entityKind: $entityKind
      includeSystemTags: $includeSystemTags
    ) {
      ...Tag
    }
  }
`;
