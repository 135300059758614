import React from "react";
import {
  Bike,
  Scooter,
  BikeElectric,
  BikeChildSeat
} from "@urbaninfrastructure/react-icons";

import useSystem from "../src/hooks/useSystem";
import { SystemType, VehicleCategory } from "../src/core-types";
import { SvgIconInternalProps } from "@urbaninfrastructure/react-icons/lib/Svg";

const getVehicleIcon = (system?: { type: SystemType }) => {
  if (system) {
    switch (system.type) {
      case SystemType.bikesharing:
      case SystemType.busshelters:
      case SystemType.carsharing: {
        break;
      }
      case SystemType.scootersharing: {
        return Scooter;
      }
    }
  }
  return Bike;
};

type Props = SvgIconInternalProps & {
  system?: { type: SystemType };
  category?: VehicleCategory | null;
};

const VehicleIcon = ({ system, category, ...props }: Props) => {
  const selectedSystem = useSystem();
  let Icon:
    | undefined
    | ((props: SvgIconInternalProps) => JSX.Element) = undefined;

  switch (category) {
    case undefined:
    case null: {
      break;
    }
    case VehicleCategory.bike: {
      Icon = Bike;
      break;
    }
    case VehicleCategory.ebike: {
      Icon = BikeElectric;
      break;
    }
    case VehicleCategory.ebike_with_childseat: {
      Icon = BikeChildSeat;
      break;
    }
    case VehicleCategory.scooter: {
      Icon = Scooter;
      break;
    }
  }

  if (!Icon) {
    Icon = getVehicleIcon(system || selectedSystem);
  }

  return <Icon {...props} />;
};

export default VehicleIcon;
