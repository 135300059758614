import { useIntl, MessageDescriptor } from "react-intl";

import useSystem from "./useSystem";
import { getVehicleNouns } from "../../src/utils/translation";

type Translate = (
  message: MessageDescriptor,
  values?: Record<string, any>
) => string;

const useTranslate = (): Translate => {
  const system = useSystem();
  const intl = useIntl();
  const nouns = getVehicleNouns(system, intl);

  const translate = (message, values = {}) =>
    intl.formatMessage(message, { ...nouns, ...values });

  return translate;
};

export default useTranslate;
