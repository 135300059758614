import React from "react";
import { SystemContext } from "../context/system";
import { system_system } from "../core-types";

type System = system_system;

const useSystem = (): System => {
  const system = React.useContext(SystemContext);

  return system;
};

export default useSystem;
