import React from "react";
import styled from "styled-components";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { getRouteRegex } from "next/dist/next-server/lib/router/utils";
import { Arrow, Ol, Li, Flex, Text } from "@urbaninfrastructure/react-ui-kit";
import routeMessages from "../../src/routes/messages";
import useTranslate from "../../src/hooks/useTranslate";

const BreadcrumbLink = styled(Text)`
  text-decoration: none;
  border-radius: ${({ theme }) => theme.space[1]}px;
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  &:hover,
  &:focus {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
  }
`;
BreadcrumbLink.defaultProps = { as: "a" };

const BreadcrumbCaption = styled(Text)`
  text-decoration: none;
  border-radius: ${({ theme }) => theme.space[1]}px;
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  opacity: 0.75;
  font-weight: normal;
  &:hover,
  &:focus {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
`;
BreadcrumbCaption.defaultProps = { as: "a" };

const Breadcrumbs = ({
  breadcrumbTitleOverride
}: {
  breadcrumbTitleOverride?: string;
}) => {
  const translate = useTranslate();
  const router = useRouter();
  const breadcrumbs = router.route
    // remove trailing slash "/" from pathname (avoids multiple of the same match)
    .replace(/\/$/, "")
    // split pathname into sections
    .split("/")
    // remove empty strings
    .filter(x => x)
    .reduce<{ pathname: Pathname; query: ParsedUrlQueryInput }[]>(
      (accum, route, index) => {
        // combine the last route section with the current
        // ex `pathname = /1/2/3 results in match checks for
        // `/1`, `/1/2`, `/1/2/3`
        const previous = accum[index - 1];
        let pathname = `/${route}` as Pathname;
        if (previous) {
          pathname = (previous.pathname + pathname) as Pathname;
        }
        // parse pathname for [param], and create query from router.query.
        // eg, "/[systemId]/business" and router.query { companyId: "1", systemId: "oslobysykkel" }
        // should give { systemId: "oslobsykkel" }
        // NOTE: Using getRouteRegex is not safe, and might break when updating Next.js
        const { groups } = getRouteRegex(pathname);
        const query = Object.keys(groups).reduce<ParsedUrlQueryInput>(
          (accum, key) => {
            if (router.query[key]) {
              accum[key] = router.query[key];
            }
            return accum;
          },
          {}
        );
        accum.push({ pathname, query });
        return accum;
      },
      []
    );

  // remove first item (dashboard) from breadcrumbs
  if (breadcrumbs.length > 1) {
    breadcrumbs.shift();
  }

  return (
    <nav>
      <Ol listStyle="none" display="flex" ml={2} p={0} m={0}>
        {breadcrumbs.map(({ pathname, query }, i) => {
          const isLast = breadcrumbs.length === i + 1;
          const name = routeMessages[pathname]
            ? translate(routeMessages[pathname])
            : pathname;
          if (router.query.systemId) {
            query.systemId = router.query.systemId;
          }
          return (
            <Li key={pathname}>
              <Flex alignItems="center">
                <Arrow direction="right" size="12px" />
                {!isLast && (
                  <NextLink href={{ pathname, query }} prefetch={false}>
                    <BreadcrumbLink color="primaryContrast" ml={3}>
                      {name}
                    </BreadcrumbLink>
                  </NextLink>
                )}
                {isLast && (
                  <NextLink href={{ pathname, query }} prefetch={false}>
                    <BreadcrumbCaption color="primaryContrast" ml={3}>
                      {breadcrumbTitleOverride ? breadcrumbTitleOverride : name}
                    </BreadcrumbCaption>
                  </NextLink>
                )}
              </Flex>
            </Li>
          );
        })}
      </Ol>
    </nav>
  );
};

export default Breadcrumbs;
